import { DocTypeEnum } from "../enum/doc-type";
import { Account } from "../form/account";
import { Reason } from "../reason";
import { COUNTRY_1 } from "./country-mockup";
import { PT } from "./language-mockup";

export const ACCOUNT_1 = new Account('0', COUNTRY_1, 
    'Usuario Teste', { id: DocTypeEnum.CPF, name: 'CPF'  }, '12312313212', null, '9999999999999', 
    'teste@teste.com', '', '', 
    PT, 
    true, true, false, new Reason());

export const ACCOUNT_LIST = []