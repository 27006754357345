import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { Login } from 'src/app/model/form/login';
import { CustomValidator } from 'src/shared/custom-validator';
import { Message } from 'primeng/api';
import { Router } from '@angular/router';
import { AuthService } from '../../../service/auth.service';
import { CognitoException } from 'src/app/model/enum/cognito-exception';
import { Title } from '@angular/platform-browser';
import { LANGUAGE_LIST } from 'src/app/model/mockup/language-mockup';
import { AccountService } from 'src/app/service/account.service';
import { Language } from 'src/app/model/language';
import { GeolocationService } from 'src/app/service/geolocationService';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public form!: FormGroup;

  messages: Message[];
  languageOptions: Language[];
  DEFAULT_LANGUAGE: Language;
  lastLanguage: Language;
  language: Language;
  dashboardUrl: string;
  createAccountUrl: string;
  forgotPasswordUrl: string;
  activateAccountUrl: string;
  mfaAccountUrl: string;
  submitBtnLoading: boolean = false;
  username: string | null = null;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private authService: AuthService,
              private titleService: Title,
              private translocoService: TranslocoService,
              private accountService: AccountService,
              private geolocationService: GeolocationService) {

    this.messages = [];
    this.languageOptions = LANGUAGE_LIST as [];
    this.DEFAULT_LANGUAGE = this.languageOptions[0];
    this.language = this.DEFAULT_LANGUAGE;
    this.lastLanguage = this.DEFAULT_LANGUAGE;
    this.dashboardUrl = '/dashboard';
    this.createAccountUrl = '/create-account';
    this.forgotPasswordUrl = '/forgot-password';
    this.activateAccountUrl = '/activate-account';
    this.mfaAccountUrl = '/mfa-login';

  }

  async ngOnInit() {
    this.createForm(new Login(this.getLanguage(null)));
    this.changeLanguage(this.getLanguage(null).name);
    this.setTitle();
    this.refreshLanguageOptions();
    this.getLocation();

    this.form.controls['email'].valueChanges.subscribe(value => {
      if (value) {
        this.form.controls['email'].setValue(value.toLowerCase(), { emitEvent: false });
      }
    });

    let refresh = localStorage.getItem('refreshToken');
    let token = localStorage.getItem('token');
    if(token) {
      this.authService.verifyToken(token).then(result => {
        if(result) {
          this.authService.startTokenRefresh();
          this.accountService.findUserActive().subscribe(
            data => {                
              if(data) {
                localStorage.setItem('idUser', data.userID);
                localStorage.setItem('nameUser', data.name);
                localStorage.setItem('language', data.language);
                if(data.accessLevel < 1){
                  this.translocoService.selectTranslate('user.login.accessDenied').subscribe(value => this.messages = [
                    { severity: 'error', summary: value}
                  ]);                    
                }else{
                  this.router.navigate([this.dashboardUrl]);
                }
              } else {
                this.translocoService.selectTranslate('user.login.accessDenied').subscribe(value => this.messages = [
                  { severity: 'error', summary: value}
                ]);
              }
              this.submitBtnLoading = false;
          });
        } else {
          if(refresh) {
            this.authService.refreshAuth().then(result => {
              if(result) {
                this.accountService.findUserActive().subscribe(
                  data => {                
                    if(data) {
                      localStorage.setItem('idUser', data.userID);
                      localStorage.setItem('nameUser', data.name);
                      localStorage.setItem('language', data.language);
                      if(data.accessLevel < 1){
                        this.translocoService.selectTranslate('user.login.accessDenied').subscribe(value => this.messages = [
                          { severity: 'error', summary: value}
                        ]);                    
                      }else{
                        this.router.navigate([this.dashboardUrl]);
                      }
                    } else {
                      this.translocoService.selectTranslate('errorConection').subscribe(value => this.messages = [
                        { severity: 'error', summary: value}
                      ]);
                    }
                    this.submitBtnLoading = false;
                });
              } else {
                this.submitBtnLoading = false;
              }
            }).catch(() => {
              this.submitBtnLoading = false;
            });
          } else {
          this.submitBtnLoading = false;
          }
        }
      }).catch(() => {
        if(refresh) {
          this.authService.refreshAuth().then(result => {
            if(result) {
              this.accountService.findUserActive().subscribe(
                data => {                
                  if(data) {
                    localStorage.setItem('idUser', data.userID);
                    localStorage.setItem('nameUser', data.name);
                    localStorage.setItem('language', data.language);
                    if(data.accessLevel < 1){
                      this.translocoService.selectTranslate('user.login.accessDenied').subscribe(value => this.messages = [
                        { severity: 'error', summary: value}
                      ]);                    
                    }else{
                      this.router.navigate([this.dashboardUrl]);
                    }
                  } else {
                    this.translocoService.selectTranslate('errorConection').subscribe(value => this.messages = [
                      { severity: 'error', summary: value}
                    ]);
                  }
                  this.submitBtnLoading = false;
              });
            } else {
              this.submitBtnLoading = false;
            }
          }).catch(() => {
            this.submitBtnLoading = false;
          });
        } else {
        this.submitBtnLoading = false;
        }
      });
    } 
  }

  createForm(login: Login) {
    this.form = this.formBuilder.group({
      email: [login.email, CustomValidator.getEmail()],
      password: [login.password, CustomValidator.getRequired()],
      language: [login.language]
    })
  }

  signUp() {
    localStorage.setItem('language', this.translocoService.getActiveLang());
    this.router.navigate([this.createAccountUrl]);
  }

  forgotPassword() {
    this.router.navigate([this.forgotPasswordUrl]);
  }

  async onSubmit() {
    if(this.form.valid) {
      this.submitBtnLoading = true;
      await this.authService.initiateAuth(this.f.email.value, this.f.password.value).then(async result => {
        localStorage.setItem('email', this.f.email.value);
        if(result !== false) {
          let token = localStorage.getItem('token');
          if(token) {
            this.accountService.findUserActive().subscribe(
              data => {                
                if(data) {
                  localStorage.setItem('idUser', data.userID);
                  localStorage.setItem('nameUser', data.name);
                  localStorage.setItem('language', data.language);
                  if(data.accessLevel < 1){
                    this.translocoService.selectTranslate('user.login.accessDenied').subscribe(value => this.messages = [
                      { severity: 'error', summary: value}
                    ]);                    
                  }else{
                    this.router.navigate([this.dashboardUrl]);
                  }
                } else {
                  this.translocoService.selectTranslate('user.login.errorLogin').subscribe(value => this.messages = [
                    { severity: 'error', summary: value}
                  ]);
                }
                this.submitBtnLoading = false;
            });
          } else {
            if(result.ChallengeName === 'CUSTOM_CHALLENGE') {
              localStorage.setItem('session', result.Session);
              localStorage.setItem('email', this.f.email.value);
              this.router.navigate([this.mfaAccountUrl]);
              this.submitBtnLoading = false;
            } else {
              this.translocoService.selectTranslate('user.login.errorLogin').subscribe(value => this.messages = [
                { severity: 'error', summary: value}
              ]);
              this.submitBtnLoading = false;
            }
          }
        } else {
          this.translocoService.selectTranslate('user.login.errorLogin').subscribe(value => this.messages = [
            { severity: 'error', summary: value}
          ]);
          this.submitBtnLoading = false;
        }
      }).catch(error => {
        if(CognitoException.USER_NOT_CONFIRMED == error.name ) {
          this.username = null;
          this.authService.resendConfirmationCode(this.f.email.value, this.username, localStorage.getItem('language'), 'signup').then(() => {
            this.submitBtnLoading = false;
            localStorage.setItem('email-activate-account', this.f.email.value);
            if(this.username)
              localStorage.setItem('name-activate-account', this.username);
            this.router.navigate([this.activateAccountUrl]);
          }).catch(() => {
            this.translocoService.selectTranslate('errorConection').subscribe(value => this.messages = [
              { severity: 'error', summary: value}
            ]);
            this.submitBtnLoading = false;
          });
        } else if(CognitoException.NOT_AUTHORIZED === error.name ) {
          this.translocoService.selectTranslate('user.login.errorLogin').subscribe(value => this.messages = [
            { severity: 'error', summary: value}
          ]);
          this.submitBtnLoading = false;
        } else if(error.message === 'UserNotConfirmedException' ) {
          this.username = null;
          this.authService.resendConfirmationCode(this.f.email.value, this.username, localStorage.getItem('language'), 'signup').then(() => {
            this.submitBtnLoading = false;
            localStorage.setItem('email-activate-account', this.f.email.value);
            if(this.username)
              localStorage.setItem('name-activate-account', this.username);
            this.router.navigate([this.activateAccountUrl]);
          }).catch(() => {
            this.translocoService.selectTranslate('errorConection').subscribe(value => this.messages = [
              { severity: 'error', summary: value}
            ]);
            this.submitBtnLoading = false;
          });
        } else {
          this.translocoService.selectTranslate('user.login.errorLogin').subscribe(value => this.messages = [
            { severity: 'error', summary: value}
          ]);
          this.submitBtnLoading = false;
        }
      });
    } else {
      this.translocoService.selectTranslate('user.login.errorLogin').subscribe(value => this.messages = [
        { severity: 'error', summary: value}
      ]);
    }
  }

  fixLanguage() {
    this.messages = [];
    this.language = this.f.language.value;
    if(this.lastLanguage !== this.language) {
        this.languageOptions
          .filter(opt => opt.name === this.language.name)
          .map(cty => cty.disabled = true)
        this.languageOptions
          .filter(opt => opt.name !== this.language.name)
          .map(cty => cty.disabled = false)

        this.lastLanguage = this.language;
        this.changeLanguage(this.language.name);
        localStorage.setItem('language', this.language.name);
    }
  }

  
  refreshLanguageOptions() {
    for (let i = 0; i < this.languageOptions.length; i++) {
      if(this.languageOptions[i].name === this.f.language.value.name)
        this.languageOptions[i].disabled = true;
      else
       this.languageOptions[i].disabled = false;
    }
  }

  goDashboard() {
    this.router.navigate([this.dashboardUrl]);

  }

  setTitle() {
    this.translocoService.selectTranslate('title.login').subscribe(value => 
      this.titleService.setTitle(value)
    );
  }

  getLanguage(lan: string | null) : Language {
    for (let i = 0; i < this.languageOptions.length; i++) {
      if(this.languageOptions[i].name === lan) {
        return this.languageOptions[i];
      }
    }
    return this.DEFAULT_LANGUAGE;
  }

  public changeLanguage(languageCode: string | null): void {
    if(languageCode !== null)
      this.translocoService.setActiveLang(languageCode);
  }

  getLocation(){
    this.geolocationService.getCountry().subscribe((data: any) => {
      if (data.country === "BR") {
        this.form.get('language')?.setValue(this.languageOptions[0]);
      }
    });
  }
  get f() { return this.form.controls; }
}
