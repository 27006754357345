import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { ButtonModule } from 'primeng/button';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { StepsModule } from 'primeng/steps';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { ChipsModule } from 'primeng/chips';
import { DropdownModule } from 'primeng/dropdown';
import { TagModule } from 'primeng/tag';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from "primeng/message"; 

import { TranslocoRootModule } from 'src/app/transloco-root.module';
import { HttpClientModule } from '@angular/common/http';
import { VcuComponent } from './vcu/vcu.component';
import { AccordionModule } from 'primeng/accordion';
import { TableModule } from 'primeng/table';
import { DashboardTemplateComponent } from '../dashboard/dashboard-template/dashboard-template.component';
import { InputIconModule } from 'primeng/inputicon';
import { IconFieldModule } from 'primeng/iconfield';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { RegisterModule } from '../register/register.module';
import { VcuManagementComponent } from './vcu-management/vcu-management.component';
import { VcuIdentifiedComponent } from './vcu-identified/vcu-identified.component';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CardModule } from '../card/card.module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@NgModule({
  declarations: [
    VcuComponent,
    VcuManagementComponent,
    VcuIdentifiedComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    DialogModule,
    StepsModule,
    InputTextModule,
    CalendarModule,
    ButtonModule,
    ReactiveFormsModule,
    ChipsModule,
    DropdownModule,
    TranslocoRootModule,
    HttpClientModule,
    AccordionModule,
    TableModule,
    InputIconModule,
    IconFieldModule,
    TagModule,
    MessagesModule,
    MessageModule,
    FormsModule,
    ConfirmDialogModule,
    DashboardTemplateComponent,
    RegisterModule,
    FileUploadModule,
    ToastModule,
    InputTextareaModule,
    CardModule,
    ProgressSpinnerModule
  ],
  exports: [
    VcuComponent,
    VcuManagementComponent,
    VcuIdentifiedComponent
  ],
  providers: [provideAnimations(), provideAnimationsAsync(), ConfirmationService]
})
export class VcuModule { }
