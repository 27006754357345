<p-dialog
    id="modal-register-vcu"
    name="modal-register-vcu"
    [header]="dialogHeader"
    [modal]="true"
    [draggable]="false"
    [(visible)]="showDialog"
    (onHide)="closeModal()"
    [style]="{'border-radius':'130px'}">
    <div class="modal-content-page1" [(style.display)]="modalpage[0]">
        <p-steps [activeIndex]="active" [model]="stepItems" [readonly]="true" />
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <!-- {{ form.value | json }} -->
            <div class="row">
                <input
                    type="text" 
                    id="surname" name="surname"
                    formControlName="surname"
                    placeholder="{{ 'vcu.surname' | transloco }}"
                    pInputText >
            </div>
            <div class="row">
                <p-dropdown id="family"
                    [options]="familyOptions" 
                    formControlName="family" 
                    name="family"
                    optionLabel="name"
                    placeholder="{{ 'vcu.family' | transloco }}*"
                    emptyMessage="{{ 'noResult' | transloco }}"/>

                <p-dropdown id="model"
                    [options]="modelOptions" 
                    formControlName="model" 
                    name="model"
                    optionLabel="name" 
                    placeholder="{{ 'vcu.model' | transloco }}*"
                    emptyMessage="{{ 'noResult' | transloco }}"/>
                </div>
            <div class="row">
                <div class="row-label">
                    <input
                        type="text" 
                        id="serialNumber" name="serialNumber"
                        formControlName="serialNumber"
                        placeholder="{{ 'vcu.serialNumber' | transloco }}"
                        pInputText >
                    <label for="serialNumber">
                        {{ 'vcu.serialNumber-label1' | transloco }}<a [routerLink]="[]">{{ 'vcu.serialNumber-label2' | transloco }}</a>
                    </label>
                </div>
                <p-dropdown id="dealer"
                    [options]="resellerOptions" 
                    formControlName="reseller" 
                    name="reseller"
                    optionLabel="name" 
                    placeholder="{{ 'vcu.dealer' | transloco }}"
                    emptyMessage="{{ 'noResult' | transloco }}"/>
            </div>
            <div class="row">
                <input
                    type="text" 
                    id="invoice" name="invoice"
                    formControlName="invoice"
                    placeholder="{{ 'vcu.invoice' | transloco }}"
                    pInputText /> 
                <p-calendar 
                    id="purchaseDate"
                    placeholder="{{ 'vcu.purchaseDate' | transloco }}"
                    formControlName="purchaseDate" 
                    name="purchaseDate"
                    dateFormat="dd/mm/yy"
                    [showIcon]="true"
                    [showOnFocus]="false"  />
            </div>
            <div class="row row-tag">
                <p-chips 
                    id="tags" 
                    name="tags" 
                    placeholder="{{ 'vcu.tags' | transloco }}"
                    formControlName="tags" 
                    separator="," />
                <label for="tags">
                    {{ 'vcu.tagsLabel' | transloco }}
                </label>
            </div>
            <div class="row">
                <p-messages id="messages"
                    [(value)]="messages" 
                    [enableService]="false" 
                    [style]="{'font-size':'15px', 'width': '100%'}"/>
            </div>
            <div class="row row-btn">
                <p-button
                    [label]="labelBtnSave"
                    type="submit"
                    severity="danger"
                    [loading]="saveVcuBtnLoading"
                    [raised]="true">
                </p-button>
            </div>
        </form>
    </div>
    <div class="modal-content-page2" [(style.display)]="modalpage[1]">
        <p-steps [activeIndex]="active" [model]="stepItems" [readonly]="true" />
        <form *ngIf="vcuLinkOut; else elseBlock" class="form-align">
            <div>
                <div class="row">
                    <strong>{{ 'vcu.code.label' | transloco }}</strong>
                </div>
                <div class="row">
                    <ul>
                        <li>{{ 'vcu.code.step1' | transloco }}</li>
                        <li>{{ 'vcu.code.step2' | transloco }}</li>
                        <li>{{ 'vcu.code.step3' | transloco }}</li>
                    </ul>
                </div>
            </div>
            <div class="row row-btn row-btn-text">
                {{ 'vcu.code.labelLinkAfter' | transloco }}
                <p-button
                    id="codeBtnAfter"
                    name="codeBtnAfter"
                    label="{{ 'vcu.code.btnExit' | transloco }}"
                    severity="secondary"
                    [raised]="true"
                    (click)="closeModal()">
                </p-button>
            </div>
        </form>
        <ng-template #elseBlock>
            <form class="form-align" [formGroup]="formCode" (ngSubmit)="onSubmitCode()">
                <!-- {{ form.value | json }} -->
                <div class="row">
                    <strong>{{ 'vcu.code.question' | transloco }}</strong>
                </div>
                <div class="row">
                    <p-inputOtp 
                        formControlName="code"
                        [integerOnly]="true" 
                        [readonly]="true"
                        [length]="getCodeLength()" />
                </div>
                <div class="row row-btn">
                    <p-button
                        id="codeBtnAfter"
                        name="codeBtnAfter"
                        label="{{ 'vcu.code.btnAfter' | transloco }}"
                        severity="secondary"
                        [raised]="true"
                        (click)="this.modalGoPage3()">
                    </p-button>
                    <p-button
                        id="codeBtnActive"
                        name="codeBtnActive"
                        label="{{ 'vcu.code.btnActive' | transloco }}"
                        type="submit"
                        severity="danger"
                        [raised]="true">
                    </p-button>
                </div>
            </form>
        </ng-template>
    </div>
    <div class="modal-content-page3" [(style.display)]="modalpage[2]">
        <div class="modal-left modal-left-sucess">
            <h2>{{ 'vcu.sucess.title' | transloco }}</h2>
            <p>{{ 'vcu.sucess.msg' | transloco }}</p>
            <div class="row row-btn">
                <!-- <p-button
                    id="sucessGarage" name="sucessGarage"
                    label="{{ 'vcu.sucess.btnSecondary' | transloco }}"
                    severity="secondary"
                    [raised]="true"
                    (click)="addVcuToGarage()">
                </p-button> -->
                <p-button
                    id="sucessSubmit" name="sucessSubmit"
                    label="{{ 'vcu.sucess.btnSubmit' | transloco }}"
                    severity="danger"
                    [raised]="true"
                    (click)="showDialog = false">
                </p-button>
            </div>
        </div>
        <div class="modal-right">
            <div class="img img-end"></div>
        </div>
    </div>
</p-dialog>
<p-dialog 
    id="linkVcu" 
    header="{{ 'vcu.detail.linkVcu.header' | transloco }}" 
    [modal]="true" 
    [(visible)]="showDialogLink" 
    [style]="{ 'width': '350px', 'height': '270px' }"
>
    <span style="margin-bottom: 10px;">{{ 'vcu.detail.linkVcu.msg' | transloco }}:</span>
    <div class="v-row">
        <p-dropdown id="projectLink"
            [options]="projectOptions" 
            [(ngModel)]="projectLink"
            name="projectLink"
            optionLabel="name"  
            appendTo="body"
            placeholder="{{ 'vcu.detail.linkVcu.project' | transloco }}"
            emptyMessage="{{ 'noResult' | transloco }}"
            [ngClass]="projectLinkInvalid"
            (onChange)="projectLinkInvalid = ''"
            [style]="{ 'width': '100%', 'margin-top': '10px' }"/>
    </div>
    <div style="display: flex; justify-content: flex-end; margin-top: 35px;">
        <p-button 
            id="dialog-cancel" 
            label="{{ 'vcu.detail.linkVcu.cancel' | transloco }}" 
            severity="secondary" 
            (onClick)="showDialogLink = false" />
        <p-button 
            id="dialog-save" 
            label="{{ 'vcu.detail.linkVcu.save' | transloco }}" 
            (onClick)="linkVCU()"
            [style]="{ 'margin-left': '20px' }" />
    </div>
</p-dialog>
