<app-dashboard-template>
    <div class="c-container">
        <p-tabMenu [model]="items" [ngStyle]="{'width': '100%'}" />
        <form class="c-form" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="white">
                <!-- {{ form.value | json }} -->
            </div>
            <p-dropdown id="country"
                [options]="countryOptions" 
                formControlName="country" 
                name="country"
                (onChange)="setIsBrasil()"
                optionLabel="name" 
                placeholder="{{ 'user.account.country' | transloco }}"
                emptyMessage="{{ 'noResult' | transloco }}"/>
            <input id="name" 
                class="name" 
                formControlName="name" 
                name="name"
                type="text" 
                [placeholder]="placeHolderName" 
                pInputText />
            <!-- <input id="docNumber"
                class="docNumber"
                formControlName="docNumber"
                name="docNumber"
                type="text"
                placeholder="{{ 'user.account.docNumber' | transloco }}"
                (blur)="getMaskDocNumber()"
                maxlength="18"
                pInputText /> -->
            <input id="docNumber" 
                formControlName="docNumber" 
                name="docNumber"
                placeholder="CPF/CNPJ*"
                maxlength="18"
                [style]="{'width':'100%'}" 
                pInputText />
            <p-calendar *ngIf="showBirthDate"
                id="birthDate"
                placeholder="{{ 'user.account.birthDate' | transloco }}*"
                formControlName="birthDate"
                name="birthDate"
                [minDate]="minDate" 
                [maxDate]="maxDate" 
                dateFormat="dd/mm/yy"
                [showIcon]="true"
                [showOnFocus]="false"  />
            <p-inputMask *ngIf="isBrasil"
                id="phone" 
                formControlName="phone" 
                name="phone"
                mask="+99 (99) 99999-9999" 
                unmask="true"
                placeholder="{{ 'user.account.phone' | transloco }}*"
                [style]="{'width':'100%'}" />
            <p-inputMask *ngIf="!isBrasil"
                id="phone" 
                formControlName="phone" 
                name="phone"
                mask="+99999999999?9999" 
                unmask="true"
                placeholder="{{ 'user.createAccount.phone' | transloco }}*"
                [style]="{'width':'100%'}" />
            <input class="email" 
                type="email"
                id="email" name="email"
                formControlName="email" 
                placeholder="{{ 'user.account.email' | transloco }}" 
                pInputText />
            <!-- <p-checkbox id="checkNewsletter"
                formControlName="checkNewsletter"
                [binary]="true"
                label="{{ 'user.account.checkNewsletter' | transloco }}"
                inputId="checkNewsletter" /> -->
            <p-selectButton id="language"
                name="language"
                formControlName="language" 
                [options]="languageOptions" 
                optionLabel="name"
                (click)="fixLanguage()">
                <ng-template let-item pTemplate>
                    <div [class]="item.class"></div>
                </ng-template>
            </p-selectButton>
            <div class="c-row">
                <p-inputSwitch id="checkNewsletter"
                    formControlName="checkNewsletter" />
                <label id="labelCheckNewsletter" for="checkNewsletter">
                    {{ 'user.account.checkNewsletter' | transloco }}
                </label>
            </div>
            <div class="c-row">
                <p-inputSwitch 
                    id="inactive"
                    formControlName="inactive"
                    (onChange)="confirmInactive($event)"/>
                <label id="labelInactive" for="inactive">
                    {{ 'user.account.inactive' | transloco }}
                </label>
            </div>
            <p-dropdown *ngIf="f.inactive.value"
                id="inactiveReason"
                [options]="inactiveReasonOptions" 
                formControlName="inactiveReason" 
                name="inactiveReason"
                optionLabel="description" 
                placeholder="{{ 'user.account.inactiveReason' | transloco }}"
                emptyMessage="{{ 'noResult' | transloco }}"/>
            
            <p-messages id="messages"
                [style]="{'font-size':'15px'}"/>
            <p-toast key="toast" />

            <div class="btns">
                <p-button id="btnBackLogin"
                    severity="secondary"
                    [raised]="true"
                    [loading]="backBtnLoading"
                    (click)="backPage()"
                    label="{{'user.account.btnBack' | transloco}}">
                </p-button>
                <p-button
                    type="submit"
                    severity="danger"
                    [raised]="true"
                    [loading]="submitBtnLoading"
                    label="{{'user.account.btnSave' | transloco}}">
                </p-button>
            </div>
            <div class="c-row footerMsg" style="padding-bottom: 50px;">
                {{'user.account.footerMsg' | transloco}}
            </div>
        </form>
    </div>
    <p-confirmDialog></p-confirmDialog>    
</app-dashboard-template>
