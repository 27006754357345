import { Equipment } from "../equipment";
import { Image } from "../Image";
import { Item } from "../Item";
import { MapProject } from "../map-project";
import { Share } from "../share";
import { Vehicle } from "../vehicle";
import { Vcu } from "./vcu";
import { LogProject } from '../log-project';

export class Project {

    id: string;
    favorite: boolean;
    name: string;
    application: Item | null;
    vehicle: Vehicle | null;
    tags: string[];
    detail: string;
    vcus: Vcu[];
    equipments: Equipment[];
    images: Image[];
    primaryImage: Image | null;
    sharedBy: Share | null;
    sharedWith: Share[];
    maps: MapProject[];
    logs: LogProject[];
    instagram: string;

    constructor(
        id = '',
        favorite = true,
        name = '',
        application: Item | null = null,
        vehicle: Vehicle | null = null,
        tags = new Array<string>(),
        detail = '',
        vcus = new Array<Vcu>(),
        equipments = new Array<Equipment>(),
        images = new Array<Image>(),
        primaryImage: Image | null = null,
        sharedBy: Share | null = null,
        sharedWith = new Array<Share>(),
        maps = new Array<MapProject>(),
        logs = new Array<LogProject>(),
        instagram = ''
    ) {
        this.id = id;
        this.favorite = favorite;
        this.name = name;
        this.application = application;
        this.vehicle = vehicle;
        this.tags = tags;
        this.detail = detail;
        this.vcus = vcus;
        this.equipments = equipments;
        this.images = images;
        this.primaryImage = primaryImage;
        this.sharedBy = sharedBy;
        this.sharedWith = sharedWith;
        this.maps = maps;
        this.logs = logs;
        this.instagram = instagram;
    }
}
