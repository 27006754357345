
<div class="card last-card" [(style.display)]="lastCardClass">
    <div class="card-container last-card-container">
        <div class="content">
            <i class="pi pi-plus-circle"></i>
            <h2>{{ labelLastCard }}</h2>
        </div>
    </div>
</div>

<div class="card" *ngIf="vcu" [(style.display)]="cardClass">
    <div class="card-img">
        <div class="img" [ngStyle]="{'background-image': formatImage(vcu.image?.href) }"></div> <!-- img-pending -->
    </div>
    <div class="card-container">
        <div class="card-header" [ngClass]="cardHeaderClass"> <!-- card-header-pending -->
            <div class="title">
                <h1>{{ vcu.surname }}</h1>
                <h3 class="subtitle" [ngClass]="subtitleClass"> <!-- subtitle-pending -->
                    {{ 'vcu.card.pendingActivate' | transloco }} (!)
                </h3>
            </div>
        </div>
        <div class="card-body" [ngClass]="bodyClass"> <!-- card-body-pending -->
            <div class="row">
                <p *ngIf="showProjectName"><b>{{ 'vcu.card.project' | transloco }}: </b>{{ showProjectName }}</p>
                <p><b>{{ 'vcu.card.generalVersion' | transloco }}: </b>{{ vcu.generalVersionDate | date: 'dd/MM/yyyy' }}</p>
            </div>
            <p><b>{{ 'vcu.card.serial' | transloco }}: </b>{{ vcu.serialNumber}}</p>
            <div class="row">
                <p class="green"><b>{{ 'vcu.card.status' | transloco }}: </b>{{ descriptionStatus }}</p>
                <p><b>{{ 'vcu.card.lastAcess' | transloco }}: </b>{{ vcu.lastAcessDate | date: 'dd/MM/yyyy' }}</p>
            </div>
        </div>
        <div class="card-options">
            <div class="left">
                <!-- <p-button icon="pi pi-pencil" />
                <p-button icon="pi pi-trash"  /> -->
            </div>
            <div class="right">
                <p-button *ngIf="showUnlink" class="black-btn" icon="pi pi-sort-alt-slash" (onClick)="unlinkVcu()"/>
                <p-button id="btnVcuDetail"
                    name="btnVcuDetail"
                    label="{{ 'vcu.card.detail' | transloco }}" 
                    icon="pi pi-chevron-right" 
                    iconPos="right" 
                    (onClick)="goVcuDetail()"
                    severity="danger" />
            </div>
        </div>
    </div>
<div class="polygon" [ngClass]="polygonClass" [(style.display)]="cardClass"></div> <!-- polygon-pending -->
