import { VcuAttributes } from "../vcu-attributes";

export class VcuRequest {
    serial?: string;
    nickname?: string;
    familyID?: number | string;
    modelID?: number | string;
    resellerID?: number | string;
    invoiceNumber?: string;
    purchaseDate?: string;
    vcuAttributes?: VcuAttributes | null;

    constructor(
        serial?: string, 
        nickname?: string, 
        familyID?: number | string,
        modelID?: number | string,
        resellerID?: number | string,
        invoiceNumber?: string,
        purchaseDate?: string,
        tags?: string[],
        reasonUnlink?: string
    ) {
        this.serial = serial;
        this.nickname = nickname;
        this.familyID = familyID;
        this.modelID = modelID;
        this.resellerID = resellerID;
        this.invoiceNumber = invoiceNumber;
        this.purchaseDate = purchaseDate;
        this.vcuAttributes = new VcuAttributes(tags, reasonUnlink);
    }
}