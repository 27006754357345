import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { CreateAccountComponent } from './create-account/create-account.component';
import { ActivateAccountComponent } from './activate-account/activate-account.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

import { InputTextModule } from 'primeng/inputtext';
import { InputGroupModule } from 'primeng/inputgroup';
import { PasswordModule } from 'primeng/password';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { ButtonModule } from 'primeng/button';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from "primeng/message"; 
import { ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { InputMaskModule } from 'primeng/inputmask';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CheckboxModule } from 'primeng/checkbox';
import { InputOtpModule } from 'primeng/inputotp';
import { TranslocoRootModule } from '../../transloco-root.module';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from '../../app-routing.module';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AccountComponent } from './account/account.component';
import { DashboardTemplateComponent } from '../dashboard/dashboard-template/dashboard-template.component';
import { TabMenuModule } from 'primeng/tabmenu';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

@NgModule({
  declarations: [
    LoginComponent,
    CreateAccountComponent,
    ActivateAccountComponent,
    ForgotPasswordComponent,
    AccountComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    InputTextModule,
    InputGroupModule,
    InputGroupAddonModule,
    PasswordModule,
    ButtonModule,
    MessagesModule,
    MessageModule,
    ReactiveFormsModule,
    DropdownModule,
    CalendarModule,
    InputMaskModule,
    SelectButtonModule,
    TranslocoRootModule,
    HttpClientModule,
    CheckboxModule,
    InputOtpModule,
    DashboardTemplateComponent,
    TabMenuModule,
    InputSwitchModule,
    TooltipModule,
    ToastModule,
    ConfirmDialogModule
  ],
  exports: [
    LoginComponent,
    CreateAccountComponent,
    ActivateAccountComponent,
    ForgotPasswordComponent,
    AccountComponent
  ],
  providers: [provideAnimations(), provideAnimationsAsync(), MessageService]
})
export class UserModule { }
