import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { MenuItem, Message } from 'primeng/api';
import { Project } from 'src/app/model/form/project';
import { Vcu } from 'src/app/model/form/vcu';
import { Item } from 'src/app/model/Item';
import { AccountService } from 'src/app/service/account.service';
import { FamilyService } from 'src/app/service/family.service';
import { ModelService } from 'src/app/service/model.service';
import { ProjectService } from 'src/app/service/project.service';
import { ResellerService } from 'src/app/service/reseller.service';
import { VcuService } from 'src/app/service/vcu.service';
import { CustomValidator } from 'src/shared/custom-validator';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register-vcu',
  templateUrl: './register-vcu.component.html',
  styleUrl: './register-vcu.component.css'
})
export class RegisterVCUComponent implements OnInit, OnChanges {
  
  @Input()
  showDialog: boolean = true;

  @Input()
  vcu: Vcu | null = null;

  @Input()
  editMode: boolean = false;

  @Output()
  closeDialog = new EventEmitter<any>();

  public form!: FormGroup;
  public formCode!: FormGroup;

  sidebarVisible2: boolean = true;
  modalpage: string[] = [];
  stepItems: MenuItem[] | undefined;
  messages: Message[] = [];
  active: number = 0;
  dialogHeader: string = '';
  familyOptions: Item[] = [];
  modelOptions: Item[] = [];
  resellerOptions: Item[] = [];
  projectOptions: Item[] = [];
  vcuLinkOut: boolean = false;
  disabledSerialNumber: boolean = true;
  labelBtnSave: string = '';
  saveVcuBtnLoading: boolean = false;
  showDialogLink: boolean = false;
  projectLink: Item | null = null;
  projectLinkInvalid: string = '';
  token: string | null = '';
  userID: string | null = '';
  vcus: Vcu[] = [];
  invalidSerialNumber: boolean = false;
  loading: boolean = false;
  lang: string = 'pt-br';
  pdfUrl = '';

  constructor(private formBuilder: FormBuilder,
              private translocoService: TranslocoService,
              private accountService: AccountService,
              private vcuService: VcuService,
              private projectService: ProjectService,
              private familyService: FamilyService,
              private modelService: ModelService,
              private resellerService: ResellerService,
              private confirmationService: ConfirmationService,
              private router: Router) {

  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.vcu?.currentValue) {
      this.vcu = changes.vcu.currentValue;
      this.familyOptions.filter(item => this.vcu && item.id === this.vcu.family?.id).map(it => (this.vcu ? this.vcu.family = it : null));
      this.modelOptions.filter(item => this.vcu && item.id === this.vcu.model?.id).map(it => (this.vcu ? this.vcu.model = it : null));
      this.resellerOptions.filter(item => this.vcu && item.id === this.vcu.reseller?.id).map(it => (this.vcu ? this.vcu.reseller = it : null));
      this.createForm(this.vcu);
    }
    if(changes.editMode && changes.editMode?.currentValue !== changes.editMode?.previousValue) {
      this.editMode = changes.editMode?.currentValue;
      if(this.editMode) {
        this.isEditMode();
      }  else {
        this.noEditMode();
      }
    }
    if(changes.showDialog && changes.showDialog?.currentValue !== changes.showDialog?.previousValue) {
      this.showDialog = changes.showDialog.currentValue;
      if(this.showDialog) {
        //this.openingDialog();
        if(this.vcu) {
          this.messages = [];

          // this.familyOptions.filter(item => this.vcu && item.id === this.vcu.family?.id).map(it => (this.f.family ? this.f.family.setValue(it) : null));
          // this.modelOptions.filter(item => this.vcu && item.id === this.vcu.model?.id).map(it => (this.f.model ? this.f.model.setValue(it) : null));
          // this.resellerOptions.filter(item => this.vcu && item.id === this.vcu.reseller?.id).map(it => (this.f.reseller ? this.f.reseller.setValue(it) : null));
          if(!this.editMode) {
            this.changeOptions();
          }
        }
      }
    }
  }
  ngOnInit() {
    this.userID = localStorage.getItem('idUser');
    this.familyService.getAllFamily().subscribe(data => {
      this.familyOptions = FamilyService.toItemList(data);
      this.changeOptions();
    });
    this.modelService.getAllModel().subscribe(data => {
      this.modelOptions = ModelService.toItemList(data);
      this.changeOptions();
    });
    this.resellerService.getAllReseller().subscribe(data => {
      this.resellerOptions = ResellerService.toItemList(data);
      this.changeOptions();
    });
    this.messages = [];
    this.translocoService.selectTranslate(this.editMode ? 'vcu.header3' : 'vcu.header').subscribe(value =>
      this.dialogHeader = value
    );

    if(this.editMode) {
      this.isEditMode();
    }  else {
      this.noEditMode();
    }
    this.active = 0;
    // this.vcu = this.vcu ?? new Vcu();
    this.createForm(this.vcu);
    let language = localStorage.getItem('language');
    this.pdfUrl = language === 'pt-br' ? 'assets/docs/Instrucao numero serial - PT.pdf' : 'assets/docs/Instrucao numero serial - EN.pdf';
    if(language && language !== null)
      this.lang = language;
    this.changeLanguage();
  }

  openingDialog() {
    this.token = localStorage.getItem('token');
    this.userID = localStorage.getItem('idUser');
    if(this.token) {
      this.f.family.setValue(this.vcu?.family);
      this.f.model.setValue(this.vcu?.model);
      this.f.reseller.setValue(this.vcu?.reseller);
    }
    //   this.familyService.getAllFamily().subscribe(data => {
    //       this.familyOptions = FamilyService.toItemList(data);
    //       this.changeOptions();
    //   });
    //   this.modelService.getAllModel().subscribe(data => {
    //     this.modelOptions = ModelService.toItemList(data);
    //     this.changeOptions();
    //   });
    //   this.resellerService.getAllReseller().subscribe(data => {
    //     this.resellerOptions = ResellerService.toItemList(data);
    //     this.changeOptions();
    //   });
    // }
    
    // if(this.userID) {
    //   this.accountService.FindUserVcus(this.userID ?? '').subscribe(data => {
    //     if(data) this.vcus = VcuService.userVcuToVcus(data);
    //   });
    // }
  }

  noEditMode() {
    this.vcu = new Vcu();
    this.modalpage = this.goPage(1);
    this.translocoService.selectTranslateObject('vcu.steps').subscribe(value =>
      this.stepItems = value
    ).unsubscribe();
    this.translocoService.selectTranslateObject('vcu.next').subscribe(value =>
      this.labelBtnSave = value
    ).unsubscribe();
  }

  isEditMode() {
    this.f.serialNumber.disable();
    this.modalpage = this.goPage(1);
    this.stepItems?.pop();
    this.translocoService.selectTranslateObject('vcu.stepsEdit').subscribe(value =>
      this.stepItems = value
    ).unsubscribe();
    this.translocoService.selectTranslateObject('vcu.save').subscribe(value =>
      this.labelBtnSave = value
    ).unsubscribe();
  }

  async onSubmit() {
    this.checkInputs();
    if(this.form.valid) {      
      this.messages = [];
      this.saveVcuBtnLoading = true;
      this.userID = localStorage.getItem('idUser') ?? '';
      if(this.editMode) {
        this.vcuService.updateVcuNoProject(this.form.value).subscribe({
          next: vcuResponse => {
            this.vcu = VcuService.toVcu(vcuResponse);
            this.saveVcuBtnLoading = false;
            this.closeModal();
          },
          error: _ => {
            this.translocoService.selectTranslate('errorConection').subscribe(value => this.messages = [
              { severity: 'error', summary: value}
            ]);
            this.saveVcuBtnLoading = false;
          }
        });
      } else {
        this.accountService.FindUserVcuSerial(this.userID ? this.userID : '', this.f.serialNumber.value,'').subscribe({
          next: response => {
            if(response.length > 0){
              response.map(vcu => {
                if(vcu.status === 'DI'){
                  this.translocoService.selectTranslateObject('vcu.serialAlready').subscribe(value => 
                    this.confirmationService.confirm({
                      header: value.header,
                      message: value.message,
                      icon: 'pi pi-info-circle',
                      acceptButtonStyleClass:"p-button-danger",
                      acceptLabel: value.yes,
                      acceptIcon:"none",
                      accept: () => {
                        this.saveVcuBtnLoading = false;
                        this.router.navigate(['/profile/trash']);
                      },
                      rejectVisible: false
                    })
                  ).unsubscribe();
                } else {
                  this.translocoService.selectTranslateObject('vcu.serialAlreadyExist').subscribe(value => 
                    this.confirmationService.confirm({
                      header: value.header,
                      message: value.message,
                      icon: 'pi pi-info-circle',
                      acceptButtonStyleClass:"p-button-danger",
                      acceptLabel: value.yes,
                      acceptIcon:"none",
                      accept: () => {
                        this.saveVcuBtnLoading = false;
                        this.router.navigate(['/vcu-management']);
                      },
                      rejectVisible: false
                    })
                  ).unsubscribe();
                }
              })
              // this.translocoService.selectTranslateObject('vcu.serialAlready').subscribe(value => 
              //   this.confirmationService.confirm({
              //     header: value.header,
              //     message: value.message,
              //     icon: 'pi pi-info-circle',
              //     acceptButtonStyleClass:"p-button-danger",
              //     acceptLabel: value.yes,
              //     acceptIcon:"none",
              //     accept: () => {
              //       this.saveVcuBtnLoading = false;
              //       this.router.navigate(['/profile/trash']);
              //     },
              //     rejectVisible: false
              //   })
              // ).unsubscribe(); 
            }else{
              this.vcuService.createVcu(this.form.value).subscribe({
                next: vcuResponse => {
                  if(vcuResponse) {
                    this.vcu = VcuService.toVcu(vcuResponse);
                    this.vcuService.setProjectID('');
                    this.saveVcuBtnLoading = false;
                    this.modalGoPage3();
                  } else {
                    this.translocoService.selectTranslate('vcu.error.exist').subscribe(value => this.messages = [
                      { severity: 'error', summary: value}
                    ]);
                    this.saveVcuBtnLoading = false;
                  }
                },
                error: _ => {
                  this.translocoService.selectTranslate('errorConection').subscribe(value => this.messages = [
                    { severity: 'error', summary: value}
                  ]);
                  this.saveVcuBtnLoading = false;
                }
              });
            }
          },
          error: _=>{}
        });
      }
    } else {
      this.translocoService.selectTranslate('inputRequired').subscribe(value => this.messages = [
        { severity: 'error', summary: value }
      ]);
    }
  }

  onSubmitCode() {
    if(this.form.valid && this.formCode.valid) {
    } else {
    }
    this.modalGoPage3();
  }

  checkInputs() {
    if(this.f.family.invalid) this.f.family.markAsDirty()
    if(this.f.model.invalid) this.f.model.markAsDirty()
    if(this.f.surname.invalid) this.f.surname.markAsDirty()
    if(this.editMode) {
      if(this.f.reseller.invalid) this.f.reseller.markAsDirty()
    } else {
      this.checkInvalidSerialNumber();
    }
  }

  createForm(vcu: Vcu | null) {
    if(vcu) {
      this.form = this.formBuilder.group({
        id: [vcu.id],
        image: [vcu.image],
        surname: [vcu.surname, CustomValidator.getRequired()],
        family: [vcu.family, CustomValidator.getRequired()],
        model: [vcu.model, CustomValidator.getRequired()],
        serialNumber: [{value: vcu.serialNumber, disabled: false}, CustomValidator.getRequired()],
        reseller: [vcu.reseller],
        invoice: [vcu.invoice],
        purchaseDate: [vcu.purchaseDate],
        tags: [vcu.tags],
        generalVersionDate: [vcu.generalVersionDate],
        status: [vcu.status],
        lastAcessDate: [vcu.lastAcessDate],
        // linked: [vcu.linked],
        // reasonLinked: [vcu.reasonLinked],
      })
    }
    this.formCode = this.formBuilder.group({
      // code: [null]
      code: [123456]
    })
    this.changeOptions();
    // this.f.serialNumber.disable();
    // this.f.model.disable();
    if(this.editMode) {
      // this.f.family.disable();
      // this.f.reseller.setValidators(CustomValidator.getRequired());
    }
  }

  changeOptions() {
    if(!this.editMode) {
      if(this.form && this.familyOptions.length > 0) this.f.family.setValue(this.familyOptions[0]);
      if(this.form && this.modelOptions.length > 0) this.f.model.setValue(this.modelOptions[0]);
      if(this.form && this.resellerOptions.length > 0) this.f.reseller.setValue(this.resellerOptions[0]);
    }
  }

  addVcuToGarage() {
    this.userID = localStorage.getItem('idUser');
    this.projectService.findAllProjectSummary(this.userID ?? '').subscribe(data => {
      this.projectOptions = []
      let projects: Project[] = this.projectService.projectSummaryToProject(data);
      for(let project of projects) {
        this.projectOptions.push(new Item(project.id, project.name)); 
      }
      if(this.projectOptions.length > 0) 
        this.projectLink = this.projectOptions[0];
      this.showDialogLink = true;
    });
  }

  linkVCU() {
    //TODO BACKEND vincular VCU ao Projeto this.form.value -> projectLink.id
    if(this.vcu && this.projectLink) {
      this.vcuService.setVcuID(this.vcu.id);
      this.vcuService.setProjectID(this.projectLink.id+'');
      this.vcuService.linkVcuInProject().subscribe({});
    } else {
      this.projectLinkInvalid = 'ng-dirty ng-invalid';
    }
  }
  checkInvalidSerialNumber() {
    this.invalidSerialNumber = false;
    if(this.f.serialNumber.invalid) {
      this.f.serialNumber.markAsDirty();
      this.f.serialNumber.setErrors({'nomatch': true});
    }
    
    for(let vcu of this.vcus) {
      if(vcu.serialNumber === this.f.serialNumber.value) {
        this.f.serialNumber.markAsDirty();
        this.f.serialNumber.setErrors({'nomatch': true});
        this.invalidSerialNumber = true;
      }
    }
  }

  modalGoPage2() {
    this.active = 1;
    this.vcuLinkOut = false;
    this.modalpage = this.goPage(2);
    this.translocoService.selectTranslate('vcu.header2').subscribe(value =>
      this.dialogHeader = value
    );
  }

  modalGoPage3() {
    this.modalpage = this.goPage(3);
    this.dialogHeader = '';
  }
  
  public getCodeLength() {
    if(this.fc.code.value !== null) {
      return this.fc.code.value.toString().length;
    }
    return 0;
  }
  
  closeModal() {
    if(this.editMode) {
      this.isEditMode();
    }  else {
      this.noEditMode();
    }
    this.form.reset();
    this.closeDialog.emit();
  }

  goPage(index: number) {
    let aux = ['none', 'none', 'none', 'none'];
    aux[index-1] = '';
    return aux;
  }

  public changeLanguage(languageCode?: string | null): void {
    if(!languageCode || languageCode === null)
      languageCode = localStorage.getItem('language');
    if(languageCode && languageCode !== null)
      this.translocoService.setActiveLang(languageCode);
  }
  get f() { return this.form.controls; }

  get fc() { return this.formCode.controls; }

  findAllProjectSummary(userID: string | null){  
    this.projectOptions = [];
    if(userID === null) return;
       this.projectService.findAllProjectSummary(userID).subscribe({
      next: (data) =>{
        data.forEach( register => {
          this.projectOptions.push(new Item(register.projectID, register.projectName));
        });
      },
      error: (error) =>{}
    });
  }
}