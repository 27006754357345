import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TranslocoRootModule } from 'src/app/transloco-root.module';
import { ButtonModule } from 'primeng/button';
import { VcuModule } from '../vcu/vcu.module';
import { DashboardTemplateComponent } from "./dashboard-template/dashboard-template.component";
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { RegisterModule } from '../register/register.module';
import { AccordionModule } from 'primeng/accordion';
import { InputIconModule } from 'primeng/inputicon';
import { IconFieldModule } from 'primeng/iconfield';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { CardModule } from '../card/card.module';

@NgModule({
  declarations: [
    DashboardComponent
  ],
  imports: [
    CommonModule,
    ButtonModule,
    TranslocoRootModule,
    VcuModule,
    CardModule,
    RegisterModule,
    DashboardTemplateComponent,
    AccordionModule,
    InputIconModule,
    IconFieldModule,
    ReactiveFormsModule,
    FormsModule,
    InputTextModule,
    ProgressSpinnerModule,
    DialogModule,
    ToastModule
  ],
  exports: [
  ],
  providers: [provideAnimations(), provideAnimationsAsync()]
})
export class DashboardModule { }
