import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Message } from 'primeng/api';
import { AuthService } from 'src/app/service/auth.service';
import { CustomValidator } from 'src/shared/custom-validator';
import { CognitoException } from 'src/app/model/enum/cognito-exception';
import { Language } from 'src/app/model/language';
import { LANGUAGE_LIST } from 'src/app/model/mockup/language-mockup';
import { GeolocationService } from 'src/app/service/geolocationService';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrl: './activate-account.component.css'
})
export class ActivateAccountComponent implements OnInit {

  public form!: FormGroup;

  name: string | null = null;
  email: string = '';
  code: string = '';

  isDivCreate: string;
  isDivActivated: string;
  isDivExpired: string;
  loginUrl: string;
  createAccountUrl: string;
  messagesCode: Message[] = [];

  languageOptions: Language[];
  DEFAULT_LANGUAGE: Language;
  lastLanguage: Language;
  language: Language;

  countdown: number = 60; 
  isButtonDisabled: boolean = true;
  private intervalId: any;

  constructor(private formBuilder: FormBuilder, 
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translocoService: TranslocoService,
    private authService: AuthService,
    private geolocationService: GeolocationService) {

    this.isDivCreate='';
    this.isDivActivated='none'; //none
    this.isDivExpired='none'; //none

    this.loginUrl = '/login';
    this.createAccountUrl = '/create-account';

    this.languageOptions = LANGUAGE_LIST as [];
    this.DEFAULT_LANGUAGE = this.languageOptions[0];
    this.language = this.DEFAULT_LANGUAGE;
    this.lastLanguage = this.DEFAULT_LANGUAGE;
  }

  ngOnInit(): void {
    let lan = localStorage.getItem('language');
    this.createForm(this.code);
    this.changeLanguage(this.getLanguage(lan).name);
    this.refreshLanguageOptions();
    this.getLocation();
    this.setTitle();
    let email = localStorage.getItem('email-activate-account')
    localStorage.removeItem('email-activate-account');
    this.name = localStorage.getItem('name-activate-account')
    localStorage.removeItem('name-activate-account');
    this.startCountdown();
    // this.name = this.router.getCurrentNavigation()?.extras.state?.['name'];
    // let email = this.router.getCurrentNavigation()?.extras.state?.['email'];
    if(email !== null) {
      this.email = email;
    } else {
      this.router.navigate([this.loginUrl]);
    }
  }

  startCountdown(): void {
    this.isButtonDisabled = true;
    this.countdown = 60;

    this.intervalId = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        this.isButtonDisabled = false;
        clearInterval(this.intervalId);
      }
    }, 1000);
  }

  createForm(code: string) {
    this.form = this.formBuilder.group({
      code: [code, CustomValidator.getRequired()]
    })
  }

  onSubmit() {
    this.authService.confirmSignUp(this.email, this.f.code.value).then(result => {
          this.showActivated();
    }).catch(error => {
      let messageError = '';
      this.translocoService.selectTranslateObject('cognito.insuccess').subscribe(value =>{
        if(CognitoException.NOT_AUTHORIZED === error.name)
          messageError = value.NotAuthorizedException;
        if(CognitoException.USER_NOT_CONFIRMED === error.name)
          messageError = value.UserNotConfirmedException;
        if(CognitoException.NETWORKING_ERROR === error.name)
          messageError = value.NetworkingError;
        if(CognitoException.CODE_MISMATCH === error.name)
          messageError = value.CodeMismatchException;
        if(CognitoException.EXPIRED_CODE === error.name)
          messageError = value.ExpiredCodeException;

        if(messageError.length< 1)
          messageError = error;

        this.messagesCode = [{ severity: 'error', summary: messageError }]
      }).unsubscribe();
    });
  }

  sendEmailAgain() {
    if (!this.isButtonDisabled) {
      this.authService.resendConfirmationCode(this.email, this.name, localStorage.getItem('language'), 'signup').then(() => {
      }).catch(() => {
        this.translocoService.selectTranslate('errorConection').subscribe(value => this.messagesCode = [
          { severity: 'error', summary: value}
        ]);
      });
      this.startCountdown();
    }
  }

  redirect() {
    this.router.navigate([this.loginUrl]);
  }

  expired() {
    this.router.navigate([this.createAccountUrl]);
  }

  showCreate() {
    this.isDivCreate = "";
    this.isDivActivated = "none";
    this.isDivExpired="none";
  }

  showActivated() {
    this.isDivCreate = "none";
    this.isDivActivated = "";
    this.isDivExpired="none";
    setTimeout(() => {
      this.router.navigate([this.loginUrl]);
    }, 5000);  //5s
  }

  showExpired() {
    this.isDivCreate = "none";
    this.isDivActivated = "none";
    this.isDivExpired="";
  }
  
  setTitle() {
    this.translocoService.selectTranslate('title.activate-account').subscribe(value => 
      this.titleService.setTitle(value)
    );
  }

  fixLanguage(event:any) {
    this.language = event.option;    
    if(this.lastLanguage !== this.language) {
        this.languageOptions
          .filter(opt => opt.name === this.language.name)
          .map(cty => cty.disabled = true)
        this.languageOptions
          .filter(opt => opt.name !== this.language.name)
          .map(cty => cty.disabled = false)

        this.lastLanguage = this.language;
        this.changeLanguage(this.language.name);
        localStorage.setItem('language', this.language.name);
    }
  }

  getLanguage(lan: string | null) : Language {
    for (let i = 0; i < this.languageOptions.length; i++) {
      if(this.languageOptions[i].name === lan) {
        return this.languageOptions[i];
      }
    }
    return this.DEFAULT_LANGUAGE;
  }

  public changeLanguage(languageCode: string | null): void {
    if(languageCode !== null)
      this.translocoService.setActiveLang(languageCode);
  }

  getLocation(){
    this.geolocationService.getCountry().subscribe((data: any) => {
      if (data.country === "BR") {
        this.language = this.languageOptions[0];
      } else {
        this.language = this.languageOptions[1];
      }
    });
  }

  refreshLanguageOptions() {
    for (let i = 0; i < this.languageOptions.length; i++) {
      if(this.languageOptions[i].name === this.language.name)
        this.languageOptions[i].disabled = true;
      else
       this.languageOptions[i].disabled = false;
    }
  }


  get f() { return this.form.controls; }
}
