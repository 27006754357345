export const enviroment = {
    PRODUCTION: false,
    MOCKUP: false,
    COGNITO_REGION: 'us-east-1',
    COGNITO_USER_POOL_ID: 'us-east-1_YT9tyzqty',
    COGNITO_CLIENT_ID: '2prllsvrio0h3tc719sgc23hck',
    COGNITO_SECRET_HASH: 'pmnbq8ek38s99gfb396a8k2h1dpl4l75ocj243nrb4nbn5hbma5',
    USER_PASSWORD_AUTH: 'USER_PASSWORD_AUTH',
    REFRESH_TOKEN_AUTH: 'REFRESH_TOKEN_AUTH',
    BASE_URL_API: 'https://hml-api.fueltech.cloud',
    BASE_URL: 'https://www.hml.fueltech.cloud'
}
// USER_SRP_AUTH = 'USER_SRP_AUTH';

