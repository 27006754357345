import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { VcuModule } from './module/vcu/vcu.module';
import { UserModule } from './module/user/user.module';
import { ProjectModule } from './module/project/project.module';
import { DashboardModule } from './module/dashboard/dashboard.module';
import { RegisterModule } from './module/register/register.module';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpRequestInterceptor } from '../app/interceptors/http.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    VcuModule,
    UserModule,
    ProjectModule,
    RegisterModule,
    DashboardModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
  ],
  providers: [
    provideAnimations(), 
    provideAnimationsAsync(),
    {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpRequestInterceptor,
    multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
