export class Equipment {
    id: number;
    name: string;
    category: string;

    constructor(
        id = 0,
        name = '',
        category = ''
    ) {
        this.id = id;
        this.name = name;
        this.category = category;
    }
}
