<app-dashboard-template>
    <!-- {{ form.value | json }} -->
    <p-confirmDialog />
    <div id="add-vcu" class="cards-add-vcu" fragment="garage">
        <h1>{{ 'dashboard.myVcus' | transloco }}</h1>
        <p-button
            icon="pi pi-plus-circle" 
            id="addVcuBtn" name="addVcuBtn"
            [rounded]="true" 
            [text]="true" 
            size="large"
            (click)="createVcu()" />
    </div>
    <!-- <p-table
        #dtVcu
        [value]="vcus"
        dataKey="id"
        [rows]="10"
        [rowsPerPageOptions]="[10, 20, 30]"
        [loading]="loadingVcu"
        [paginator]="true"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{{ 'vcu.detail.tableVcu.currentPage' | transloco }}"
        [globalFilterFields]="['id', 'surname', 'status']"
        [tableStyle]="{ 'min-width': '75rem' }"
    >
        <ng-template pTemplate="caption">
            <div class="g-table-end">
                <p-iconField iconPosition="right" class="ml-auto">
                    <p-inputIcon>
                        <i class="pi pi-search"></i>
                    </p-inputIcon>
                    <input 
                        pInputText 
                        type="text" 
                        (input)="applyFilterGlobalVcu($event, 'contains')" 
                        placeholder="{{ 'vcu.detail.tableVcu.search' | transloco }}" />
                </p-iconField>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="id" style="width:20%">
                    {{ 'vcu.detail.tableVcu.id' | transloco }} <p-sortIcon field="id" />
                </th>
                <th pSortableColumn="surname" style="width:20%">
                    {{ 'vcu.detail.tableVcu.name' | transloco }} <p-sortIcon field="name" />
                </th>
                <th pSortableColumn="status" style="width:10%">
                    {{ 'vcu.detail.tableVcu.status' | transloco }} <p-sortIcon field="status" />
                </th>
                <th pSortableColumn="createdDate" style="width:20%">
                    {{ 'vcu.detail.tableVcu.dateRegister' | transloco }} <p-sortIcon field="dateRegister" />
                </th>
                <th pSortableColumn="purchaseDate" style="width:20%">
                    {{ 'vcu.detail.tableVcu.dateInstall' | transloco }} <p-sortIcon field="dateInstall" />
                </th>
                <th style="width:10%">{{ 'vcu.detail.tableVcu.action' | transloco }}</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-vc let-ri="rowIndex">
            <tr>
                <td>
                    {{ vc.id }}
                </td>
                <td>
                    {{ vc.surname }}
                </td>
                <td>
                    {{ vc.status }}
                </td>
                <td>
                    {{ vc.createdDate | date: 'dd/MM/yyyy'  }}
                </td>
                <td>
                    {{ vc.purchaseDate | date: 'dd/MM/yyyy'  }}
                </td>
                <td>
                    <p-button [rounded]="true" [text]="true" icon="pi pi-pencil" (click)="editVcu(vc)"/>
                    <p-button [rounded]="true" [text]="true" icon="pi pi-trash" (click)="removeVcu($event, ri, vc)" />
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="5">{{ 'vcu.detail.tableVcu.noFound' | transloco }}.</td>
            </tr>
        </ng-template>
    </p-table> -->
    <div class="cards-vcu">
        <div class="card-vcu" *ngFor="let vcu of vcus; let i = index">
            <app-card-vcu [vcu]="vcu" [showUnlink]="false" [showProjectName]="projectsName[i]" [projectID]="projectsID[i]"></app-card-vcu>
        </div>
    </div>
    <app-register-vcu [showDialog]="showVcuDialog" (closeDialog)="updateVcuDialog()" [vcu]="vcuEdit" [editMode]="editMode"></app-register-vcu>
</app-dashboard-template>