export class Image {
    id?: number;
    name: string;
    href?: string;
    size?: number;
    primary?: boolean;
  
    constructor(
        id?: number, 
        name: string = '', 
        href?: string, 
        size?: number,
        primary?: boolean
    ) {
        this.id = id;
        this.name = name;
        this.href = href;
        this.size = size;
        this.primary = primary;
    }
}