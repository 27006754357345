import { Country } from "../country";
import { Reason } from "../reason";

export class AccountRequest {
    userId: string;
    country: Country;
    name: string;
    docNumber: string;
    birthDate: string | null;
    phone: string;
    dialCode: string;
    phoneCountry: string;
    email: string;
    language: string;
    checkNewsletter: boolean;
    inactive: boolean;
    inactiveReason: Reason | null;
    firstAccess: boolean;
  
    constructor(
        userId: string = '',
        country: Country = new Country(),
        name: string = '',
        docNumber: string = '',
        birthDate: string | null = null,
        phone: string = '',
        dialCode: string = '',
        phoneCountry: string = '',
        email: string = '',
        language: string = '',
        checkNewsletter: boolean = false,
        inactive: boolean = false,
        inactiveReason: Reason | null = null,
        firstAccess: boolean = false) {
        this.userId = userId;
        this.country = country;
        this.name = name;
        this.docNumber = docNumber;
        this.birthDate = birthDate;
        this.phone = phone;
        this.dialCode = dialCode;
        this.phoneCountry = phoneCountry
        this.email = email;
        this.language = language;
        this.checkNewsletter = checkNewsletter;
        this.inactive = inactive;
        this.inactiveReason = inactiveReason;
        this.firstAccess = firstAccess;
    }
}
