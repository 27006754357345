import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ConfirmationService, MenuItem, Message, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Project } from 'src/app/model/form/project';
import { Vcu } from 'src/app/model/form/vcu';
import { LogProject } from 'src/app/model/log-project';
import { MapProject } from 'src/app/model/map-project';
import { Share } from 'src/app/model/share';
import { ProjectService } from '../../../service/project.service';
import { Vehicle } from "src/app/model/vehicle";
import { AccountService } from 'src/app/service/account.service';
import { VcuService } from 'src/app/service/vcu.service';
import { FileService} from 'src/app/service/file.service';
import { HttpResponse } from '@angular/common/http';
import { IMAGE_DEFAULT, IMAGE_PUNTO_LIST, IMAGE_VOYAGE_LIST } from 'src/app/model/mockup/image-mockup';
import { VcuAttributes } from 'src/app/model/vcu-attributes';
import { Location } from '@angular/common';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrl: './project.component.css'
})
export class ProjectComponent implements OnInit {
  @ViewChild('fileInputMap', { static: false }) fileInputMap!: ElementRef;
  @ViewChild('fileInputLog', { static: false }) fileInputLog!: ElementRef;

  items: MenuItem[] | undefined;
  logs: LogProject[];
  maps: MapProject[];
  shares: Share[];
  vcus: Vcu[];
  project: Project | null;
  responsiveOptions: any[] | undefined;
  backupLog: LogProject | null = null;
  backupMap: MapProject | null = null;
  projectsEdit: Project[];
  showVcuDialog: boolean = false;
  showEditProjectDialog: boolean = false;

  @ViewChild('dtLog') dtLog: Table | undefined;
  @ViewChild('dtMap') dtMap: Table | undefined;
  @ViewChild('dtShare') dtShare: Table | undefined;
  loadingLog: boolean = false;
  loadingMap: boolean = false;
  loadingShare: boolean = false;

  vehicle = new Vehicle();
  token: string | null = '';
  emailShare: string = '';
  userID: string = '';
  projectID: string = '';

  vcuUnlink: Vcu | null = null;
  showDialogUnlink: boolean = false;
  projectUnlinkInvalid: string = '';
  unlinkVcuBtnLoading: boolean = false;
  reasonUnlinkVcu: string = '';
  messages: Message[] = [];

  constructor(private translocoService: TranslocoService,
    private activatedRoute: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private titleService: Title,
    private projectService: ProjectService,
    private accountService: AccountService,
    private messageService: MessageService,
    private vcuService: VcuService,
    private fileService: FileService,
    private location: Location) {

    this.showVcuDialog = false;
    this.project = null;
    this.vcus = []
    this.logs = [];
    this.maps = [];
    this.shares = [];
    this.projectsEdit = [];

    this.loadingLog = false;
    this.loadingMap = false;
    this.loadingShare = false;
  }

  ngOnInit(): void {
    this.token = sessionStorage.getItem('token');
    this.changeLanguage();
    this.setTitle();
    this.messageService.clear();
    this.activatedRoute.params.subscribe(params => {
      this.projectID = params['id'];
      if(this.token) this.accountService.findUserActive().subscribe(
        data => {
          this.userID = data.userID;
          this.vcuService.setUserID(data.userID);
          this.vcuService.setProjectID(this.projectID);
          this.projectService.getProject(data.userID, this.projectID).subscribe(dataProject => {
            this.project = ProjectService.toProject(dataProject);
            this.shares = this.project?.sharedWith ?? [];
            this.vcus = this.project?.vcus ?? [];
            this.maps = this.project?.maps ?? [];
            this.logs = this.project?.logs ?? [];
            this.setImageDefault();
          });
        });
    });
    this.responsiveOptions = [
      {
        breakpoint: '1430px',
        numVisible: 3,
        numScroll: 1
      },
      {
        breakpoint: '1260px',
        numVisible: 2,
        numScroll: 1
      },
      {
        breakpoint: '870px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

  addVcu() {
    this.showVcuDialog = true;
  }

  async editProject() {
    this.showEditProjectDialog = true;
  }

  updateVcuDialog() {
    this.showVcuDialog = false;
    this.projectService.getProject(this.userID, this.projectID).subscribe(dataProject => {
      this.project = ProjectService.toProject(dataProject);
      this.vcus = this.project?.vcus ?? [];
      this.setImageDefault();
    });
  }
  closeProjectDialog() {
    this.showEditProjectDialog = false;
    //TODO atualizar a pagina apos fechar o dialog do edit
    this.projectService.getProject(this.userID, this.projectID).subscribe(dataProject => {
      this.project = ProjectService.toProject(dataProject);
      this.vcus = this.project?.vcus ?? [];
      this.setImageDefault();
    });
  }
  
  onRowEditInitLog(log: LogProject) {
    this.backupLog = { ...log };
  }

  onRowEditSaveLog(log: LogProject) {
    //TODO chamar backend e atualizar o log editado
  }

  onRowEditCancelLog(index: number) {
    if(this.backupLog)
      this.logs[index] = { ...this.backupLog};
  }

  onRowRemoveLog(event: Event, index: number) {
    this.translocoService.selectTranslateObject('project.detail.tableLog.delete').subscribe(value => 
      this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: `${value.message} ${this.logs[index].name} ?`,
        header: value.header,
        icon: 'pi pi-info-circle',
        acceptButtonStyleClass:"p-button-danger",
        rejectButtonStyleClass:"p-button-text",
        acceptLabel: value.yes,
        rejectLabel: value.no,
        acceptIcon:"none",
        rejectIcon:"none",
        accept: () => {
          this.projectService.DeleteProjectLog(this.userID,this.projectID,this.logs[index].id).subscribe({
            next: _=>{
              this.logs.splice(index, 1);
            },
            error: _=>{}
          });
        },
        reject: () => {
        }
      })
    ).unsubscribe();
  }

  onRowEditInitMap(map: MapProject) {
    this.backupMap = { ...map };
  }

  onRowEditSaveMap(map: MapProject) {
    //TODO chamar backend e atualizar o Map editado
  }

  onRowEditCancelMap(index: number) {
    if(this.backupMap)
      this.maps[index] = { ...this.backupMap};
  }

  onRowRemoveMap(event: Event, index: number) {
    this.translocoService.selectTranslateObject('project.detail.tableMap.delete').subscribe(value => 
      this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: `${value.message} ${this.maps[index].name} ?`,
        header: value.header,
        icon: 'pi pi-info-circle',
        acceptButtonStyleClass:"p-button-danger",
        rejectButtonStyleClass:"p-button-text",
        acceptLabel: value.yes,
        rejectLabel: value.no,
        acceptIcon:"none",
        rejectIcon:"none",
        accept: () => {
          this.projectService.DeleteProjectMap(this.userID,this.projectID,this.maps[index].id).subscribe({
            next: _=>{
              this.maps.splice(index, 1);
            },
            error: _=>{}
          });
        },
        reject: () => {
        }
      })
    ).unsubscribe();
  }

  applyFilterGlobalLog($event: any, stringVal: string) {
    this.dtLog?.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }
  applyFilterGlobalMap($event: any, stringVal: string) {
    this.dtMap?.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }
  applyFilterGlobalShare($event: any, stringVal: string) {
    this.dtShare?.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  
  addShare() {
    if(this.emailShare !== '') {
      this.projectService.CreateProjectSharing(this.projectID,this.userID, this.emailShare)
      .subscribe( response => {
          this.shares.push(new Share(response.projectID!,response.name!, this.emailShare,response.createdDate!,response.permission!));
          this.emailShare = '';
      });
    } else {
      //this.emailShare.markAsDirty()
    }
  }
  
  pageVCU() {
  }
  
  pageProject() {
  }

  setTitle() {
    this.translocoService.selectTranslate('title.project').subscribe(value => 
      this.titleService.setTitle(value)
    );
  }

  setImageDefault() {
    if(this.project && this.project.images.length < 5) {
      for (let i = this.project.images.length; i < 5; i++) {
        this.project.images.push(IMAGE_DEFAULT);
      }
    }
  }

  public changeLanguage(languageCode?: string | null): void {
    if(!languageCode || languageCode === null)
      languageCode = localStorage.getItem('language');
    if(languageCode && languageCode !== null)
      this.translocoService.setActiveLang(languageCode);
  }

  disableProject(){
    this.translocoService.selectTranslateObject('project.detail.delete').subscribe(value => 
      this.confirmationService.confirm({
        message: `${value.message.lineOne} <br> ${value.message.lineTwo}`,
        header: value.header,
        icon: 'pi pi-info-circle',
        acceptButtonStyleClass:"p-button-danger",
        rejectButtonStyleClass:"p-button-text",
        acceptLabel: value.yes,
        rejectLabel: value.no,
        acceptIcon:"none",
        rejectIcon:"none",
        accept: () => {
          this.projectService.disableProject(this.userID, this.projectID).subscribe({
            next: _=>{
              this.messageService.clear();
              this.messageService.add({ key: 'toastProject', severity: 'success', summary: value.sucess.header, detail: value.sucess.message});
            },
            error: _=>{}
          });
        },
        reject: () => {
        }
      })
    ).unsubscribe();
  }

  triggerFileUpload(type: string) {
    if(type === 'map'){
      this.fileInputMap.nativeElement.click();
    }
    if(type === 'log'){
      this.fileInputLog.nativeElement.click();
    }
  }

  handleFileInput(event: Event, type: string) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.uploadFile(file, type);
    }
  }  
  uploadFile(file: File, type: string) {
    this.fileService.HeadFile(this.userID, this.projectID, type, file.name).subscribe(
      (response: HttpResponse<any>) => {
        const uploadId = response.headers.get('upload_id');
        if (uploadId) {
          const partNumber: number = 1;
          this.fileService.PostFile(this.userID, this.projectID, type, file.name, uploadId, partNumber, file).subscribe(
            (response: HttpResponse<any>) => {
              const etagResponse = response.body;
              if (etagResponse && etagResponse.ETag && etagResponse.PartNumber) {
                let partsArray: { ETag: string, PartNumber: number }[] = [];
                partsArray.push({ETag: etagResponse.ETag,PartNumber: etagResponse.PartNumber});
                this.fileService.PutFile(this.userID, this.projectID, type, file.name, uploadId, partsArray).subscribe(
                  (putResponse: HttpResponse<any>) => {
                  },
                  error => {
                    console.error('uploadFile:::PutFile:::error > ', error);
                  }
                );
              }
            },
            error => {
              console.error('uploadFile:::PostFile:::error > ', error);
            }
          );
        }
      },
      error => {
        console.error('uploadFile:::HeadFile:::error > ', error);
      }
    );
  }

  onRowRemoveShare(event: Event, index: number) {
    this.translocoService.selectTranslateObject('project.detail.tableShare.delete').subscribe(value => 
      this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: `${value.message} ${this.shares[index].name} ?`,
        header: value.header,
        icon: 'pi pi-info-circle',
        acceptButtonStyleClass:"p-button-danger",
        rejectButtonStyleClass:"p-button-text",
        acceptLabel: value.yes,
        rejectLabel: value.no,
        acceptIcon:"none",
        rejectIcon:"none",
        accept: () => {
          this.projectService.DeleteProjectShare(this.userID,this.projectID,this.shares[index].id).subscribe({
            next: _=>{
              this.shares.splice(index, 1);
            },
            error: _=>{}
          });
        },
        reject: () => {
        }
      })
    ).unsubscribe();
  }

  onRowDownload(type:string, index: number) {
    let fileName = ''
    if(type === 'map'){
      fileName = this.maps[index].name
    }

    this.fileService.downloadFile(this.userID,this.projectID,type,fileName).subscribe({
      next: (response) =>{
        console.log('onRowDownload:::response > ', response);
        const url = window.URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(url);
      },
      error: _=>{}
    });
  }

  dialogUnlink(index: number) {
    this.vcuUnlink = this.vcus[index];
    this.vcuService.setVcuID(this.vcuUnlink.id);
    this.projectUnlinkInvalid = '';
    this.messages = []
    this.showDialogUnlink = true;
  }

  unlinkVCU() {
    if(this.vcuUnlink && this.reasonUnlinkVcu && this.reasonUnlinkVcu !== '') {
      if(!this.vcuUnlink.vcuAttributes) {
        this.vcuUnlink.vcuAttributes = new VcuAttributes();
      }
      this.unlinkVcuBtnLoading = true;
      this.vcuUnlink.vcuAttributes.reasonUnlink = this.reasonUnlinkVcu;
      this.vcuService.updateVcu(this.vcuUnlink).subscribe(updated => {
        this.vcuService.unlinkVcuInProject().subscribe({
          next: _ => {
            this.messages = []
            this.messageService.clear();
            this.translocoService.selectTranslateObject('vcu.detail.unlinkVcu').subscribe(value => {
              this.messageService.add({ key: 'toastProject', severity: 'success', summary: value.header, detail: value.msgSuccess});
            }).unsubscribe();
            this.projectService.getProject(this.userID, this.projectID).subscribe(dataProject => {
              this.project = ProjectService.toProject(dataProject);
              this.vcus = this.project?.vcus ?? [];
              this.unlinkVcuBtnLoading = false;
              this.showDialogUnlink = false;
              this.setImageDefault();
            });
          },
          error: _ => {
            this.messages = []
            this.unlinkVcuBtnLoading = false;
            this.translocoService.selectTranslate('errorConection').subscribe(value => 
              this.messages = [{ severity: 'error', summary: value}]
            ).unsubscribe();
          }
        });
      });
    } else {
      this.projectUnlinkInvalid = 'ng-dirty ng-invalid';
      this.translocoService.selectTranslate('inputRequired').subscribe(value => 
        this.messages = [{ severity: 'error', summary: value }]
      ).unsubscribe();
    }
  }
}