import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { ConfirmationService, MessageService, Message } from 'primeng/api';
import { Table } from 'primeng/table';
import { Status } from 'src/app/model/enum/status';
import { Project } from 'src/app/model/form/project';
import { Vcu } from 'src/app/model/form/vcu';
import { LogVCU } from 'src/app/model/log-vcu';
import { MapVCU } from 'src/app/model/map-vcu';
import { PROJECT_LIST } from 'src/app/model/mockup/project-mockup';
import { Item } from 'src/app/model/Item';
import { VcuService } from 'src/app/service/vcu.service';
import { ProjectService } from 'src/app/service/project.service';
import { ActivatedRoute } from '@angular/router';
import { VcuAttributes } from 'src/app/model/vcu-attributes';
import { Location } from '@angular/common';
import { AccountService } from 'src/app/service/account.service';
import { Load } from 'src/app/model/mockup/load-mockup';

@Component({
  selector: 'app-vcu',
  templateUrl: './vcu.component.html',
  styleUrl: './vcu.component.css'
})
export class VcuComponent implements OnInit {

  logs: LogVCU[] = [];
  maps: MapVCU[] = [];
  vcu!: Vcu;
  projects: Project[];

  @ViewChild('dtLog') dtLog: Table | undefined;
  @ViewChild('dtMap') dtMap: Table | undefined;
  loadingLog: boolean = true;
  loadingMap: boolean = true;
  imgClass: string = '';
  cardHeaderClass: string = '';
  subtitleClass: string = '';
  statusName: string = '';
  isPending: boolean = false;
  backupLog: LogVCU | null = null;
  backupMap: MapVCU | null = null;
  projectsEdit: Project[];
  showDialogUnlink: boolean = false;
  showEditVcuDialog: boolean = false;
  reasonUnlinkVcu: string = '';
  projectOptions: Item[] = [];
  showDialogLink: boolean = false;
  projectLink: Item | null = null;
  projectLinkInvalid: string = '';
  projectUnlinkInvalid: string = '';
  showProjectDialog: boolean = false;
  unlinkVcuBtnLoading: boolean = false;
  token: string | null = '';
  userID: string = '';
  vcuID: string = '';
  messages: Message[] = [];
  vcuProjects: Item[] = [];
  isLoadingProjects: boolean = false;
  //TODO criar a estrutura de Docs com nome, href, size
  //TODO criar objeto na forma de docs[]

  constructor(private translocoService: TranslocoService,
    private activatedRoute: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private titleService: Title,
    private projectService: ProjectService,
    private vcuService: VcuService,
    private accountService: AccountService,
    private location: Location) {

    this.vcu = new Vcu();
    //TODO BACKEND solciitar os logs por usuario e id da VCU
    // this.logs = VCU_LOG_LIST;
    //TODO BACKEND solciitar os mapas por usuario e id da VCU
    // this.maps = VCU_MAP_LIST;
    //TODO BACKEND solciitar os projetos por usuario e id da VCU
    this.projects = [];
    this.loadingLog = false;
    this.loadingMap = false;
    this.isLoadingProjects = false;
    //TODO BACKEND solciitar a lista de projetos disponiveis para VCU
    this.projectsEdit = PROJECT_LIST;
    //TODO BACKEND solicitar a lista de manuais e documentos da VCU
  }
  async ngOnInit() {
    this.changeLanguage();
    this.setTitle();
    this.messageService.clear();
    this.findVCU();
  }

  findVCU() {
    this.activatedRoute.params.subscribe(params => {
      let ids: string = decodeURIComponent(params['id']);
      console.log('IDS', ids)
      let projectID = ids.split('#')[0];
      this.vcuID = ids.split('#')[1];
      this.vcuService.setProjectID(projectID);
      this.vcuService.setVcuID(this.vcuID);
      this.isLoadingProjects = true;
      this.vcuService.findVcu().subscribe({ 
        next: (response) => {
          this.vcu = VcuService.toVcu(response);
          console.log('### VCU ###', this.vcu)
          this.vcuStatusVerify();
          this.isLoadingProjects = false;
        },
        error: _ => {
          this.isLoadingProjects = false;
        }
      });
    });
  }

  vcuStatusVerify() {
    if(this.vcu !== undefined) {
      if(this.vcu.status == Status.PENDING) {
        this.imgClass = 'img-pending'
        this.cardHeaderClass = 'card-header-pending';
        this.subtitleClass = 'subtitle-pending';
        this.isPending = true;
        this.translocoService.selectTranslateObject('vcu.status.pending').subscribe(value => 
          this.statusName = value
        );
      } else if(this.vcu.status == Status.ACTIVE) {
        this.translocoService.selectTranslateObject('vcu.status.active').subscribe(value => 
          this.statusName = value
        );
      } else if(this.vcu.status == Status.ONLINE) {
        this.translocoService.selectTranslateObject('vcu.status.online').subscribe(value => 
          this.statusName = value
        );
      } else if(this.vcu.status == Status.OFFLINE) {
        this.translocoService.selectTranslateObject('vcu.status.offline').subscribe(value => 
          this.statusName = value
        );
      }
      if(this.vcu.model?.id === '4D5' || this.vcu.model?.id === '2B1') {
        this.vcu.image = Load.IMAGE_FT700_PLUS;
      } else {
        this.vcu.image = Load.IMAGE_FT700;
      }
    }
  }

  formatImage(image: string | undefined | null) {
    if(image)
      return 'url('+image+')';
    return ''
  }

  editVCU() {
    this.showEditVcuDialog = true;
  }

  closeVcuDialog() {
    this.showEditVcuDialog = false;
    //TODO atualizar a pagina apos fechar o dialog do edit
    this.vcuService.findVcu().subscribe(response => {
      this.vcu = VcuService.toVcu(response);
      if(this.vcu.model?.id === '4D5' || this.vcu.model?.id === '2B1') {
        this.vcu.image = Load.IMAGE_FT700_PLUS;
      } else {
        this.vcu.image = Load.IMAGE_FT700;
      }
    });
  }

  dialogUnlink() {
    this.projectUnlinkInvalid = '';
    this.messages = []
    this.showDialogUnlink = true;
  }

  unlinkVCU() {
    if(this.reasonUnlinkVcu && this.reasonUnlinkVcu !== '') {
      if(!this.vcu.vcuAttributes) {
        this.vcu.vcuAttributes = new VcuAttributes();
      }
      this.unlinkVcuBtnLoading = true;
      this.vcu.vcuAttributes.reasonUnlink = this.reasonUnlinkVcu;
      this.vcuService.updateVcu(this.vcu).subscribe(updated => {
        this.vcuService.unlinkVcuInProject().subscribe({
          next: _ => {
            this.messages = []
            this.messageService.clear();
            this.translocoService.selectTranslateObject('vcu.detail.unlinkVcu').subscribe(value => {
              this.messageService.add({ key: 'toast', severity: 'success', summary: value.header, detail: value.msgSuccess});
            }).unsubscribe();
            setTimeout(() => {
              this.unlinkVcuBtnLoading = false;
              this.showDialogUnlink = false;
              this.location.back();
            }, 3000);  //3s
          },
          error: _ => {
            this.messages = []
            this.unlinkVcuBtnLoading = false;
            this.translocoService.selectTranslate('errorConection').subscribe(value => 
              this.messages = [{ severity: 'error', summary: value}]
            ).unsubscribe();
          }
        });
      });
    } else {
      this.projectUnlinkInvalid = 'ng-dirty ng-invalid';
      this.translocoService.selectTranslate('inputRequired').subscribe(value => 
        this.messages = [{ severity: 'error', summary: value }]
      ).unsubscribe();
    }
  }

  refreshListProjects(){
    this.findVCU();
  }

  linkToProject() {
    //TODO BACKEND vincular VCU ao Projeto
    this.userID = localStorage.getItem('idUser') ?? '';
    // todos os projetos
    this.projectService.findAllProjectSummary(this.userID).subscribe(data => {
      let projOptions = []
      let projects: Project[] = this.projectService.projectSummaryToProject(data);
      // projetos da vcu
      this.accountService.FindUserVcus(this.userID).subscribe(response => {
        for(let vcu of response) {
          if(vcu && vcu.id  === this.vcuID && vcu.projects) {
            for(let proj of vcu.projects) {
              if(proj.projectID  && proj.projectName)
                this.vcuProjects.push(new Item(proj.projectID, proj.projectName));
            }
          }
        }
        // remove os projetos da vcu de todos os projetos
        for(let project of projects) {
          if(this.vcuProjects.filter(proj => proj.id === project.id).length === 0) {
            projOptions.push(new Item(project.id, project.name));
          }
        }
        this.projectOptions = [...projOptions]
        if(this.projectOptions.length > 0) 
          this.projectLink = this.projectOptions[0];
        this.showDialogLink = true;
      });
    });
  }

  linkVCU() {
    //TODO BACKEND vincular VCU ao Projeto this.form.value -> garageLink.id
    if(this.vcu && this.projectLink) {
      this.vcuService.setVcuID(this.vcu.id);
      this.vcuService.setProjectID(this.projectLink.id+'');
      this.vcuService.linkVcuInProject().subscribe({
        next: _ => {
          this.messageService.clear();
          this.translocoService.selectTranslateObject('vcu.detail.linkVcu').subscribe(value => {
            this.messageService.add({ key: 'toast', severity: 'success', summary: value.header, detail: value.msgSuccess});
          }).unsubscribe();
          this.showDialogLink = false;
        }, error: _ => {
          this.messages = []
          this.translocoService.selectTranslate('errorConection').subscribe(value => 
            this.messages = [{ severity: 'error', summary: value}]
          ).unsubscribe();
        }
      });
    } else {
      this.projectLinkInvalid = 'ng-dirty ng-invalid';
    }
  }
  
  newProject() {
    //TODO Chamar cadastro do projeto
    this.showProjectDialog = true;
  }

  updateProjectDialog(eventProjectID: string) {
    if(eventProjectID) {
      this.vcuService.setVcuID(this.vcuID)
      this.vcuService.setProjectID(eventProjectID)
      this.vcuService.linkVcuInProject().subscribe();
    }
    this.showProjectDialog = false;
  }
  
  onRowEditInitLog(log: LogVCU) {
    this.backupLog = { ...log };
  }

  onRowEditSaveLog(log: LogVCU) {
    //TODO chamar backend e atualizar o log editado
  }

  onRowEditCancelLog(index: number) {
    if(this.backupLog)
      this.logs[index] = { ...this.backupLog};
  }

  onRowRemoveLog(event: Event, index: number) {
    this.translocoService.selectTranslateObject('vcu.detail.tableLog.delete').subscribe(value => 
      this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: value.message,
        header: value.header,
        icon: 'pi pi-info-circle',
        acceptButtonStyleClass:"p-button-danger",
        rejectButtonStyleClass:"p-button-text",
        acceptLabel: value.yes,
        rejectLabel: value.no,
        acceptIcon:"none",
        rejectIcon:"none",
        accept: () => {
          //TODO chamar backend para remover o Log
          this.logs.splice(index, 1);
        },
        reject: () => {
        }
      })
    ).unsubscribe();
  }

  onRowEditInitMap(map: MapVCU) {
    this.backupMap = { ...map };
  }

  onRowEditSaveMap(map: MapVCU) {
    //TODO chamar backend e atualizar o Map editado
  }

  onRowEditCancelMap(index: number) {
    if(this.backupMap)
      this.maps[index] = { ...this.backupMap};
  }

  onRowRemoveMap(event: Event, index: number) {
    
    this.translocoService.selectTranslateObject('vcu.detail.tableMap.delete').subscribe(value => 
      this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: value.message,
        header: value.header,
        icon: 'pi pi-info-circle',
        acceptButtonStyleClass:"p-button-danger",
        rejectButtonStyleClass:"p-button-text",
        acceptLabel: value.yes,
        rejectLabel: value.no,
        acceptIcon:"none",
        rejectIcon:"none",
        accept: () => {
          //TODO chamar backend para remover o Map
          this.maps.splice(index, 1);
        },
        reject: () => {
        }
      })
    ).unsubscribe();
  }

  applyFilterGlobalLog($event: any, stringVal: string) {
    this.dtLog?.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }
  applyFilterGlobalMap($event: any, stringVal: string) {
    this.dtMap?.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  setTitle() {
    this.translocoService.selectTranslate('title.vcu').subscribe(value => 
      this.titleService.setTitle(value)
    );
  }

  public changeLanguage(languageCode?: string | null): void {
    if(!languageCode || languageCode === null)
      languageCode = localStorage.getItem('language');
    if(languageCode && languageCode !== null)
      this.translocoService.setActiveLang(languageCode);
  }
}