
import { enviroment } from "src/environments/environment";
import { PhoneCountry } from "../country";

export interface AttributeType {
    Name: string;
    Value?: string;
}
export class SignUpRequest {
    ClientId: string = enviroment.COGNITO_CLIENT_ID;
    Username: string;
    Password: string;
    SecretHash: string;
    ClientMetadata: {[key: string]: string};

    constructor(username: string, password: string, secretHash: string, name: string, country: string,
                docNumber: string, docType: string, birthDate: string, phoneNumber: string,
                phoneCountry: string, phoneCountryId: number, cookies: string, newsletters: string, language: string) {
        this.Username = username;
        this.Password = password;
        this.SecretHash = secretHash;
        this.ClientMetadata = {};
        this.ClientMetadata["name"] = name;
        this.ClientMetadata["country"] = country;
        this.ClientMetadata["identification"] = docNumber;
        this.ClientMetadata["identification-type"] = docType;
        this.ClientMetadata["birthdate"] = birthDate;
        this.ClientMetadata["phone_number"] = phoneNumber;
        this.ClientMetadata["dial_code"] = phoneCountry;
        this.ClientMetadata["phone_country"] = phoneCountryId.toString();
        this.ClientMetadata["bl_cookies"] = cookies;
        this.ClientMetadata["bl_notification"] = newsletters;
        this.ClientMetadata["language"] = language;
        this.ClientMetadata["origin"] = origin;
        this.ClientMetadata["base_url"] = enviroment.BASE_URL;
    }
}

export class ConfirmSignUpRequest {
    ClientId: string = enviroment.COGNITO_CLIENT_ID;
    Username: string;
    ConfirmationCode: string;
    SecretHash: string;

    constructor(username: string, confirmationCode: string, secretHash: string) {
        this.Username = username;
        this.ConfirmationCode = confirmationCode;
        this.SecretHash = secretHash;
    }
}

export class RespondToAuthChallengeRequest {
    Session: string;
    ChallengeName: string;
    ClientId: string;
    ChallengeResponses: {
        USERNAME: string,
        EMAIL_OTP_CODE: string,
    };

    constructor(session: string, username: string, code: string) {
        this.ChallengeName = "SELECT_CHALLENGE";
        this.ClientId = enviroment.COGNITO_CLIENT_ID;
        this.ChallengeResponses ={"EMAIL_OTP_CODE": code, "USERNAME": username}
        this.Session = session;
      }

}

export class ResendConfirmationCodeRequest {
    ClientId: string = enviroment.COGNITO_CLIENT_ID;
    Username: string;
    SecretHash: string;
    ClientMetadata: {[key: string]: string};

    constructor(username: string, secretHash: string, name: string, language:string, origin: string) {
        this.Username = username;
        this.SecretHash = secretHash;
        this.ClientMetadata = {};
        this.ClientMetadata["name"] = name;
        this.ClientMetadata["language"] = language;
        this.ClientMetadata["origin"] = origin;
        this.ClientMetadata["base_url"] = enviroment.BASE_URL;
    }
}

export class InitiateAuthRequest {
    ClientId: string ;
    AuthFlow: string ;
    AuthParameters: {
        USERNAME: string,
        PASSWORD: string,
        SECRET_HASH: string
    };
    ClientMetadata: {[key: string]: string};



    constructor(username: string, password: string, secretHash: string, name: string, language:string) {
        this.AuthFlow = "USER_PASSWORD_AUTH";
        this.ClientId = enviroment.COGNITO_CLIENT_ID;
        this.AuthParameters = {
            USERNAME: username,
            PASSWORD: password,
            SECRET_HASH: secretHash
        };
        this.ClientMetadata = {};
        this.ClientMetadata["name"] = name;
        this.ClientMetadata["language"] = language;
        this.ClientMetadata["base_url"] = enviroment.BASE_URL;
        this.ClientMetadata["origin"] = 'login';
    }
}

export class RefreshAuthRequest {
    ClientId: string = enviroment.COGNITO_CLIENT_ID;
    AuthFlow: string = enviroment.REFRESH_TOKEN_AUTH;
    AuthParameters: {
        REFRESH_TOKEN: string,
        SECRET_HASH: string
    };

    constructor(refreshTokenAuth: string, secretHash: string) {
        this.AuthParameters = {
            REFRESH_TOKEN: refreshTokenAuth,
            SECRET_HASH: secretHash
        };
    }
}

export class ForgotPasswordRequest {
    ClientId: string = enviroment.COGNITO_CLIENT_ID;
    Username: string;
    SecretHash: string;
    ClientMetadata: {[key: string]: string};

    constructor(username: string, secretHash: string, name: string, language:string) {
        this.Username = username;
        this.SecretHash = secretHash;
        this.ClientMetadata = {};
        this.ClientMetadata["name"] = name;
        this.ClientMetadata["language"] = language;
        this.ClientMetadata["base_url"] = enviroment.BASE_URL;
        this.ClientMetadata["origin"] = 'forgot';
    }
}

export class ConfirmForgotPasswordRequest {
    ClientId: string = enviroment.COGNITO_CLIENT_ID;
    Username: string;
    Password: string;
    ConfirmationCode: string;
    SecretHash: string;

    constructor(username: string, password: string, confirmationCode: string, secretHash: string) {
        this.Username = username;
        this.Password = password;
        this.ConfirmationCode = confirmationCode;
        this.SecretHash = secretHash;
    }
}

export class GetUserRequest {
    AccessToken: string;

    constructor(accessToken: string) {
        this.AccessToken = accessToken;
    }
}


export class RevokeTokenRequest {
    ClientId: string = enviroment.COGNITO_CLIENT_ID;
    ClientSecret: string = enviroment.COGNITO_SECRET_HASH;
    Token: string;

    constructor(token: string) {
        this.Token = token;
    }
}