import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, booleanAttribute } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Status } from 'src/app/model/enum/status';
import { Vcu } from 'src/app/model/form/vcu';
import { Load } from 'src/app/model/mockup/load-mockup';

@Component({
  selector: 'app-card-vcu',
  templateUrl: './card-vcu.component.html',
  styleUrl: './card-vcu.component.css'
})
export class CardVcuComponent implements OnInit, OnChanges {

  @Input() vcu: Vcu | undefined = undefined;
  @Input({transform: booleanAttribute}) lastCard: boolean = false;
  @Input({transform: booleanAttribute}) lastCardLink: boolean = false;
  @Input({transform: booleanAttribute}) showUnlink: boolean = false;
  @Input() showProjectName: string | null = null;
  @Input() projectID: string | null = null;

  @Output()
  unlink = new EventEmitter<any>();

  active: boolean;
  cardHeaderClass: string = '';
  subtitleClass: string = '';
  bodyClass: string = '';
  polygonClass: string = '';
  cardClass: string = '';
  lastCardClass: string = 'none';
  labelLastCard: string = '';
  visible: boolean = false;
  descriptionStatus: string = '';
  

  constructor(private translocoService: TranslocoService,
    private router: Router) {
    this.active = true;
    // this.vcu = VCU_1;
    // this.vcu = undefined;
    // this.vcu = VCU_2;
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('CHANGES CARD', changes)
    if(changes.vcu && changes.vcu?.currentValue !== changes.vcu?.previousValue) {
      // console.log('CHANGES CARD CURRENT', changes.vcu?.currentValue)
      this.vcu = changes.vcu?.currentValue;
      this.createCard();
    }
  }

  ngOnInit(): void {
    this.changeLanguage();
    this.createCard();
  }
  
  createCard() {
    if(this.vcu !== undefined) {
      if(this.vcu.status == Status.PENDING) {
        this.cardHeaderClass = 'card-header-pending';
        this.subtitleClass = 'subtitle-pending';
        this.bodyClass = 'card-body-pending';
        this.polygonClass = 'polygon-pending';
      } else if(this.showProjectName && this.showProjectName.length > 12) {
        this.cardHeaderClass = 'card-header-small';
      }
      // console.log(this.vcu.surname, this.vcu?.model)
      if(this.vcu.model?.id === '4D5' || this.vcu.model?.id === '2B1') {
        this.vcu.image = Load.IMAGE_FT700_PLUS;
      } else {
        this.vcu.image = Load.IMAGE_FT700;
      }
      this.statusToDescription(this.vcu.status);
    } else if(this.lastCard !== undefined) {
      if(this.lastCard) {
        this.lastCardClass = 'block';
        this.cardClass = 'none';
      }
    }
    // this.lastCardClass = 'block';
    if(this.lastCardLink) {
      this.translocoService.selectTranslate('vcu.card.link').subscribe(value => this.labelLastCard = value).unsubscribe();
    } else {
      this.translocoService.selectTranslate('vcu.card.new').subscribe(value => this.labelLastCard = value).unsubscribe();
    }
  }

  formatImage(image: string | undefined | null) {
    if(image)
      return 'url('+image+')';
    return ''
  }

  unlinkVcu() {
    this.unlink.emit();
  }
  
  goVcuDetail() {
    if(this.vcu){
      this.router.navigate(['/vcu/', this.projectID+'#'+this.vcu.id]);
    }
  }

  public changeLanguage(languageCode?: string | null): void {
    if(!languageCode || languageCode === null)
      languageCode = localStorage.getItem('language');
    if(languageCode && languageCode !== null)
      this.translocoService.setActiveLang(languageCode);
  }

  statusToDescription(status: Status | null){
    this.descriptionStatus = '';
    if(status){
      if(status == Status.ACTIVATION_PENDING){
        this.descriptionStatus = 'Pendente de Ativação'
      }
      if(status == Status.ACTIVE){
        this.descriptionStatus = 'Ativada'
      }
      if(status == Status.DISABLED){
        this.descriptionStatus = 'Desativada'
      }
    }
  }
}
