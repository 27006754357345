export class VcuAttributes {
    tags?: string;
    reasonUnlink?: string;

    constructor(
        tags?: string[],
        reasonUnlink?: string
    ) {
        this.tags = tags?.join(",");
        this.reasonUnlink = reasonUnlink;
    }
}
