<div class="background">
    <div class="header">
        <a routerLink="{{ loginUrl }}">
            <div class="logo-write"></div>
        </a>
        <strong>{{ 'user.createAccount.header' | transloco }}</strong>
    </div>
    <div class="container">
        <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
            <!-- {{ form.value | json }} -->
            <strong>{{ 'user.createAccount.personalData' | transloco }}</strong>
            <p-dropdown id="country"
                [options]="countryOptions" 
                formControlName="country" 
                name="country"
                (onChange)="setIsBrasil()"
                optionLabel="name" 
                [filter]="true"
                filterBy="name"
                [showClear]="true"
                placeholder="{{ 'user.createAccount.country' | transloco }}"
                emptyMessage="{{ 'noResult' | transloco }}"/>
            <!-- <p-inputMask *ngIf="isBrasil"
                id="docNumber" 
                formControlName="docNumber" 
                name="docNumber"
                [mask]="maskDocNumber" 
                unmask="true"
                placeholder="CPF/CNPJ*"
                (onBlur)="getMaskDocNumber()"
                autoClear="false"
                [style]="{'width':'100%'}" /> -->
            <input *ngIf="isBrasil"
                    id="docNumber" 
                    formControlName="docNumber" 
                    name="docNumber"
                    placeholder="CPF/CNPJ*"
                    (blur)="getMaskDocNumber()"
                    maxlength="18"
                    [style]="{'width':'100%'}" 
                    pInputText />
            <input *ngIf="!isBrasil"
                id="docNumber"
                class="docNumber" 
                formControlName="docNumber"
                name="docNumber"
                type="text" 
                placeholder="{{ 'user.createAccount.docNumber' | transloco }}*" 
                pInputText />
            <!-- <p-dropdown *ngIf="isBrasil"
                id="docType" 
                [options]="docOptions" 
                (onChange)="getMaskDocNumber()"
                formControlName="docType" 
                name="docType"
                optionLabel="name" 
                placeholder="{{ 'user.createAccount.docType' | transloco }}"
                emptyMessage="{{ 'noResult' | transloco }}" /> -->
            <input id="name" 
                class="name" 
                formControlName="name" 
                name="name"
                type="text" 
                [placeholder]="placeHolderName"
                pInputText />
            <div class="double-input">
                <p-calendar *ngIf="showBirthDate"
                    id="birthDate"
                    name="birthDate"
                    placeholder="{{ 'user.createAccount.birthDate' | transloco }}*"
                    formControlName="birthDate" 
                    [maxDate]="maxDate" 
                    dateFormat="dd/mm/yy"
                    [showIcon]="true"
                    [showOnFocus]="false" />
                <p-inputMask *ngIf="isBrasil"
                    id="phone" 
                    formControlName="phone" 
                    name="phone"
                    mask="+99 (99) 99999-9999" 
                    unmask="true"
                    placeholder="{{ 'user.createAccount.phone' | transloco }}*"
                    [style]="{'width':'100%'}" />
                <p-inputMask *ngIf="!isBrasil"
                    id="phone" 
                    formControlName="phone" 
                    name="phone"
                    mask="+99999999999?9999" 
                    unmask="true"
                    placeholder="{{ 'user.createAccount.phone' | transloco }}*"
                    [style]="{'width':'100%'}" />
            </div>
            <input id="email" 
                class="email" 
                type="email" 
                formControlName="email"
                name="email"
                (blur)="checkEmail()"
                placeholder="{{ 'user.createAccount.email' | transloco }}*" 
                pInputText />
            <p-password id="password"
                class="ig-pass"
                formControlName="password"
                name="password"
                placeholder="{{ 'user.createAccount.password' | transloco }}*"
                promptLabel="{{ 'user.createAccount.passwordInput' | transloco }}"
                weakLabel="{{ 'user.createAccount.passwordShort' | transloco }}" 
                mediumLabel="{{ 'user.createAccount.passwordMedium' | transloco }}" 
                strongLabel="{{ 'user.createAccount.passwordLong' | transloco }}" 
                [toggleMask]="true" 
                [style]="{'width':'100%'}"
                [inputStyle]="{'width':'100%'}"/>
                <small id="password-help" style="margin-top: 15px;">
                    {{ 'user.createAccount.passwordText' | transloco }}
                </small>
            <p-password id="passwordConfirm"
                class="ig-pass"
                formControlName="passwordConfirm"
                name="passwordConfirm"
                placeholder="{{ 'user.createAccount.passwordConfirm' | transloco }}*"
                [toggleMask]="true" 
                (onBlur)="equalsPassword()"
                [style]="{'width':'100%'}"
                [inputStyle]="{'width':'100%'}"/>
            <p-messages id="messages"
                [(value)]="messagesDocNumber" 
                [enableService]="false" 
                [style]="{'font-size':'15px'}"/>
            <p-messages id="messages"
                [(value)]="messagesEmail" 
                [enableService]="false" 
                [style]="{'font-size':'15px'}"/>
            <p-messages id="messages"
                [(value)]="messagesPassword" 
                [enableService]="false" 
                [style]="{'font-size':'15px'}"/>
            <p-messages id="messages"
                [(value)]="messagesCookies" 
                [enableService]="false" 
                [style]="{'font-size':'15px'}"/>
            <p-messages id="messages"
                [(value)]="messages" 
                [enableService]="false" 
                [style]="{'font-size':'15px'}"/>
            <p-toast key="toast" />

            <div class="inline">
                <p-checkbox id="checkCookies"
                    formControlName="checkCookies"
                    [binary]="true"
                    (onChange)="changeCookies()"
                    inputId="checkCookies" />
                    <label for="checkCookies">
                        {{ 'user.createAccount.checkCookies.v1' | transloco }} <a [routerLink]="[]">{{ 'user.createAccount.checkCookies.v2' | transloco }}</a>
                        {{ 'user.createAccount.checkCookies.v3' | transloco }} <a [routerLink]="[]">{{ 'user.createAccount.checkCookies.v4' | transloco }}</a>
                    </label>
            </div>
            <p-checkbox id="checkNewsletter"
                formControlName="checkNewsletter"
                [binary]="true"
                label="{{ 'user.createAccount.checkNewsletter' | transloco }}"
                inputId="checkNewsletter" />
            <div class="btns">
                <!-- <a id="btnBackLogin" routerLink="" (click)="backLogin()">
                    {{'user.createAccount.btnBack' | transloco}}
                </a> -->
                <p-button id="btnBackLogin"
                    severity="secondary"
                    [raised]="true"
                    [loading]="backBtnLoading"
                    (click)="backLogin()"
                    label="{{'user.createAccount.btnBack' | transloco}}">
                </p-button>
                <p-button
                    type="submit"
                    severity="danger"
                    [raised]="true"
                    [loading]="submitBtnLoading"
                    label="{{'user.createAccount.btnCreateAccount' | transloco}}">
                </p-button>
            </div>
        </form>
    </div>
</div>
