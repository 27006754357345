import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { MenuItem, MessageService } from 'primeng/api';
import { Country, PhoneCountry } from 'src/app/model/country';
import { DocTypeEnum } from 'src/app/model/enum/doc-type';
import { Account } from 'src/app/model/form/account';
import { ACCOUNT_1 } from 'src/app/model/mockup/account-mockup';
import { COUNTRY_LIST } from 'src/app/model/mockup/country-mockup';
import { CustomValidator } from 'src/shared/custom-validator';
import { AccountService } from '../../../service/account.service';
import { enviroment } from 'src/environments/environment';
import { LANGUAGE_LIST } from 'src/app/model/mockup/language-mockup';
import { Language } from 'src/app/model/language';
import { CountryService } from 'src/app/service/country.service';
import { ReasonService } from 'src/app/service/reason.service';
import { Reason } from 'src/app/model/reason';
import { DocType } from 'src/app/model/doc-type';
import { Location } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
import { cpf, cnpj } from 'cpf-cnpj-validator'; 
import { ActivatedRoute } from '@angular/router';
import { Table } from 'primeng/table';
import { TrashService } from 'src/app/service/trash.service';
import { Trash } from 'src/app/model/trash';
import { TrashRestoreRequest } from 'src/app/model/request/trashRestore-request';
import { forkJoin, lastValueFrom } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrl: './account.component.css'
})
export class AccountComponent implements OnInit {
  @ViewChild(Table) dtTrash!: Table;
  
  public form!: FormGroup;
  
  account!: Account;
  accountDB!: Account;
  userId: string | null = null;
  items: MenuItem[] | undefined;
  activeItem: MenuItem | undefined;
  itensTrash: Trash[] = [];
  parametroId: string = 'data';
  loadingTrash: boolean = false;
  selectedDocumento: any = null;
  searchValue: string = '';
  item: any = {};
  countryOptions: Country[] = [];
  docType: DocType | null = null;
  urlDashboard: string = '';
  language: Language;
  lastLanguage: Language;
  languageOptions: Language[] = [];
  inactiveReasonOptions: Reason[] = [];
  isBrasil: boolean = false;
  isBrasilDate: boolean = false;
  showBirthDate: boolean = true;
  placeHolderName: string = '';
  minDate: Date | undefined;
  maxDate: Date | undefined;
  beforeInactive: boolean = true;
  submitBtnLoading: boolean = false;
  backBtnLoading: boolean = false;
  countries: PhoneCountry[] = [];
  currentMask: string = '';
  selectedCountry: any;
  isLoadingAccount: boolean = false;
  currentLocale: string = 'pt-br';
  accessToken: string | null = null;
  mfaEnabled: boolean = false;
  dashboardUrl: string = '';

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private titleService: Title,
              private translocoService: TranslocoService,
              private messageService: MessageService,
              private accountService: AccountService,
              private countryService: CountryService,
              private reasonService: ReasonService,
              private confirmationService: ConfirmationService,
              private trashService : TrashService,
              private location: Location,
              private route: ActivatedRoute,
              private authService: AuthService,
              private cdr: ChangeDetectorRef) {

    this.languageOptions = LANGUAGE_LIST as [];

    // this.translocoService.selectTranslate('user.account.basicData').subscribe(value => this.items = [
    //   { label: value, icon: 'pi pi-user' }
    // ]);
    this.language = this.languageOptions[0];
    this.lastLanguage = this.languageOptions[0];
    this.dashboardUrl = '/dashboard';
  }

  async ngOnInit() {
    this.currentLocale = localStorage.getItem('language') || 'pt-br';
    this.isLoadingAccount = true;
    this.account = new Account();
    this.userId = localStorage.getItem('idUser');
    this.createForm(this.account);
    this.adaptForm();
    this.accessToken = localStorage.getItem('token');
    
    if (enviroment.MOCKUP) {
      this.countryOptions = COUNTRY_LIST;
      this.account = ACCOUNT_1;
      this.createForm(this.account);
      this.adaptForm();
      this.updateMask();
    } else {
      try {
        const [countries, reasons] = await lastValueFrom(
          forkJoin([
            this.countryService.getAllCountry(),
            this.reasonService.getAllReason()
          ])
        );
        this.countryOptions = CountryService.toCountryList(countries);
        this.countries = CountryService.toCountryList(countries);
        this.inactiveReasonOptions = ReasonService.toReasonList(reasons);
        
        if (this.userId) {
          const accountResponse = await lastValueFrom(this.accountService.getAccount(this.userId));          
          this.account = AccountService.toAccount(accountResponse);
          this.accountDB = { ...this.account };
          this.account.phoneCountry = this.countries.find(c => c.id === this.account.phoneCountry.id)!;
          
          this.createForm(this.account);
          this.adaptForm();
          this.updateMask();
  
          this.loadingTrash = true;
          const trashList = await lastValueFrom(this.trashService.getTrashList(this.userId));
          this.itensTrash = Trash.fromArray(trashList);
          
          this.loadingTrash = false;
          this.isLoadingAccount = false;
        }
        if(this.accessToken){
          let response = await this.authService.getUser(this.accessToken);          
          if(response.UserAttributes && response.UserAttributes.length > 0){
            let mfaEnabled = response.UserAttributes.find(attr => attr.Name === 'custom:mfa_enabled');
            if(mfaEnabled){
              this.mfaEnabled = mfaEnabled.Value === 'true'? true : false;
            }else{
              this.mfaEnabled = false;
            }
          }
          // if (response.UserMFASettingList) {
          //   const mfaEnabled = response.UserMFASettingList.find(attr => attr === 'EMAIL_OTP');
          //   if (mfaEnabled) {
          //     this.mfaEnabled = true;
          //   } else {
          //     this.mfaEnabled = false;
          //   }
          // } else {
          //   this.mfaEnabled = false;
          // }
        }
      } catch (error) {
        this.isLoadingAccount = false;
        if (!this.countryOptions) this.countryOptions = [];
        if (!this.inactiveReasonOptions) this.inactiveReasonOptions = [];
        this.messageService.clear();
        this.messageService.add({
          key: 'toastAccount',
          severity: 'error',
          summary: this.translocoService.translate('user.trash.errorLoadTrash'),
        });
      }
    }
  
    this.changeLanguage();
    this.urlDashboard = '/dashboard';
  
    this.items = [
      { label: this.translocoService.translate('user.account.basicData'), icon: 'pi pi-user', command: () => this.setActiveTab('data') },
      { label: this.translocoService.translate('user.account.trash'), icon: 'pi pi-trash', command: () => this.setActiveTab('trash') },
    ];
    this.activeItem = this.items[0];
  
    this.route.params.subscribe(params => {
      this.parametroId = params['id'];
      this.setActiveTab(this.parametroId);
    });
    setTimeout(() => {
      this.messageService.clear();
    }, 0);
  }
  
  updateMask() {
    const selectedCountry = this.form.get('phoneCountry')?.value;
    const country = this.countries.find(c => c.code === selectedCountry?.code);
  
    if (country) {
      this.currentMask = country.mask;
      const phoneControl = this.form.get('phone');
  
      if (phoneControl) {
        const currentPhoneValue = phoneControl.value || '';
        const isValidForNewMask = new RegExp(this.currentMaskToRegex(country.mask)).test(currentPhoneValue);
        phoneControl.updateValueAndValidity();
      }
    }
  }
  
  currentMaskToRegex(mask: string): string {
    return mask
      .replace(/9/g, '\\d')
      .replace(/\(/g, '\\(')
      .replace(/\)/g, '\\)')
      .replace(/\s/g, '\\s');
  }
  
  activeTab: string = 'data'; 

  setActiveTab(tab: string): void {
    this.activeTab = tab;
  }

  restoreItem(index: number): void {
    this.itensTrash.splice(index, 1);
  }

  restore(item: Trash): void {
    const request = new TrashRestoreRequest(item.id, item.type);
    if(this.userId && request) {
      this.translocoService.selectTranslateObject('user.trash').subscribe(value => 
        this.confirmationService.confirm({
          message: value.confirmationRestoreMessage,
          header: value.restoreItem,
          icon: 'pi pi-info-circle',
          acceptButtonStyleClass:"p-button-danger",
          rejectButtonStyleClass:"p-button-text",
          acceptLabel: value.yes,
          rejectLabel: value.no,
          acceptIcon:"none",
          rejectIcon:"none",
          accept: () => {
            if(!this.userId) return;
            this.trashService.trashRestore(this.userId, [request]).subscribe({
              next:() => {
                this.translocoService.selectTranslate('user.trash.successRestore').subscribe(value => {
                  this.messageService.clear();
                  this.messageService.add({ key: 'toastAccount', severity: 'success', summary: value});
                });
                this.itensTrash = this.itensTrash.filter(i => i.id !== item.id);
              },
              error:() => {
                this.translocoService.selectTranslate('user.trash.errorRestore').subscribe(value => {
                  this.messageService.clear();
                  this.messageService.add({ key: 'toastAccount', severity: 'error', summary: value});
                });
              },
              complete:() => {}
            })
          },
          reject: () => {
            
          }
        })
      ).unsubscribe(); 
    }
  }

  onGlobalFilter(table: Table, event: Event) {
    table.filterGlobal(
        (event.target as HTMLInputElement).value,
        'contains'
    );
  }

  onEnterPress(event: Event) {
    const keyboardEvent = event as KeyboardEvent;
    keyboardEvent.preventDefault();
  }
  
  createForm(account: Account) {
    this.form = this.formBuilder.group({
      id: [account.id],
      country: [account.country, CustomValidator.getRequired()],
      name: [account.name, CustomValidator.getRequired()],
      docType: [account.docType],
      docNumber: [account.docNumber],
      birthDate: [account.birthDate, CustomValidator.getRequired()],
      phone: [account.phone, CustomValidator.getRequired()],
      phoneCountry: [account.phoneCountry, CustomValidator.getRequired()],
      email: [account.email, CustomValidator.getEmail()],
      language: [account.language, CustomValidator.getRequired()],
      checkNewsletter: [account.checkNewsletter],
      inactive: [account.inactive],
      inactiveReason: [account.inactiveReason]
    })
  }

  onSubmit() {
    this.messageService.clear();
    if(this.f.country.invalid) this.f.country.markAsDirty()
    if(this.f.name.invalid) this.f.name.markAsDirty()
    //if(this.isBrasil && this.f.docNumber.invalid) this.f.docNumber.markAsDirty()
    if(DocTypeEnum.CPF === this.f.docType.value.id && this.f.birthDate.invalid) this.f.birthDate.markAsDirty()
    //if(this.f.phone.invalid) this.f.phone.markAsDirty()
    if(this.f.email.invalid) this.f.email.markAsDirty()
    if(this.form.valid) {
      if(!this.f.inactive.value)
        this.f.inactiveReason.setValue(null);
      let payload = this.accountService.toAccountRequest(this.form.value, this.verifyInactive() );

      if(this.accessToken){
        // this.authService.setUserMFA(this.accessToken, this.mfaEnabled).then(() => {
        //   console.log("MFA Ativado");
        // }
        this.authService.updateUserAttibutes(this.accessToken, this.mfaEnabled).then(() => {
          console.log("MFA Ativado");
        }
        ).catch(() => {
          console.log("Erro ao ativar MFA");
        });
      }

      this.accountService.updateAccountFix(payload).subscribe({
        next: (response) => {
          localStorage.setItem('language', response.language);
          this.messageService.clear();
          this.translocoService.selectTranslate('user.account.success').subscribe(value => {
            this.messageService.add({ key: 'toastAccount', severity: 'success', summary: value});
          });
          this.submitBtnLoading = false;
          this.router.navigate([this.dashboardUrl]);
        },
        error: () => {
          this.messageService.clear();
          this.translocoService.selectTranslate('errorConection').subscribe(value => {
            this.messageService.add({ key: 'toastAccount', severity: 'error', summary: value});
          });
      //     this.submitBtnLoading = false;
         }
       });
    } else {
      this.translocoService.selectTranslate('inputRequired').subscribe(value => {
        this.messageService.clear();
        this.messageService.add({ key: 'toastAccount', severity: 'error', summary: value});
      });
    }
  }

  adaptForm() {
    this.setTitle();
    if(this.account.country.id !== 0) {
      this.account.country = this.countryOptions.filter(c => c.id === this.account.country.id)[0];
      this.f.country.setValue(this.account.country);
    }
    this.setIsBrasil();
    this.setDateInterval();
    this.fixLanguage();
    this.beforeInactive = this.f.inactive.value;
    this.messageService.clear();
    this.f.docNumber.disable();
    this.f.email.disable();
    
    if(this.f.inactiveReason.value === null) {
      this.f.inactiveReason.setValue((this.inactiveReasonOptions.length > 0 ? this.inactiveReasonOptions[0] : null))
    }
    if(this.beforeInactive) {
      this.f.inactive.disable();
      this.f.inactiveReason.disable();
    }
    this.cdr.detectChanges();
  }
  
  public setIsBrasil() {
    if('Brasil' === this.f.country.value.name || 'Brazil' === this.f.country.value.name) {
      this.isBrasil = true;
      this.isBrasilDate = true;
      this.getMaskDocNumber();
      this.showBirthDateAndName();
      this.f.docNumber.setValidators(CustomValidator.getRequired());
    } else {
      this.isBrasil = false;
      this.isBrasilDate = false;
      this.docType = null;
      this.showBirthDate = true;
      this.f.docNumber.setValue(this.account.docNumber ?? null);
      // this.f.docNumber.enable();
      this.f.docNumber.clearValidators();
      this.f.docNumber.setErrors(null);
    }
    this.f.birthDate.updateValueAndValidity();
    this.cdr.detectChanges();
  }

  setDateInterval() {
    let today = new Date();
    this.maxDate = new Date();
    this.maxDate.setDate(today.getDate() - 1);
  }

  fixLanguage() {
    this.messageService.clear();
    this.language = this.f.language.value;
    if(this.lastLanguage !== this.language) {
        this.languageOptions
          .filter(opt => opt.name === this.language.name)
          .map(cty => cty.disabled = true)
        this.languageOptions
          .filter(opt => opt.name !== this.language.name)
          .map(cty => cty.disabled = false)

        this.lastLanguage = this.language;
        this.changeLanguage(this.language.name);
    }
  }

  backPage() {
    if(!this.deepEquals(this.form.value, this.accountDB)) {
      this.backBtnLoading = true;
      this.translocoService.selectTranslate('noSaved').subscribe(value => {
        this.messageService.clear();
        this.messageService.add({ key: 'toastAccount', severity: 'error', summary: value});
      }).unsubscribe();

      setTimeout(() => {
        this.location.back();
      }, 3000);  //3s
    } else {
      this.location.back();
    }
  }

  deepEquals(form: Account, db: Account) : boolean {
    if(form && db) {
      if(form.country.id !== db.country.id) return false;
      if(form.name !== db.name) return false;
      if(form.birthDate?.getTime() !== db.birthDate?.getTime()) return false;
      if(this.removeMask(form.phone) !== this.removeMask(db.phone)) return false;
      if(form.language !== db.language) return false;
      if(form.checkNewsletter !== db.checkNewsletter) return false;
    }
    return true;
  }

  setTitle() {
    this.translocoService.selectTranslate('title.account').subscribe(value => 
      this.titleService.setTitle(value)
    );
  }
  
  setPlaceHolderNameCpf() {
    this.translocoService.selectTranslate('user.createAccount.fullName').subscribe(value => 
      this.placeHolderName = value+'*'
    );
  }
  setPlaceHolderNameCnpj() {
    this.translocoService.selectTranslate('user.createAccount.companyName').subscribe(value => 
      this.placeHolderName = value+'*'
    );
  }
  
  showBirthDateAndName() {
    if(DocTypeEnum.CPF === this.f.docType.value.id) {
      this.setPlaceHolderNameCpf();
      this.showBirthDate = true;
    } else if(DocTypeEnum.CNPJ === this.f.docType.value.id) {
      this.setPlaceHolderNameCnpj();
      this.showBirthDate = false;
      this.f.birthDate.setValidators(null);
    }
  }

  verifyInactive() : Reason | null {
    // se esta sendo inativado, retorna o motivo definido no form, senao mantem o motivo do getUser
    if(!this.beforeInactive && this.f.inactive.value)
      return this.f.inactiveReason.value;
    return this.account.inactiveReason;
  }

  getMaskDocNumber() {
    this.messageService.clear();
    let docNumber = this.removeMask(this.f.docNumber.value);
    if(docNumber.length === 11) {
      docNumber = this.addMaskCPF(docNumber);
      this.f.docNumber.setValue(docNumber);
      if(this.validCPF(docNumber)) {
        this.docType = DocType.CPF;
        this.setPlaceHolderNameCpf();
        this.showBirthDate = true;
      } else {
        this.invalidateCPF();
      }
    } else if(docNumber.length === 14) {
      docNumber = this.addMaskCNPJ(docNumber);
      this.f.docNumber.setValue(docNumber);
      let valido = this.validCNPJ(docNumber);
      if(this.validCNPJ(docNumber)) {
        this.docType = DocType.CNPJ;
        this.setPlaceHolderNameCnpj();
        this.showBirthDate = false;
      } else {
        this.invalidateCNPJ();
      }
    } else {
      this.f.docNumber.setValue('');
    }
  }

  private invalidateCPF() {
    this.f.docNumber.markAsDirty();
    this.f.docNumber.setErrors({'nomatch': true});
    this.messageService.clear();
    this.translocoService.selectTranslate('user.createAccount.cpfValid').subscribe(value => 
      this.messageService.add({ key: 'toastAccount', severity: 'error', summary: value })
    );
  }

  private invalidateCNPJ() {
    this.f.docNumber.markAsDirty();
    this.f.docNumber.setErrors({'nomatch': true});
    this.messageService.clear();
    this.translocoService.selectTranslate('user.createAccount.cnpjValid').subscribe(value => 
      this.messageService.add({ key: 'toastAccount', severity: 'error', summary: value })
    );
  }

  private validCPF(value: string) : boolean {
    return cpf.isValid(this.removeMask(value));
  }

  private validCNPJ(value: string) : boolean {
    return cnpj.isValid(this.removeMask(value));
  }

  private addMaskCPF(str: string) : string { // '999.999.999-99'
    if(str === null) return "";
    str = str.replace(/\D/g,"");
    str = str.replace(/(\d{3})(\d)/,"$1.$2");
    str = str.replace(/(\d{3})(\d)/,"$1.$2");
    str = str.replace(/(\d{3})(\d{1,2})$/,"$1-$2");
    return str;
  }

  private addMaskCNPJ(str: string) : string { // '99.999.999/9999-99'
    if(str === null) return "";
    str = str.replace(/\D/g,"");
    str = str.replace(/^(\d{2})(\d)/,"$1.$2")
    str = str.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3")
    str = str.replace(/\.(\d{3})(\d)/,".$1/$2")
    str = str.replace(/(\d{4})(\d)/,"$1-$2")
    return str;
  }

  private removeMask(str: string) : string {
    if(str === null) return "";
    str = str.replaceAll(".","");
    str = str.replaceAll("-","");
    str = str.replaceAll("/","");
    return str;
  }

  changeInactive() : void {
    if(this.f.inactive.value) {
      if(this.f.inactiveReason.value === null) 
        this.f.inactiveReason.setValue((this.inactiveReasonOptions.length > 0 ? this.inactiveReasonOptions[0] : null))
    } else {
      this.f.inactiveReason.setValue(null);
    }
  }

  public changeLanguage(languageCode?: string | null): void {
    if(!languageCode || languageCode === null)
      languageCode = localStorage.getItem('language');
    if(languageCode && languageCode !== null){
      if(languageCode === 'pt-br') {
        this.isBrasilDate = true;
        localStorage.setItem('language', languageCode);
      } else {
        this.isBrasilDate = false;
        localStorage.setItem('language', languageCode);
      }
      this.translocoService.setActiveLang(languageCode);
    }
  }

  confirmInactive() {
        this.translocoService.selectTranslateObject('user.account').subscribe(value => 
          this.confirmationService.confirm({
            message: value.inactiveQuest,
            header: value.inactive,
            icon: 'pi pi-info-circle',
            acceptButtonStyleClass:"p-button-danger",
            rejectButtonStyleClass:"p-button-text",
            acceptLabel: value.yes,
            rejectLabel: value.no,
            acceptIcon:"none",
            rejectIcon:"none",
            accept: () => {
              this.form.get('inactive')?.setValue(true);
            },
            reject: () => {
              this.form.get('inactive')?.setValue(false);
            }
          })
        ).unsubscribe();
  }

  showTrash(itemType: string):boolean{
    if(itemType == 'Vcu')
      return true;
    return false;
  }

  trashPermanently(item: Trash) {
    this.translocoService.selectTranslateObject('user.trash.permanently').subscribe(value => 
      this.confirmationService.confirm({
        header: value.header,
        message: value.ask,
        icon: 'pi pi-info-circle',
        acceptButtonStyleClass:"p-button-danger",
        rejectButtonStyleClass:"p-button-text",
        acceptLabel: value.yes,
        rejectLabel: value.no,
        acceptIcon:"none",
        rejectIcon:"none",
        accept: () => {
          this.trashService.trashPermanently(this.userId ? this.userId : '', item.id).subscribe({
            next: _=>{
              this.itensTrash = this.itensTrash.filter(i => i.id !== item.id);
            },
            error: _=>{}
          });
        },
        reject: () => {}
      })
    ).unsubscribe();
  }  

  get f() { return this.form.controls; }
}
