import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Message } from 'primeng/api';
import { CognitoException } from 'src/app/model/enum/cognito-exception';
import { AuthService } from 'src/app/service/auth.service';
import { CustomValidator } from 'src/shared/custom-validator';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.css'
})
export class ForgotPasswordComponent implements OnInit {

  public form!: FormGroup;
  public formCode!: FormGroup;
  public formPass!: FormGroup;

  email: string;
  code: string;
  password: string;
  passwordConfirm: string;
  isDivEmail: string;
  isDivCode: string;
  isDivPass: string;
  loginUrl: string;
  messagesEmail: Message[]
  messagesCode: Message[]
  messagesCodeFix: Message[]
  messagesPass: Message[]
  submitBtnLoading: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private titleService: Title,
    private translocoService: TranslocoService) {

    this.email="";
    this.code="";
    this.password="";
    this.passwordConfirm="";
    this.isDivEmail="";
    this.isDivCode=""; //none
    this.isDivPass=""; //none
    this.loginUrl = '/login';
    this.messagesEmail = [];
    this.messagesCode = [];
    this.messagesCodeFix = [];
    this.messagesPass = [];
  }

  ngOnInit(): void {
    this.changeLanguage();
    this.setTitle();
    this.createForm(this.email);
    this.createFormCode(this.code);
    this.createFormPass(this.password, this.passwordConfirm);
    this.createFixMessage();
    // this.showEmail();
  }

  async onSubmit() {
    if(this.form.valid) {
      this.submitBtnLoading = true;
      //TODO BACKEND pegar nome do usuario a partir do email e passar para reenviar o email
      await this.authService.forgotPassword(this.f.email.value, null, localStorage.getItem('language')).then(() => {
        this.email = this.maskEmail(this.f.email.value);
        this.submitBtnLoading = false;
        this.showCode();
      }).catch(error => {
        //TODO ??? - erro email não registrado ou erro de comunicação
        if(CognitoException.USER_NOT_FOUND === error.code) {
          this.translocoService.selectTranslate('user.forgotPassword.emailValid').subscribe(value => this.messagesEmail = [
            { severity: 'error', summary: value}
          ]);
          this.form.reset();
        } else {
          this.translocoService.selectTranslate('errorConection').subscribe(value => this.messagesEmail = [
            { severity: 'error', summary: value}
          ]);
        }
        this.submitBtnLoading = false;
        this.showEmail();
      });
    } else {
      this.f.email.markAsDirty()
      this.translocoService.selectTranslate('inputRequired').subscribe(value => this.messagesEmail = [
        { severity: 'error', summary: value}
      ]);
    }
  }

  onSubmitCode() {
    if(this.formCode.valid && String(this.fc.code.value).length === 6) {
      this.showPass();
    } else {
      this.fc.code.markAsDirty()
      this.translocoService.selectTranslate('inputRequired').subscribe(value => this.messagesCode = [
        { severity: 'error', summary: value}
      ]);
    }
  }

  async onSubmitPass() {
    if(this.fp.password.invalid) this.fp.password.markAsDirty()
    if(this.fp.passwordConfirm.invalid) this.fp.passwordConfirm.markAsDirty()
    
    if(this.equalsPassword() && this.formPass.valid) {
      this.submitBtnLoading = true;
      await this.authService.confirmForgotPassword(this.f.email.value, this.fp.password.value, this.fc.code.value).then(() => {
        this.translocoService.selectTranslate('user.forgotPassword.msgSuccess').subscribe(value => this.messagesPass = [
          { severity: 'success', summary: value}
        ]);
        setTimeout(() => {
        this.submitBtnLoading = false;
          this.router.navigate([this.loginUrl]);
        }, 3000);  //3s
      }).catch(error => {
        if(CognitoException.INVALID_PASSWORD === error.code) {
          this.translocoService.selectTranslate('user.forgotPassword.invalidPassword').subscribe(value => this.messagesPass = [
            { severity: 'error', summary: value}
          ]);
        } else if(CognitoException.CODE_MISMATCH === error.code) {
          this.translocoService.selectTranslate('user.forgotPassword.codeMismatch').subscribe(value => this.messagesCodeFix = [
            { severity: 'error', summary: value}
          ]);
          this.resetFormCode()
          this.showCode();
        } else if(CognitoException.EXPIRED_CODE === error.code) {
          this.translocoService.selectTranslate('user.forgotPassword.expiredCode').subscribe(value => this.messagesPass = [
            { severity: 'error', summary: value}
          ]);
        } else {
          this.translocoService.selectTranslate('errorConection').subscribe(value => this.messagesPass = [
            { severity: 'error', summary: value}
          ]);
        }
        this.submitBtnLoading = false;
      });
    } else {
      if(this.messagesPass.length === 0) {
        this.translocoService.selectTranslate('inputRequired').subscribe(value => this.messagesPass = [
          { severity: 'error', summary: value}
        ]);
      }
    }
  }

  equalsPassword() : boolean {
    if(this.fp.password.value !== this.fp.passwordConfirm.value) {
      this.translocoService.selectTranslate('user.createAccount.passwordEquals').subscribe(value => 
        this.messagesPass = [{ severity: 'error', summary: value }]
      );
      return false;
    } else if(this.fp.password.invalid || this.fp.passwordConfirm.invalid) {
      this.translocoService.selectTranslate('user.forgotPassword.invalidPassword').subscribe(value => 
        this.messagesPass = [{ severity: 'error', summary: value }]
      );
      return false;
    }
    return true;
  }

  createForm(email: string) {
    this.form = this.formBuilder.group({
      email: [email, CustomValidator.getEmail()]
    })
  }
  createFormCode(code: string) {
    this.formCode = this.formBuilder.group({
      code: [code, CustomValidator.getRequired()]
    })
  }
  createFormPass(password: string, passwordConfirm: string) {
    this.formPass = this.formBuilder.group({
      password: [password, CustomValidator.getPassword()],
      passwordConfirm: [passwordConfirm, CustomValidator.getPassword()]
    })
  }
  resetFormCode() {
    this.formCode.reset();
  }
  resetFormPass() {
    this.formPass.reset();
  }

  createFixMessage() {
    this.translocoService.selectTranslate('user.forgotPassword.msg').subscribe(value => this.messagesCodeFix = [
      { severity: 'success', summary: value}
    ]);
  }

  backLogin() {
    this.router.navigate([this.loginUrl]);
  }

  async sendEmailAgain() {
    //TODO BACKEND pegar nome do usuario a partir do email e passar para reenviar o email
    await this.authService.resendConfirmationCode(this.f.email.value, null, localStorage.getItem('language'), 'forgot').then(() => {
    }).catch(() => {
      this.translocoService.selectTranslate('errorConection').subscribe(value => this.messagesCode = [
        { severity: 'error', summary: value}
      ]);
    });
  }
  
  showEmail() {
    this.isDivEmail = "";
    this.isDivCode = "none";
    this.isDivPass="none";
    this.messagesEmail = [];
  }

  showCode() {
    this.isDivEmail = "none";
    this.isDivCode = "";
    this.isDivPass="none";
    this.messagesCode = [];
    this.createFixMessage();
  }

  showPass() {
    this.isDivEmail = "none";
    this.isDivCode = "none";
    this.isDivPass="";
    this.messagesPass = [];
  }

  maskEmail(email: string) {
    return email.substring(0,1)+
          '*****@***'+
          email.substring(email.length - 2);
  }

  setTitle() {
    this.translocoService.selectTranslate('title.forgot-password').subscribe(value => 
      this.titleService.setTitle(value)
    );
  }

  public changeLanguage(languageCode?: string | null): void {
    if(!languageCode || languageCode === null)
      languageCode = localStorage.getItem('language');
    if(languageCode && languageCode !== null)
      this.translocoService.setActiveLang(languageCode);
  }

  get f() { return this.form.controls; }

  get fc() { return this.formCode.controls; }

  get fp() { return this.formPass.controls; }
}
