import { Injectable } from '@angular/core';
import { User } from '../model/user';
import {
  AuthenticationResultType,
  AuthFlowType,
  CognitoIdentityProvider,
  CognitoIdentityProviderClient,
  ConfirmForgotPasswordCommandOutput,
  ConfirmSignUpCommandOutput,
  ForgotPasswordCommandOutput,
  GetUserCommandOutput,
  InitiateAuthCommandInput,
  RespondToAuthChallengeCommand,
  RespondToAuthChallengeCommandInput,
  SignUpCommandOutput,
  UpdateUserAttributesCommand,
} from '@aws-sdk/client-cognito-identity-provider';
import { CognitoJwtVerifier } from "aws-jwt-verify";
import * as CryptoJS from 'crypto-js';
import { ConfirmForgotPasswordRequest, ConfirmSignUpRequest, ForgotPasswordRequest, GetUserRequest, InitiateAuthRequest, RefreshAuthRequest, ResendConfirmationCodeRequest, RespondToAuthChallengeRequest, RevokeTokenRequest, SignUpRequest } from '../model/request/cognito-request';
import { InitiateAuthResponse } from '../model/response/cognito-response';
import { enviroment } from 'src/environments/environment';
import { Account } from '../model/form/account';
// @ts-ignore
import { SRPClient, calculateSignature, getNowString } from 'amazon-user-pool-srp-client';


@Injectable({
  providedIn: 'root'
})
export class CognitoService {

  constructor() {}
  
  async signUp(account: Account) : Promise<SignUpCommandOutput> {
    
    const cognito = new CognitoIdentityProvider({
      region: enviroment.COGNITO_REGION,
    });
    // let phoneComplete = account.phoneCountry.dialCode.replace('+', '') + '-' + account.phone;
    let param = new SignUpRequest(account.email, 
                                  account.password, 
                                  this.getSecretHash(account.email, enviroment.COGNITO_CLIENT_ID, enviroment.COGNITO_SECRET_HASH),
                                  account.name,
                                  account.country.id.toString(),
                                  account.docNumber,
                                  account.docType.name.toLowerCase(),
                                  (account.birthDate ? account.birthDate.toISOString().substring(0, 10) : ''),
                                  account.phone,
                                  account.phoneCountry.dialCode.replace('+', ''),
                                  account.phoneCountry.id,
                                  new Boolean(account.checkCookies).toString(),
                                  new Boolean(account.checkNewsletter).toString(),
                                  account.language);

    return cognito.signUp(param);
  }

  async confirmSignup(user: User) : Promise<ConfirmSignUpCommandOutput> {
    const cognito = new CognitoIdentityProvider({
      region: enviroment.COGNITO_REGION,
    });

    let params = new ConfirmSignUpRequest(user.email,
                                          user.code,
                                          this.getSecretHash(user.email, enviroment.COGNITO_CLIENT_ID, enviroment.COGNITO_SECRET_HASH)
    );

    return cognito.confirmSignUp(params);
  }
  
  async resendConfirmationCode(user: User, origin: string) : Promise<boolean> {
    
    const cognito = new CognitoIdentityProvider({
      region: enviroment.COGNITO_REGION,
    });

    let param = new ResendConfirmationCodeRequest(user.email, 
                                  this.getSecretHash(user.email, enviroment.COGNITO_CLIENT_ID, enviroment.COGNITO_SECRET_HASH),
                                  user.name,
                                  user.language,
                                  origin);

    return new Promise((resolve) => {
      cognito.resendConfirmationCode(param, function(err: any, data: any) {
        if(err) {
          resolve(false);
        } else {
          resolve(true);
        }
      });
    });
  }

  async initiateAuth(user: User): Promise<boolean> {
    const userPoolId = enviroment.COGNITO_USER_POOL_ID.split('_')[1];
    const srp = new SRPClient(userPoolId);
    const srpA = srp.calculateA();
  
    const cognito = new CognitoIdentityProvider({
      region: enviroment.COGNITO_REGION,
    });
    let lan = localStorage.getItem('language') ?? 'pt-br';
    const request: InitiateAuthCommandInput = {
      AuthFlow: "CUSTOM_AUTH",
      AuthParameters: {
        USERNAME: user.email,
        PASSWORD: user.password,
        SRP_A: srpA,
        SECRET_HASH: this.getSecretHash(user.email, enviroment.COGNITO_CLIENT_ID, enviroment.COGNITO_SECRET_HASH),
        LANGUAGE: lan,
      },
      ClientMetadata: {
        name: user.name,
        language: lan,
        base_url: enviroment.BASE_URL,
        origin: 'login',
      },
      ClientId: enviroment.COGNITO_CLIENT_ID,
    };
  
    try {
      const response = await cognito.initiateAuth(request);
      return await this.handleAuthResponse(response, user, srp, userPoolId);
    } catch (error) {
      console.error("Error in initiateAuth:", error);
      if (error instanceof Error && error.message.includes('UserNotConfirmedException')) {
        throw new Error("UserNotConfirmedException");
      } else {
        throw new Error("Authentication failed. Please try again.");
      }
      
    }
  }
  
  private async handleAuthResponse(response: InitiateAuthResponse, user: User, srp: SRPClient, userPoolId: string): Promise<boolean> {
    if (response.AuthenticationResult) {
      this.storeTokens(response.AuthenticationResult);
      return true;
    }
  
    if (response.ChallengeName && response.Session && response.ChallengeParameters) {
      return await this.handleChallenge(response, user, srp, userPoolId);
    }
  
    console.warn("Unknown authentication response:", response);
    return false;
  }
  
  private storeTokens(authResult: AuthenticationResultType): void {
    if (authResult.RefreshToken) {
      localStorage.setItem('refreshToken', authResult.RefreshToken);
    }
    if (authResult.IdToken) {
      localStorage.setItem('idToken', authResult.IdToken);
    }
    if (authResult.AccessToken) {
      localStorage.setItem('token', authResult.AccessToken);
    }
  }
  
  private async handleChallenge(response: InitiateAuthResponse, user: User, srp: SRPClient, userPoolId: string): Promise<any> {
    if (!response.ChallengeParameters) {
      throw new Error("Challenge parameters are missing in the response.");
    }
    const { USER_ID_FOR_SRP, SRP_B, SALT, SECRET_BLOCK } = response.ChallengeParameters as { USER_ID_FOR_SRP: string, SRP_B: string, SALT: string, SECRET_BLOCK: string };
  
    const hkdf = srp.getPasswordAuthenticationKey(USER_ID_FOR_SRP, user.password, SRP_B, SALT);
    
    const dateNow = getNowString();
    const signatureString = calculateSignature(hkdf, userPoolId, USER_ID_FOR_SRP, SECRET_BLOCK, dateNow);
  
    let lang = localStorage.getItem('language') ?? 'pt-br';

    const params: RespondToAuthChallengeCommandInput = {
      ChallengeName: 'PASSWORD_VERIFIER',
      ClientId: enviroment.COGNITO_CLIENT_ID,
      Session: response.Session,
      ChallengeResponses: {
        USERNAME: user.email,
        PASSWORD_CLAIM_SIGNATURE: signatureString,
        PASSWORD_CLAIM_SECRET_BLOCK: SECRET_BLOCK,
        TIMESTAMP: dateNow,
        SECRET_HASH: this.getSecretHash(user.email, enviroment.COGNITO_CLIENT_ID, enviroment.COGNITO_SECRET_HASH),
      },
      ClientMetadata: {
        language: lang,
        base_url: enviroment.BASE_URL,
        origin: 'login',
      },
    };
  
    try {
      const cognitoClient = new CognitoIdentityProviderClient({ region: enviroment.COGNITO_REGION });
      const respondToAuthCommand = new RespondToAuthChallengeCommand(params);
      const respondToAuthResponse = await cognitoClient.send(respondToAuthCommand);

      if (respondToAuthResponse.AuthenticationResult) {
        this.storeTokens(respondToAuthResponse.AuthenticationResult);
        
        return true;
      } else {
        return respondToAuthResponse
      }
    } catch (error) {
      console.error("Error handling challenge:", error);
      throw new Error("Challenge response failed. Please try again.");
      return false
    }
  }
  
  async refreshAuth(refreshToken: string, user: User): Promise<string> {

    const cognito = new CognitoIdentityProvider({
      region: enviroment.COGNITO_REGION,
    });

    const params = {
        AuthFlow: "REFRESH_TOKEN_AUTH" as AuthFlowType,
        ClientId: enviroment.COGNITO_CLIENT_ID,
        AuthParameters: {
            REFRESH_TOKEN: refreshToken,
            SECRET_HASH: this.getSecretHash(user.usernameAWS, enviroment.COGNITO_CLIENT_ID, enviroment.COGNITO_SECRET_HASH),
        },
    };

    return new Promise((resolve, reject) => {
        cognito.initiateAuth(params, (err:any, data:any) => {
            if (err) {
                console.error('Erro ao realizar refreshAuth:', err, err.stack);
                reject(err);
            } else if (data && data.AuthenticationResult) {
                const { AccessToken, IdToken } = data.AuthenticationResult;
                if (AccessToken) {
                    localStorage.setItem('token', AccessToken);
                }
                if (IdToken) {
                    localStorage.setItem('idToken', IdToken);
                }
                if (data.AuthenticationResult.AccessToken) {
                    resolve(data.AuthenticationResult.AccessToken);
                } else {
                    reject('AccessToken is undefined');
                }
            } else {
                reject('Nenhum resultado de autenticação retornado.');
            }
        });
    });
}

  async forgotPassword(user: User) : Promise<ForgotPasswordCommandOutput> {
    
    const cognito = new CognitoIdentityProvider({
      region: enviroment.COGNITO_REGION,
    });

    let param = new ForgotPasswordRequest(user.email, 
                                  this.getSecretHash(user.email, enviroment.COGNITO_CLIENT_ID, enviroment.COGNITO_SECRET_HASH),
                                  user.name,
                                  user.language);

    return cognito.forgotPassword(param);
  }

  async confirmForgotPassword(user: User) : Promise<ConfirmForgotPasswordCommandOutput> {
    const cognito = new CognitoIdentityProvider({
      region: enviroment.COGNITO_REGION,
    });

    let params = new ConfirmForgotPasswordRequest(user.email,
                                          user.password,
                                          user.code,
                                          this.getSecretHash(user.email, enviroment.COGNITO_CLIENT_ID, enviroment.COGNITO_SECRET_HASH)
    );

    return cognito.confirmForgotPassword(params);
  }

  
  async getUser(token: string) : Promise<GetUserCommandOutput> {
    const cognito = new CognitoIdentityProvider({
      region: enviroment.COGNITO_REGION,
    });

    let param = new GetUserRequest(token);
    
    return new Promise((resolve, reject) => {
      cognito.getUser(param, function(err, data) {
        if(err) {
          reject(err);
        } else {
          if (data) {
            resolve(data);
          } else {
            reject(new Error('GetUserCommandOutput is undefined'));
          }
        }
      });
    });
  }

  async verifyToken(token: string) : Promise<boolean> {
    const verifier = CognitoJwtVerifier.create({
      userPoolId: enviroment.COGNITO_USER_POOL_ID,
      tokenUse: "access",
      clientId: enviroment.COGNITO_CLIENT_ID,
    });
  
    return new Promise((resolve) => {
      verifier.verify(token).then(_ => {
        resolve(true);
      }).catch(error => {
        console.error("Token is invalid. Error:", error);
        resolve(false);
      });
    });
  }
  
  async revokeAuth(user: User) : Promise<boolean> {
    
    const cognito = new CognitoIdentityProvider({
      region: enviroment.COGNITO_REGION,
    });

    let request = new RevokeTokenRequest(user.token);

    return new Promise((resolve) => {
      cognito.revokeToken(request, function(err, data) {
        if(err) {
          console.error('revokeToken', err, err.stack);
          resolve(false);
        } else {
          resolve(true);
        }
      });
    });
  }

  async respondToChallenge(session:string, username:string, code:string) : Promise<any> {
    
    const cognito = new CognitoIdentityProvider({
      region: enviroment.COGNITO_REGION,
    });

    const params: RespondToAuthChallengeCommandInput = {
      ChallengeName: 'CUSTOM_CHALLENGE',
      ClientId: enviroment.COGNITO_CLIENT_ID,
      Session: session,
      ChallengeResponses: {
        USERNAME: username,
        ANSWER: code,
        SECRET_HASH: this.getSecretHash(username, enviroment.COGNITO_CLIENT_ID, enviroment.COGNITO_SECRET_HASH)

      },
    };

    let responseAuth = await cognito.respondToAuthChallenge(params);

    if (responseAuth.AuthenticationResult) {
      this.storeTokens(responseAuth.AuthenticationResult);
    }

    return responseAuth;
  
  }

  async setUserMFA(token: string, mfaEnabled: boolean) : Promise<boolean> {
    const cognito = new CognitoIdentityProvider({
      region: enviroment.COGNITO_REGION,
    });

    let param = {
      AccessToken: token,
      EmailMfaSettings: { 
        Enabled: mfaEnabled,
        PreferredMfa: mfaEnabled
      },
    };

    return new Promise((resolve) => {
      cognito.setUserMFAPreference(param, function(err: any, data: any) {
        if(err) {
          resolve(false);
        } else {
          resolve(true);
        }
      });
    });
  } 

  async updateUserAttibutes(token: string, mfaEnabled: boolean) : Promise<boolean> {
    const cognito = new CognitoIdentityProviderClient({
      region: enviroment.COGNITO_REGION,
    });

    let param = {
      AccessToken: token,
      UserAttributes: [
        {
          Name: 'custom:mfa_enabled',
          Value: mfaEnabled.toString()
        },

      ]
    };
    try {
      const command = new UpdateUserAttributesCommand(param);
      await cognito.send(command);
      console.log('Preferência de MFA atualizada com sucesso.');
      return true;
    } catch (error) {
      console.error('Erro ao atualizar a preferência de MFA:', error);
      return false;
    }

  }

  getSecretHash(username: string, clientId: string, clientSecret: string) {
    return CryptoJS.HmacSHA256(`${username}${clientId}`, clientSecret).toString(CryptoJS.enc.Base64);
  }
}
