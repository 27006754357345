import { Injectable } from '@angular/core';
import { CountryResponse } from '../model/response/country-response';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { enviroment } from 'src/environments/environment';
import { Country } from '../model/country';
import { LanguageEnum } from '../model/enum/language-enum';

@Injectable({providedIn: 'root'})
export class CountryService {

  httpOptions: {};
  countryUrl: string;

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({  
        'Content-Type': 'application/json; charset=utf-8',
        // 'Authorization': 'Bearer ' + idToken;
      })
    };
    this.countryUrl = enviroment.BASE_URL_API + '/global/country';
  }

  getAllCountry() : Observable<CountryResponse[]> {
    let lang = this.getLanguage(localStorage.getItem('language'));
    let idToken = this.getLanguage(localStorage.getItem('idToken'));

    this.httpOptions = {
      headers: new HttpHeaders({  
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + idToken
      })
    };
    let url = this.countryUrl + this.getLanguageUrl(lang);

    return this.http.get<CountryResponse[]>(url, this.httpOptions).pipe(
      map((countries: any[]) => countries.sort((a, b) => a.countryName.localeCompare(b.countryName))), 
      tap(),
      catchError(this.handleError<CountryResponse[]>(`getAllCountry`))
    );
  }

  static toCountry(acc: CountryResponse) : Country {
    if(acc) {
      return new Country(
            acc.countryId,
            acc.countryName,
            acc.code,
            acc.dialCode,
            acc.countryIcon,
            acc.mask);
    } else {
      return new Country();
    }
  }

  static toCountryList(ctrs: CountryResponse[]) : Country[] {
    if(ctrs) {
      let countrys: Country[] = [];
      ctrs.forEach(ct => countrys.push(this.toCountry(ct)));
      return countrys;
    } else {
      return [];
    }
  }

  private getLanguageUrl(language: string) {
      return '?Language=' + language;
  }

  private getLanguage(language: string | null) {
      return (language ? language : LanguageEnum.PT_BR);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
