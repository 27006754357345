import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { Vcu } from 'src/app/model/form/vcu';
import { AccountService } from '../../../service/account.service';
import { VcuService } from 'src/app/service/vcu.service';
import { ProjectService } from 'src/app/service/project.service';
import { Project } from 'src/app/model/form/project';
import { TranslocoService } from '@ngneat/transloco';
import { ConfirmationService } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { ProjectsFindUserVcusGetResp } from 'src/app/model/response/find-user-vcus-get-resp';
import { ModelService } from 'src/app/service/model.service';
import { Item } from 'src/app/model/Item';
import { switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-vcu-management',
  templateUrl: './vcu-management.component.html',
  styleUrl: './vcu-management.component.css'
})
export class VcuManagementComponent implements OnInit {


  vcus: Vcu[] = [];
  vcuEdit: Vcu;
  editMode: boolean = false;
  editing: boolean = false;
  loadingVcu: boolean = false;
  showVcuDialog: boolean = false;
  userID: string | null = null;
  projectID: string | null = null;
  vcuID: string | null = null;
  projectsID: string[] = [];
  projectsUserVcu: ProjectsFindUserVcusGetResp[] = [];
  modelOptions: any[] = [];

  @ViewChild('dtVcu') dtVcu: Table | undefined;

  constructor(private accountService: AccountService,
              private vcuService: VcuService,
              private projectService: ProjectService,
              private confirmationService: ConfirmationService,
              private titleService: Title,
              private translocoService: TranslocoService,
              private modelService: ModelService) {
    this.vcuEdit = new Vcu();
  }
  
  ngOnInit(): void {
    this.loadingVcu = true;
    this.setTitle();
    this.userID = localStorage.getItem('idUser') ?? '';
    this.vcuService.setUserID(this.userID);
  
    if (this.userID) {
      this.modelService
        .getAllModel()
        .pipe(
          tap(dataModel => {
            this.modelOptions = ModelService.toItemList(dataModel);
          }),
          switchMap(() => this.accountService.FindUserVcus(this.userID ?? '','AC,AP'))
        )
        .subscribe(data => {
          if (data) {
            this.processVcus(data);
          }
        });
    }
  }
  private processVcus(data: any[]): void {
    data.forEach(vcudata => {
      if(vcudata.vcuAttributes?.model) {
        let nameModel = this.modelOptions.filter(model => model.id === vcudata.vcuAttributes?.model);
        if(nameModel.length > 0) {
          vcudata.model = new Item(vcudata.vcuAttributes?.model, nameModel[0].name);
        }
    }
    });
  
    this.vcus = VcuService.userVcuToVcus(data);
    data.forEach(userVcu => {
      if (userVcu.projects) {
        this.projectsUserVcu = VcuService.userVcuToProjects(userVcu);
        const projectId = this.projectsUserVcu.length > 0 ? this.projectsUserVcu[0].projectID ?? '' : '';
        this.projectsID.push(projectId);
      }
    });
    this.loadingVcu = false;
  }
  

  createVcu() {
    this.editMode = false;
    this.showVcuDialog = true;
  }

  editVcu(vcu: Vcu) {
    // this.vcuService.findVcu().subscribe(data => {
    //   this.vcuEdit = VcuService.toVcu(data);
    //   this.showVcuDialog = true;
    // });
    this.editing = true;
    this.findVcuInProjects(vcu).then(_ => {
      this.editMode = true;
      this.showVcuDialog = true;
    });
  }

  // removeVcu(event: Event, index: number, vcu: Vcu) {
  //   this.translocoService.selectTranslateObject('vcu.detail.tableVcu.delete').subscribe(value => 
  //     this.confirmationService.confirm({
  //       target: event.target as EventTarget,
  //       message: value.message,
  //       header: value.header,
  //       icon: 'pi pi-info-circle',
  //       acceptButtonStyleClass:"p-button-danger",
  //       rejectButtonStyleClass:"p-button-text",
  //       acceptLabel: value.yes,
  //       rejectLabel: value.no,
  //       acceptIcon:"none",
  //       rejectIcon:"none",
  //       accept: () => {
  //         this.deleteVcuInProjects(vcu, index);
  //       },
  //       reject: () => {
  //       }
  //     })
  //   );
  // }

  updateVcuDialog() {
    this.accountService.FindUserVcus(this.userID ?? '','AC,AP').subscribe(data => {
      if(data) {
        this.vcus = VcuService.userVcuToVcus(data);
        this.processVcus(data);
        // for(let userVcu of data) {
        //   if(userVcu.projects) {
        //     this.projectsUserVcu = VcuService.userVcuToProjects(userVcu);
        //   }
        // }
        this.showVcuDialog = false;
      }
    });
    // if (this.userID) {
    //   this.modelService
    //     .getAllModel()
    //     .pipe(
    //       tap(dataModel => {
    //         this.modelOptions = ModelService.toItemList(dataModel);
    //       }),
    //       switchMap(() => this.accountService.FindUserVcus(this.userID ?? '','AC,AP'))
    //     )
    //     .subscribe(data => {
    //       if (data) {
    //         this.processVcus(data);
    //       }
    //     });
    //     this.showVcuDialog = false;
    // }
  }

  applyFilterGlobalVcu($event: any, stringVal: string) {
    this.dtVcu?.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  setTitle() {
    this.translocoService.selectTranslate('title.vcu').subscribe(value => 
      this.titleService.setTitle(value)
    );
  }
  
  unlinkVcu(index: number) {
    this.translocoService.selectTranslateObject('project.detail.unlink').subscribe(value => 
      this.confirmationService.confirm({
        message: value.message,
        header: value.header,
        icon: 'pi pi-info-circle',
        acceptButtonStyleClass:"p-button-danger",
        rejectButtonStyleClass:"p-button-text",
        acceptLabel: value.yes,
        rejectLabel: value.no,
        acceptIcon:"none",
        rejectIcon:"none",
        accept: () => {
          //TODO chamar backend para remover o vinculo da VCU
          this.vcus.splice(index, 1);
        },
        reject: () => {
        }
      })
    ).unsubscribe();
  }
  
  // async deleteVcuInProjects(vcu: Vcu, index: number) {
  //   this.userID = localStorage.getItem('idUser');
  //   if(this.userID) {
  //     this.vcuService.setUserID(this.userID);
  //     this.projectService.findAllProjectSummary(this.userID).subscribe(data => {
  //       for(let sum of data) {
  //         if(sum.nickname.includes(vcu.surname)) {
  //           this.projectService.getProject(this.userID ?? '', sum.projectID).subscribe(dataProj => {
  //             let project: Project | null = this.projectService.toProject(dataProj);
  //             if(project) {
  //               for(let v of project.vcus) {
  //                 if(vcu.id === v.id) {
  //                   if(this.editing) {
  //                     this.vcuEdit = v;
  //                     this.editing = false;
  //                   }
  //                   this.vcuID = v.id;
  //                   this.projectID = sum.projectID;
  //                   this.vcuService.setProjectID(this.projectID);
  //                   this.vcuService.setVcuID(this.vcuID);
  //                   this.vcuService.deleteVcu().subscribe(_ => this.vcus.splice(index, 1));
  //                 }
  //               }
  //             }
  //           });
  //         }
  //       }
  //     });
  //   }
  // }

  async findVcuInProjects(vcu: Vcu) {
    this.userID = localStorage.getItem('idUser');
    if(this.userID) {
      this.vcuService.setUserID(this.userID);
      this.projectService.findAllProjectSummary(this.userID).subscribe(data => {
        for(let sum of data) {
          if(sum.nickname.includes(vcu.surname)) {
            this.projectService.getProject(this.userID ?? '', sum.projectID).subscribe(dataProj => {
              let project: Project | null = ProjectService.toProject(dataProj);
              if(project) {
                for(let v of project.vcus) {
                  if(vcu.id === v.id) {
                    if(this.editing) {
                      this.vcuEdit = v;
                      this.editing = false;
                    }
                    this.vcuID = v.id;
                    this.projectID = sum.projectID;
                    this.vcuService.setProjectID(this.projectID);
                    this.vcuService.setVcuID(this.vcuID);
                  }
                }
              }
            });
          }
        }
      });
    }
  }
}
