
<div class="background">
    <p-menubar [model]="items">
        <ng-template pTemplate="start">
            <a routerLink="{{ dashboardUrl }}">
                <div class="logo-write"></div>
            </a>
        </ng-template>
        <ng-template pTemplate="item" let-item>
            <span>{{ item.label }}</span>
        </ng-template>
        <ng-template pTemplate="end">
            <p-button 
                id="sidebarMenuBtn"
                icon="pi pi-bars" 
                [rounded]="true" 
                [text]="true" 
                (click)="sidebarMenuVisible = true"
                [style]="{'font-size': '1.2rem', 'color': 'white'}" />
            <p-avatar 
                icon="pi pi-user"
                shape="circle"
                name="sidebarProfileBtn"
                (click)="sidebarProfileVisible = true" />
        </ng-template>
    </p-menubar>
    <p-sidebar [(visible)]="sidebarMenuVisible" position="right">
        <p-button class="card-my-profile" (click)="pageGarage()" [outlined]="true">
            <i class="pi pi-warehouse" style="font-size: 1.2rem; margin-right: 15px;"></i>
            <span>{{ "dashboard.header.garage" | transloco }}</span>
        </p-button>
        <p-button class="card-my-profile" (click)="pageSharedGarage()" [outlined]="true">
            <i class="pi pi-warehouse" style="font-size: 1.2rem; margin-right: 15px;"></i>
            <span>{{ "dashboard.header.sharedGarage" | transloco }}</span>
        </p-button>
        <p-button class="card-my-profile" (click)="pageVCU()" [outlined]="true">
            <i class="pi pi-microchip" style="font-size: 1.2rem; margin-right: 15px;"></i>
            <span>{{ "dashboard.header.vcu" | transloco }}</span>
        </p-button>
        <p-button class="card-my-profile" (click)="myProfile()" [outlined]="true">
            <i class="pi pi-user" style="font-size: 1.2rem; margin-right: 15px;"></i>
            <span>{{ "dashboard.sidebar.myProfile" | transloco }}</span>
        </p-button>
        <div class="exit">
            <p-button [text]="true" (click)="logout()">
                <h3>{{ "dashboard.sidebar.exit" | transloco }}</h3>
            </p-button>
        </div>
    </p-sidebar>
    <p-sidebar [(visible)]="sidebarProfileVisible" position="right">
        <h3>{{ "dashboard.sidebar.hello" | transloco }}</h3>
        <h3>{{ username }}</h3>
        <p-button class="card-my-profile" (click)="myProfile()" [outlined]="true">
            <i class="pi pi-user" style="font-size: 1.2rem; margin-right: 15px;"></i>
            <span>{{ "dashboard.sidebar.myProfile" | transloco }}</span>
        </p-button>
        <div class="exit">
            <p-button [text]="true" (click)="logout()">
                <h3>{{ "dashboard.sidebar.exit" | transloco }}</h3>
            </p-button>
        </div>
    </p-sidebar>
    <div class="container">
        <div class="subcontainer">
            <ng-content></ng-content>
        </div>
    </div>
</div>