import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { MenuItem, Message } from 'primeng/api';
import { Project } from 'src/app/model/form/project';
import { Vcu } from 'src/app/model/form/vcu';
import { Item } from 'src/app/model/Item';
import { Load } from 'src/app/model/mockup/load-mockup';
import { PROJECT_LIST } from 'src/app/model/mockup/project-mockup';
import { FamilyService } from 'src/app/service/family.service';
import { ModelService } from 'src/app/service/model.service';
import { ProjectService } from 'src/app/service/project.service';
import { ResellerService } from 'src/app/service/reseller.service';
import { VcuService } from 'src/app/service/vcu.service';
import { CustomValidator } from 'src/shared/custom-validator';

@Component({
  selector: 'app-register-vcu',
  templateUrl: './register-vcu.component.html',
  styleUrl: './register-vcu.component.css'
})
export class RegisterVCUComponent implements OnInit, OnChanges {
  
  @Input()
  showDialog: boolean = true;

  @Input()
  vcu: Vcu | null = null;

  @Input()
  editMode: boolean = false;

  @Input()
  projectID: string | null = null;

  @Output()
  closeDialog = new EventEmitter<any>();

  public form!: FormGroup;
  public formCode!: FormGroup;

  sidebarVisible2: boolean = true;
  modalpage: string[] = [];
  stepItems: MenuItem[] | undefined;
  messages: Message[] = [];
  active: number = 0;
  dialogHeader: string = '';
  familyOptions: Item[] = [];
  modelOptions: Item[] = [];
  resellerOptions: Item[] = [];
  projectOptions: Item[] = [];
  vcuLinkOut: boolean = false;
  disabledSerialNumber: boolean = true;
  labelBtnSave: string = '';
  saveVcuBtnLoading: boolean = false;
  showDialogLink: boolean = false;
  projectLink: Item | null = null;
  projectLinkInvalid: string = '';
  token: string | null = '';
  userID: string | null = '';

  constructor(private formBuilder: FormBuilder,
              private translocoService: TranslocoService,
              private vcuService: VcuService,
              private projectService: ProjectService,
              private familyService: FamilyService,
              private modelService: ModelService,
              private resellerService: ResellerService) {

    // this.familyOptions = Load.FAMILY
    // this.resellerOptions = Load.DEALER;
    // this.modelOptions = Load.MODEL_VISION;
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.vcu?.currentValue) {
      this.vcu = changes.vcu.currentValue;
      this.familyOptions.filter(item => this.vcu && item.id === this.vcu.family?.id).map(it => (this.vcu ? this.vcu.family = it : null));
      this.resellerOptions.filter(item => this.vcu && item.id === this.vcu.reseller?.id).map(it => (this.vcu ? this.vcu.reseller = it : null));
      this.createForm(this.vcu);
    }
    if(changes.editMode && changes.editMode?.currentValue !== changes.editMode?.previousValue) {
      this.editMode = changes.editMode?.currentValue;
      if(this.editMode) {
        this.isEditMode();
      }  else {
        this.noEditMode();
      }
    }
    if(changes.projectID && changes.projectID?.currentValue !== changes.projectID?.previousValue) {
      this.projectID = changes.projectID?.currentValue;
      this.vcuService.setProjectID(this.projectID ?? '');
    }
    if(changes.showDialog && changes.showDialog?.currentValue !== changes.showDialog?.previousValue) {
      this.showDialog = changes.showDialog.currentValue;
      if(this.vcu && this.showDialog) {
        this.messages = [];
        this.familyOptions.filter(item => this.vcu && item.id === this.vcu.family?.id).map(it => (this.f.family ? this.f.family.setValue(it) : null));
        this.resellerOptions.filter(item => this.vcu && item.id === this.vcu.reseller?.id).map(it => (this.f.reseller ? this.f.reseller.setValue(it) : null));
        if(!this.editMode) {
          this.changeOptions();
        }
      }
    }
  }
  ngOnInit() {
    this.token = sessionStorage.getItem('token');
    if(this.token) {
      this.familyService.getAllFamily().subscribe(data => {
          this.familyOptions = FamilyService.toItemList(data);
          this.changeOptions();
      });
      this.modelService.getAllModel().subscribe(data => {
        this.modelOptions = ModelService.toItemList(data);
        this.changeOptions();
      });
      this.resellerService.getAllReseller().subscribe(data => {
        this.resellerOptions = ResellerService.toItemList(data);
        this.changeOptions();
      });
    }
    this.messages = [];
    this.translocoService.selectTranslate('vcu.header').subscribe(value =>
      this.dialogHeader = value
    );

    if(this.editMode) {
      this.isEditMode();
    }  else {
      this.noEditMode();
    }
    this.active = 0;
    // this.vcu = this.vcu ?? new Vcu();
    this.createForm(this.vcu);
    this.changeLanguage();
  }

  noEditMode() {
    this.vcu = new Vcu();
    this.modalpage = this.goPage(1);
    this.translocoService.selectTranslateObject('vcu.steps').subscribe(value =>
      this.stepItems = value
    );
    this.translocoService.selectTranslateObject('vcu.next').subscribe(value =>
      this.labelBtnSave = value
    );
  }

  isEditMode() {
    this.f.serialNumber.disable();
    this.modalpage = this.goPage(1);
    this.stepItems?.pop();
    this.translocoService.selectTranslateObject('vcu.stepsEdit').subscribe(value =>
      this.stepItems = value
    );
    this.translocoService.selectTranslateObject('vcu.save').subscribe(value =>
      this.labelBtnSave = value
    );
  }

  async onSubmit() {
    this.checkInputs();
    if(this.form.valid) {
      this.messages = [];
      this.saveVcuBtnLoading = true;
      if(this.editMode) {
        this.vcuService.updateVcu(this.form.value).subscribe({
          next: vcuResponse => {
            this.vcu = VcuService.toVcu(vcuResponse);
            this.saveVcuBtnLoading = false;
            this.closeModal();
          },
          error: _ => {
            this.translocoService.selectTranslate('errorConection').subscribe(value => this.messages = [
              { severity: 'error', summary: value}
            ]);
            this.saveVcuBtnLoading = false;
          }
        });
      } else {
        // se projectID null, set project default
        if(!this.projectID || this.projectID === '') {
          this.userID = localStorage.getItem('idUser');
          this.projectService.getProjectDefault(this.userID ?? '').then(project => {
            if(project) {
              this.vcuService.setProjectID(project.id);
              this.vcuService.createVcu(this.form.value).subscribe({
                next: vcuResponse => {
                  //TODO baseado na VCU definir qual a proxima tela pelo vcuLinkOut
                  //TODO BACKEND dependendo a tela, solicitar o backend o codigo de 6 digitos
                  this.vcu = VcuService.toVcu(vcuResponse);
                  this.vcuService.setProjectID('');
                  this.saveVcuBtnLoading = false;
                  this.modalGoPage2();
                },
                error: _ => {
                  this.translocoService.selectTranslate('errorConection').subscribe(value => this.messages = [
                    { severity: 'error', summary: value}
                  ]);
                  this.saveVcuBtnLoading = false;
                }
              });
            }
          });
        } else {
          this.vcuService.createVcu(this.form.value).subscribe({
            next: vcuResponse => {
              if(vcuResponse) {
                //TODO baseado na VCU definir qual a proxima tela pelo vcuLinkOut
                //TODO BACKEND dependendo a tela, solicitar o backend o codigo de 6 digitos
                this.vcu = VcuService.toVcu(vcuResponse);
                this.vcuService.setProjectID('');
                this.saveVcuBtnLoading = false;
                this.modalGoPage2();
              } else {
                this.translocoService.selectTranslate('vcu.error.exist').subscribe(value => this.messages = [
                  { severity: 'error', summary: value}
                ]);
                this.saveVcuBtnLoading = false;
              }
            },
            error: _ => {
              this.translocoService.selectTranslate('errorConection').subscribe(value => this.messages = [
                { severity: 'error', summary: value}
              ]);
              this.saveVcuBtnLoading = false;
            }
          });
        }
      }
    } else {
      this.translocoService.selectTranslate('inputRequired').subscribe(value => this.messages = [
        { severity: 'error', summary: value }
      ]);
    }
  }

  onSubmitCode() {
    if(this.form.valid && this.formCode.valid) {
    } else {
    }
    this.modalGoPage3();
  }

  checkInputs() {
    if(this.f.family.invalid) this.f.family.markAsDirty()
    if(this.f.model.invalid) this.f.model.markAsDirty()
    if(this.f.surname.invalid) this.f.surname.markAsDirty()
    if(this.f.serialNumber.invalid) this.f.serialNumber.markAsDirty()
    if(this.editMode) {
      if(this.f.reseller.invalid) this.f.reseller.markAsDirty()
    }
  }

  createForm(vcu: Vcu | null) {
    if(vcu) {
      this.form = this.formBuilder.group({
        id: [vcu.id],
        image: [vcu.image],
        surname: [vcu.surname, CustomValidator.getRequired()],
        family: [vcu.family, CustomValidator.getRequired()],
        model: [vcu.model, CustomValidator.getRequired()],
        serialNumber: [{value: vcu.serialNumber, disabled: false}, CustomValidator.getRequired()],
        reseller: [vcu.reseller],
        invoice: [vcu.invoice],
        purchaseDate: [vcu.purchaseDate],
        tags: [vcu.tags],
        generalVersionDate: [vcu.generalVersionDate],
        status: [vcu.status],
        lastAcessDate: [vcu.lastAcessDate],
        // linked: [vcu.linked],
        // reasonLinked: [vcu.reasonLinked],
      })
    }
    this.formCode = this.formBuilder.group({
      // code: [null]
      code: [123456]
    })
    this.changeOptions();
    // this.f.serialNumber.disable();
    // this.f.model.disable();
    if(this.editMode) {
      // this.f.family.disable();
      // this.f.reseller.setValidators(CustomValidator.getRequired());
    }
  }

  changeOptions() {
    if(!this.editMode) {
      if(this.form && this.familyOptions.length > 0) this.f.family.setValue(this.familyOptions[0]);
      if(this.form && this.modelOptions.length > 0) this.f.model.setValue(this.modelOptions[0]);
      if(this.form && this.resellerOptions.length > 0) this.f.reseller.setValue(this.resellerOptions[0]);
    }
  }

  addVcuToGarage() {
    this.userID = localStorage.getItem('idUser');
    this.projectService.findAllProjectSummary(this.userID ?? '').subscribe(data => {
      this.projectOptions = []
      let projects: Project[] = this.projectService.projectSummaryToProject(data);
      for(let project of projects) {
        this.projectOptions.push(new Item(project.id, project.name)); 
      }
      if(this.projectOptions.length > 0) 
        this.projectLink = this.projectOptions[0];
      this.showDialogLink = true;
    });
  }

  linkVCU() {
    //TODO BACKEND vincular VCU ao Projeto this.form.value -> projectLink.id
    if(this.vcu && this.projectLink) {
      this.vcuService.setVcuID(this.vcu.id);
      this.vcuService.setProjectID(this.projectLink.id+'');
      this.vcuService.linkVcuInProject().subscribe({});
    } else {
      this.projectLinkInvalid = 'ng-dirty ng-invalid';
    }
  }

  modalGoPage2() {
    this.active = 1;
    this.vcuLinkOut = false;
    this.modalpage = this.goPage(2);
    this.translocoService.selectTranslate('vcu.header2').subscribe(value =>
      this.dialogHeader = value
    );
  }

  modalGoPage3() {
    this.modalpage = this.goPage(3);
    this.dialogHeader = '';
  }
  
  public getCodeLength() {
    if(this.fc.code.value !== null) {
      return this.fc.code.value.toString().length;
    }
    return 0;
  }
  
  closeModal() {
    if(this.editMode) {
      this.isEditMode();
    }  else {
      this.noEditMode();
    }
    this.form.reset();
    this.closeDialog.emit();
  }

  goPage(index: number) {
    let aux = ['none', 'none', 'none', 'none'];
    aux[index-1] = '';
    return aux;
  }

  public changeLanguage(languageCode?: string | null): void {
    if(!languageCode || languageCode === null)
      languageCode = localStorage.getItem('language');
    if(languageCode && languageCode !== null)
      this.translocoService.setActiveLang(languageCode);
  }
  get f() { return this.form.controls; }

  get fc() { return this.formCode.controls; }

  findAllProjectSummary(userID: string | null){  
    this.projectOptions = [];
    if(userID === null) return;
       this.projectService.findAllProjectSummary(userID).subscribe({
      next: (data) =>{
        data.forEach( register => {
          this.projectOptions.push(new Item(register.projectID, register.projectName));
        });
      },
      error: (error) =>{}
    });
  }
}