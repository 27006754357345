import { Injectable } from '@angular/core';
import { User } from '../model/user';
import { AWSError, CognitoIdentityServiceProvider } from 'aws-sdk';
import { CognitoJwtVerifier } from "aws-jwt-verify";
import * as CryptoJS from 'crypto-js';
import { ConfirmForgotPasswordRequest, ConfirmSignUpRequest, ForgotPasswordRequest, GetUserRequest, InitiateAuthRequest, RefreshAuthRequest, ResendConfirmationCodeRequest, RevokeTokenRequest, SignUpRequest } from '../model/request/cognito-request';
import { InitiateAuthResponse } from '../model/response/cognito-response';
import { PromiseResult } from 'aws-sdk/lib/request';
import { ConfirmForgotPasswordResponse, ForgotPasswordResponse, GetUserResponse, SignUpResponse } from 'aws-sdk/clients/cognitoidentityserviceprovider';
import { enviroment } from 'src/environments/environment';
import { Account } from '../model/form/account';

@Injectable({
  providedIn: 'root'
})
export class CognitoService {

  constructor() {}
  
  async signUp(account: Account) : Promise<PromiseResult<SignUpResponse, AWSError>> {
    
    const cognito = new CognitoIdentityServiceProvider({region: enviroment.COGNITO_REGION});

    var param = new SignUpRequest(account.email, 
                                  account.password, 
                                  this.getSecretHash(account.email, enviroment.COGNITO_CLIENT_ID, enviroment.COGNITO_SECRET_HASH),
                                  account.name,
                                  account.country.id.toString(),
                                  account.docNumber,
                                  account.docType.name.toLowerCase(),
                                  (account.birthDate ? account.birthDate.toISOString().substring(0, 10) : ''),
                                  account.phone,
                                  new Boolean(account.checkCookies).toString(),
                                  new Boolean(account.checkNewsletter).toString(),
                                  account.language);

    return cognito.signUp(param).promise();
  }

  async confirmSignup(user: User) : Promise<boolean> {
    const cognito = new CognitoIdentityServiceProvider({region: enviroment.COGNITO_REGION});

    var params = new ConfirmSignUpRequest(user.email,
                                          user.code,
                                          this.getSecretHash(user.email, enviroment.COGNITO_CLIENT_ID, enviroment.COGNITO_SECRET_HASH)
    );

    return new Promise((resolve) => {
      cognito.confirmSignUp(params, function(err, data) {
        if(err) {
          console.error('confirmSignup', err, err.stack);
          // if(err.name === 'ExpiredCodeException') {
          // }
          resolve(false);
        } else {
          resolve(true);
        }
      });
    });
  }
  
  async resendConfirmationCode(user: User, origin: string) : Promise<boolean> {
    
    const cognito = new CognitoIdentityServiceProvider({region: enviroment.COGNITO_REGION});

    var param = new ResendConfirmationCodeRequest(user.email, 
                                  this.getSecretHash(user.email, enviroment.COGNITO_CLIENT_ID, enviroment.COGNITO_SECRET_HASH),
                                  user.name,
                                  user.language,
                                  origin);

    return new Promise((resolve) => {
      cognito.resendConfirmationCode(param, function(err, data) {
        if(err) {
          resolve(false);
        } else {
          resolve(true);
        }
      });
    });
  }

  async initiateAuth(user: User) : Promise<boolean> {
    
    const cognito = new CognitoIdentityServiceProvider({region: enviroment.COGNITO_REGION});

    var request = new InitiateAuthRequest(user.email, 
                                  user.password, 
                                  this.getSecretHash(user.email, enviroment.COGNITO_CLIENT_ID, enviroment.COGNITO_SECRET_HASH));
    var response = new InitiateAuthResponse();

    return new Promise((resolve, reject) => {
      cognito.initiateAuth(request, function(err, data) {
        if(err) {
          console.error('initiateAuth', err, err.stack);
          reject(err);
        } else {
          response = data
          if(response.AuthenticationResult) {
            if(response.AuthenticationResult.RefreshToken) 
              localStorage.setItem('refreshToken', response.AuthenticationResult.RefreshToken);
            if(response.AuthenticationResult.IdToken) 
              localStorage.setItem('idToken', response.AuthenticationResult.IdToken);
            if(response.AuthenticationResult.AccessToken) {
              sessionStorage.setItem('token', response.AuthenticationResult.AccessToken);
              resolve(true);
            }
          } else {
            resolve(false);
          }
        }
      })
    });
  }
  
  async refreshAuth(user: User) : Promise<string> {
    
    const cognito = new CognitoIdentityServiceProvider({region: enviroment.COGNITO_REGION});

    var request = new RefreshAuthRequest(user.refreshToken,
                                  this.getSecretHash(user.usernameAWS, enviroment.COGNITO_CLIENT_ID, enviroment.COGNITO_SECRET_HASH));
    var response = new InitiateAuthResponse();

    return new Promise((resolve, reject) => {
      cognito.initiateAuth(request, function(err, data) {
        if(err) {
          console.error('refreshAuth', err, err.stack);
          reject(err.name);
        } else {
          response = data
          if(response.AuthenticationResult) {
            if(response.AuthenticationResult.RefreshToken) 
              localStorage.setItem('refreshToken', response.AuthenticationResult.RefreshToken);
            if(response.AuthenticationResult.AccessToken) 
              resolve(response.AuthenticationResult.AccessToken);
          }
        }
      });
    });
  }

  async forgotPassword(user: User) : Promise<PromiseResult<ForgotPasswordResponse, AWSError>> {
    
    const cognito = new CognitoIdentityServiceProvider({region: enviroment.COGNITO_REGION});

    var param = new ForgotPasswordRequest(user.email, 
                                  this.getSecretHash(user.email, enviroment.COGNITO_CLIENT_ID, enviroment.COGNITO_SECRET_HASH),
                                  user.name,
                                  user.language);

    return cognito.forgotPassword(param).promise();
  }

  async confirmForgotPassword(user: User) : Promise<PromiseResult<ConfirmForgotPasswordResponse, AWSError>> {
    const cognito = new CognitoIdentityServiceProvider({region: enviroment.COGNITO_REGION});

    var params = new ConfirmForgotPasswordRequest(user.email,
                                          user.password,
                                          user.code,
                                          this.getSecretHash(user.email, enviroment.COGNITO_CLIENT_ID, enviroment.COGNITO_SECRET_HASH)
    );

    return cognito.confirmForgotPassword(params).promise();
  }

  
  async getUser(token: string) : Promise<GetUserResponse> {
    
    const cognito = new CognitoIdentityServiceProvider({region: enviroment.COGNITO_REGION});

    var param = new GetUserRequest(token);

    return new Promise((resolve, reject) => {
      cognito.getUser(param, function(err, data) {
        if(err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  async verifyToken(token: string) : Promise<boolean> {

    const verifier = CognitoJwtVerifier.create({
      userPoolId: enviroment.COGNITO_USER_POOL_ID,
      tokenUse: "access",
      clientId: enviroment.COGNITO_CLIENT_ID,
    });
  
    return new Promise((resolve) => {
      verifier.verify(token).then(_ => {
        resolve(true);
      }).catch(error => {
        console.error("Token is invalid. Error:", error);
        resolve(false);
      });
    });
  }
  
  async revokeAuth(user: User) : Promise<boolean> {
    
    const cognito = new CognitoIdentityServiceProvider({region: enviroment.COGNITO_REGION});

    var request = new RevokeTokenRequest(user.token);

    return new Promise((resolve) => {
      cognito.revokeToken(request, function(err, data) {
        if(err) {
          console.error('revokeToken', err, err.stack);
          resolve(false);
        } else {
          resolve(true);
        }
      });
    });
  }

  getSecretHash(username: string, clientId: string, clientSecret: string) {
    return CryptoJS.HmacSHA256(`${username}${clientId}`, clientSecret).toString(CryptoJS.enc.Base64);
    // return Base64.stringify(CryptoJS.HmacSHA256(`${username}${clientId}`, clientSecret))
  }
}
