import { Injectable } from '@angular/core';
import { AccountResponse } from '../model/response/account-response';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of, tap } from 'rxjs';
import { AccountRequest } from '../model/request/account-request';
import { Account } from '../model/form/account';
import { enviroment } from 'src/environments/environment';
import { LanguageEnum } from '../model/enum/language-enum';
import { EN, PT } from '../model/mockup/language-mockup';
import { UserResponseDto } from '../model/response/UserResponseDto';
import { DocType } from '../model/doc-type';
import { Reason } from '../model/reason';
import { FindUserVcusGetResp } from '../model/response/find-user-vcus-get-resp';
import { FindUserEmailGetResp } from '../model/response/find-user-email-get-resp';

@Injectable({providedIn: 'root'})
export class AccountService {

  httpOptions: {};
  accountUrl: string;
  accountRequest: AccountRequest;

  constructor(private http: HttpClient) {
    this.httpOptions = {};
    this.accountUrl = enviroment.BASE_URL_API + '/user';
    this.accountRequest = new AccountRequest();
  }

  
  getAccount(userId: string) : Observable<AccountResponse> {
    return this.http.get<AccountResponse>(this.accountUrl + '/'+ userId, this.httpOptions).pipe(
      tap()
    );
  }
  
  findUserActive() : Observable<UserResponseDto> {
    return this.http.get<UserResponseDto>(this.accountUrl + '/active', this.httpOptions).pipe(
      tap(),
      catchError(this.handleError<UserResponseDto>(`findUserActive`))
    );
  }

  updateAccount(account: Account) : Observable<AccountResponse> {
    let accountRequest = this.toAccountRequest(account);
    return this.http.put<AccountResponse>(this.accountUrl + '/'+ account.id, accountRequest, this.httpOptions).pipe(
      tap()
    );
  }

  updateAccountFix(account: Account, docNumber: string, email: string, inactiveReason: Reason | null) : Observable<AccountResponse> {

    let accountRequest = this.toAccountRequestFix(account, docNumber, email, inactiveReason);

    return this.http.put<AccountResponse>(this.accountUrl + '/'+ account.id, accountRequest, this.httpOptions).pipe(
      tap(),
      catchError(this.handleError<AccountResponse>('updateAccount'))
    );
  }
  
  inactiveAccount(id: number) : Observable<AccountResponse> {
    return this.http.get<AccountResponse>(this.accountUrl + '/inactive/'+ id, this.httpOptions).pipe(
      tap(),
      catchError(this.handleError<AccountResponse>(`inactiveAccount id=${id}`))
    );
  }


  toAccountRequest(acc: Account) : AccountRequest {
    let lan = (acc.language === EN ? LanguageEnum.EN_US : LanguageEnum.PT_BR)
    if(acc) {
      return new AccountRequest(
            acc.id,
            acc.country, 
            acc.name, 
            acc.docNumber, 
            (acc.birthDate ? acc.birthDate.toISOString().split('T')[0] : null), 
            acc.phone, 
            acc.email, 
            lan, 
            acc.checkNewsletter, 
            acc.inactive, 
            acc.inactiveReason,
            acc.firstAccess);
    } else {
      return new AccountRequest();
    }
  }

  toAccountRequestFix(acc: Account, docNumber: string, email: string, inactiveReason: Reason | null) : AccountRequest {
    let lan = (acc.language === EN ? LanguageEnum.EN_US : LanguageEnum.PT_BR)
    if(acc) {
      return new AccountRequest(
            acc.id,
            acc.country, 
            acc.name, 
            docNumber, 
            (acc.birthDate ? acc.birthDate.toISOString().split('T')[0] : null), 
            acc.phone, 
            email, 
            lan, 
            acc.checkNewsletter, 
            acc.inactive, 
            inactiveReason,
            acc.firstAccess);
    } else {
      return new AccountRequest();
    }
  }

  static toAccount(acc: AccountResponse) : Account {
    if(acc) {
      return new Account(
            acc.userId,
            acc.country, 
            acc.name, 
            (acc.docNumber.length === 11 ? DocType.CPF : DocType.CNPJ),
            acc.docNumber,
            (acc.birthDate ? AccountService.convertDateToUTC(new Date(acc.birthDate)) : null), 
            acc.phone, 
            acc.email, 
            '',
            '',
            (LanguageEnum.EN_US === acc.language ? EN : PT), 
            true,
            acc.checkNewsletter, 
            acc.inactive, 
            acc.inactiveReason);
    } else {
      return new Account();
    }
  }


  static convertDateToUTC(date: Date) { 
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()); 
  }

  getLanguage(lan: string | null) {
    return (lan ? lan : LanguageEnum.PT_BR);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  FindUserVcus(userID: string, statusVcu?:string) : Observable<FindUserVcusGetResp[]> {
    if(statusVcu){
      return this.http.get<FindUserVcusGetResp[]>(this.accountUrl + '/'+ userID + '/vcu?statusVcu='+ statusVcu, this.httpOptions).pipe(
        tap(),
        catchError(this.handleError<FindUserVcusGetResp[]>(`FindUserVcus`))
      );
    }else{
      return this.http.get<FindUserVcusGetResp[]>(this.accountUrl + '/'+ userID + '/vcu', this.httpOptions).pipe(
        tap(),
        catchError(this.handleError<FindUserVcusGetResp[]>(`FindUserVcus`))
      );
    }
  }

  FindUserEmail(email: string) : Observable<FindUserEmailGetResp> {
    return this.http.get<FindUserEmailGetResp>(this.accountUrl + '/email/'+ email, this.httpOptions).pipe(
      tap()
    );
  }
}
