import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Message } from 'primeng/api';
import { CognitoException } from 'src/app/model/enum/cognito-exception';
import { AuthService } from 'src/app/service/auth.service';
import { CustomValidator } from 'src/shared/custom-validator';
import { GeolocationService } from 'src/app/service/geolocationService';
import { GlobalService } from 'src/app/service/global.service';
import { Language } from 'src/app/model/language';
import { LANGUAGE_LIST } from 'src/app/model/mockup/language-mockup';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.css'
})
export class ForgotPasswordComponent implements OnInit {

  public form!: FormGroup;
  public formCode!: FormGroup;
  public formPass!: FormGroup;

  email: string;
  code: string;
  password: string;
  passwordConfirm: string;
  isDivEmail: string;
  isDivCode: string;
  isDivPass: string;
  loginUrl: string;
  messagesEmail: Message[]
  messagesCode: Message[]
  messagesCodeFix: Message[]
  messagesPass: Message[]
  submitBtnLoading: boolean = false;

  languageOptions: Language[];
  DEFAULT_LANGUAGE: Language;
  lastLanguage: Language;
  language: Language;
  countdown: number = 60; 
  isButtonDisabled: boolean = true;
  private intervalId: any;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private titleService: Title,
    private translocoService: TranslocoService,
    private geolocationService: GeolocationService,
    private globalService: GlobalService) {

    this.email="";
    this.code="";
    this.password="";
    this.passwordConfirm="";
    this.isDivEmail="";
    this.isDivCode="none";
    this.isDivPass="none";
    this.loginUrl = '/login';
    this.messagesEmail = [];
    this.messagesCode = [];
    this.messagesCodeFix = [];
    this.messagesPass = [];

    this.languageOptions = LANGUAGE_LIST as [];
    this.DEFAULT_LANGUAGE = this.languageOptions[0];
    this.language = this.DEFAULT_LANGUAGE;
    this.lastLanguage = this.DEFAULT_LANGUAGE;
  }

  ngOnInit(): void {
    let lan = localStorage.getItem('language');
    this.createForm(this.email);
    this.createFormCode(this.code);
    this.createFormPass(this.password, this.passwordConfirm);
    this.changeLanguage(this.getLanguage(lan).name);
    this.refreshLanguageOptions();
    this.getLocation();
    this.setTitle();
    this.createFixMessage();
    this.startCountdown();

    this.form.controls['email'].valueChanges.subscribe(value => {
      if (value) {
        this.form.controls['email'].setValue(value.toLowerCase(), { emitEvent: false });
      }
    });

  }

  startCountdown(): void {
    this.isButtonDisabled = true;
    this.countdown = 60;

    this.intervalId = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        this.isButtonDisabled = false;
        clearInterval(this.intervalId);
      }
    }, 1000);
  }

  async onSubmit() {
    if(this.form.valid) {
      this.submitBtnLoading = true;
      
      this.globalService.FindUseEmail(this.f.email.value).subscribe({
        next: _=> {
          this.authService.forgotPassword(this.f.email.value, null, localStorage.getItem('language')).then(() => {
            this.email = this.maskEmail(this.f.email.value);
            this.submitBtnLoading = false;
            this.showCode();
          }).catch(error => {
            let messageError = '';
            if(CognitoException.USER_NOT_FOUND === error.name)
              this.translocoService.selectTranslate('user.forgotPassword.emailValid').subscribe(
                value => messageError = value
              );
            if(messageError.length < 1)
              messageError = error;
            this.submitBtnLoading = false;
            this.messagesEmail = [{ severity: 'error', summary: messageError}]
          });
        },
        error: _=>{
          this.submitBtnLoading = false;
          this.f.email.markAsDirty()
          this.translocoService.selectTranslate('user.forgotPassword.emailValid').subscribe(value => 
            this.messagesEmail = [{ severity: 'error', summary: value}]
          );          
        }
      });
    } else {
      this.f.email.markAsDirty()
      this.translocoService.selectTranslate('inputRequired').subscribe(value => this.messagesEmail = [
        { severity: 'error', summary: value}
      ]);
    }
  }

  async onSubmitCode() {
    if(this.formCode.valid && String(this.fc.code.value).length === 6) {
      this.submitBtnLoading = true;
      await this.authService.confirmForgotPassword(this.f.email.value, '111111', this.fc.code.value).then(() => {
        this.translocoService.selectTranslate('inputRequired').subscribe(value => this.messagesCode = [
          { severity: 'error', summary: value}
        ]);
      }).catch(error => {
        if(CognitoException.INVALID_PASSWORD === error.name) {
          this.showPass();
        } else if(CognitoException.CODE_MISMATCH === error.name) {
          this.resetFormCode()
          this.showCode();
          this.translocoService.selectTranslate('user.forgotPassword.codeMismatch').subscribe(value => this.messagesCodeFix = [
            { severity: 'error', summary: value}
          ]);          
        } else if(CognitoException.EXPIRED_CODE === error.name) {
          this.resetFormCode()
          this.showCode();
          this.translocoService.selectTranslate('user.forgotPassword.expiredCode').subscribe(value => this.messagesPass = [
            { severity: 'error', summary: value}
          ]);
        } else {
          this.resetFormCode()
          this.showCode();
          this.messagesPass = [{ severity: 'error', summary: error}]
        }
        this.submitBtnLoading = false;
      });
    } else {
      this.fc.code.markAsDirty()
      this.translocoService.selectTranslate('inputRequired').subscribe(value => this.messagesCode = [
        { severity: 'error', summary: value}
      ]);
    }
  }

  async onSubmitPass() {
    if(this.fp.password.invalid) this.fp.password.markAsDirty()
    if(this.fp.passwordConfirm.invalid) this.fp.passwordConfirm.markAsDirty()
    
    // if( this.formPass.valid) {
      this.submitBtnLoading = true;
      await this.authService.confirmForgotPassword(this.f.email.value, this.fp.password.value, this.fc.code.value).then(() => {
        this.translocoService.selectTranslate('user.forgotPassword.msgSuccess').subscribe(value => this.messagesPass = [
          { severity: 'success', summary: value}
        ]);
        setTimeout(() => {
        this.submitBtnLoading = false;
          this.router.navigate([this.loginUrl]);
        }, 3000);  //3s
      }).catch(error => {
        if(CognitoException.INVALID_PASSWORD === error.name) {
          this.translocoService.selectTranslate('user.forgotPassword.invalidPassword').subscribe(value => this.messagesPass = [
            { severity: 'error', summary: value}
          ]);
        } else if(CognitoException.CODE_MISMATCH === error.name) {
          this.resetFormCode()
          this.showCode();
          this.translocoService.selectTranslate('user.forgotPassword.codeMismatch').subscribe(value => this.messagesCodeFix = [
            { severity: 'error', summary: value}
          ]);          
        } else if(CognitoException.EXPIRED_CODE === error.name) {
          this.resetFormCode()
          this.showCode();
          this.translocoService.selectTranslate('user.forgotPassword.expiredCode').subscribe(value => this.messagesPass = [
            { severity: 'error', summary: value}
          ]);
        } else {
          this.resetFormCode()
          this.showCode();
          this.messagesPass = [{ severity: 'error', summary: error}]
        }
        this.submitBtnLoading = false;
      });
    // } else {
    //   if(this.messagesPass.length === 0) {
    //     this.translocoService.selectTranslate('inputRequired').subscribe(value => this.messagesPass = [
    //       { severity: 'error', summary: value}
    //     ]);
    //   }
    // }
  }

  equalsPassword() : boolean {
    if(this.fp.password.value !== this.fp.passwordConfirm.value) {
      this.translocoService.selectTranslate('user.createAccount.passwordEquals').subscribe(value => 
        this.messagesPass = [{ severity: 'error', summary: value }]
      );
      return false;
    } else if(this.fp.password.invalid || this.fp.passwordConfirm.invalid) {
      this.translocoService.selectTranslate('user.forgotPassword.invalidPassword').subscribe(value => 
        this.messagesPass = [{ severity: 'error', summary: value }]
      );
      return false;
    }
    return true;
  }

  createForm(email: string) {
    this.form = this.formBuilder.group({
      email: [email, CustomValidator.getEmail()]
    })
  }
  createFormCode(code: string) {
    this.formCode = this.formBuilder.group({
      code: [code, CustomValidator.getRequired()]
    })
  }
  createFormPass(password: string, passwordConfirm: string) {
    this.formPass = this.formBuilder.group({
      password: [password, CustomValidator.getPassword()],
      passwordConfirm: [passwordConfirm, CustomValidator.getPassword()]
    })
  }
  resetFormCode() {
    this.formCode.reset();
  }
  resetFormPass() {
    this.formPass.reset();
  }

  createFixMessage() {
    this.translocoService.selectTranslate('user.forgotPassword.msg').subscribe(value => this.messagesCodeFix = [
      { severity: 'success', summary: value}
    ]);
  }

  backLogin() {
    this.router.navigate([this.loginUrl]);
  }

  async sendEmailAgain() {
    if (!this.isButtonDisabled) {
        await this.authService.resendConfirmationCode(this.f.email.value, null, localStorage.getItem('language'), 'forgot').then(() => {
        }).catch(() => {
          this.translocoService.selectTranslate('errorConection').subscribe(value => this.messagesCode = [
            { severity: 'error', summary: value}
          ]);
        });
        this.startCountdown();
    }
  }
  
  showEmail() {
    this.isDivEmail = "";
    this.isDivCode = "none";
    this.isDivPass="none";
    this.messagesEmail = [];
  }

  showCode() {
    this.isDivEmail = "none";
    this.isDivCode = "";
    this.isDivPass="none";
    this.messagesCode = [];
    this.createFixMessage();
  }

  showPass() {
    this.isDivEmail = "none";
    this.isDivCode = "none";
    this.isDivPass="";
    this.messagesPass = [];
  }

  maskEmail(email: string) {
    return email.substring(0,1)+
          '*****@***'+
          email.substring(email.length - 2);
  }

  setTitle() {
    this.translocoService.selectTranslate('title.forgot-password').subscribe(value => 
      this.titleService.setTitle(value)
    );
  }

  fixLanguage(event:any) {
    this.language = event.option;    
    if(this.lastLanguage !== this.language) {
        this.languageOptions
          .filter(opt => opt.name === this.language.name)
          .map(cty => cty.disabled = true)
        this.languageOptions
          .filter(opt => opt.name !== this.language.name)
          .map(cty => cty.disabled = false)

        this.lastLanguage = this.language;
        this.changeLanguage(this.language.name);
        localStorage.setItem('language', this.language.name);
    }
  }

  getLanguage(lan: string | null) : Language {
    for (let i = 0; i < this.languageOptions.length; i++) {
      if(this.languageOptions[i].name === lan) {
        return this.languageOptions[i];
      }
    }
    return this.DEFAULT_LANGUAGE;
  }

  public changeLanguage(languageCode: string | null): void {
    if(languageCode !== null)
      this.translocoService.setActiveLang(languageCode);
  }

  getLocation(){
    this.geolocationService.getCountry().subscribe((data: any) => {
      if (data.country === "BR") {
        this.language = this.languageOptions[0];
      } else {
        this.language = this.languageOptions[1];
      }
    });
  }

  refreshLanguageOptions() {
    for (let i = 0; i < this.languageOptions.length; i++) {
      if(this.languageOptions[i].name === this.language.name)
        this.languageOptions[i].disabled = true;
      else
       this.languageOptions[i].disabled = false;
    }
  }



  get f() { return this.form.controls; }

  get fc() { return this.formCode.controls; }

  get fp() { return this.formPass.controls; }
}
