import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable} from 'rxjs';
import { enviroment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class FileService {
  fileUrl: string;

  constructor(private http: HttpClient) {
    this.fileUrl = enviroment.BASE_URL_API + '/files/user';
  }

  HeadFile(userID: string, projectID:string, folder: string, fileName: string): Observable<HttpResponse<any>> {
    const url = `${this.fileUrl}/${userID}/project/${projectID}/${folder}/${fileName}`;
    return this.http.head<any>(url, { observe: 'response' });
  }
  
  PostFile(userID: string, projectID:string, folder: string, fileName: string, uploadId: string, partNumber: number, file: File): Observable<HttpResponse<any>> {
    const url = `${this.fileUrl}/${userID}/project/${projectID}/${folder}/${fileName}?upload_id=${uploadId}&part_number=${partNumber}`;
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<any>(url, formData, { observe: 'response' });
  }

  PutFile(userID: string, projectID: string, folder: string, fileName: string, uploadId: string, parts: { ETag: string, PartNumber: number }[]): Observable<HttpResponse<any>> {
    const url = `${this.fileUrl}/${userID}/project/${projectID}/${folder}/${fileName}?upload_id=${uploadId}`;
    return this.http.put<any>(url, parts, { observe: 'response' });
  }

  downloadFile(userID: string, projectID: string, folder: string, fileName: string): Observable<Blob> {
    const url = `${this.fileUrl}/${userID}/project/${projectID}/${folder}/${fileName}`;
    const httpOptions = {
      headers: new HttpHeaders({'Range': 'bytes=0-4178081'}),
      responseType: 'blob' as 'json'
    }
    return this.http.get<Blob>(url,httpOptions);
  }

}
