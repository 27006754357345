import { Project } from "../form/project"
import { Item } from "../Item";
import { EQUIPMENT_1, EQUIPMENT_2, EQUIPMENT_3, EQUIPMENT_4 } from "./equipment-mockup";
import { IMAGE_3, IMAGE_6, IMAGE_7, IMAGE_8, IMAGE_AUDI_LIST, IMAGE_CRUZE_LIST, IMAGE_PUNTO_LIST, IMAGE_VOYAGE_LIST, IMAGE_12,IMAGE_DEFAULT_LIST } from "./image-mockup";
import { SHARE_1 } from "./share-mockup";
import { VCU_1, VCU_2, VCU_3 } from "./vcu-mockup"
import { VEHICLE_1, VEHICLE_2, VEHICLE_3, VEHICLE_4 } from "./vehicle-mockup";

export const PROJECT_1 = new Project(
    '1e1d520d-7c21-11ef-8cc9-0eea79e1407b',
    false,
    "Audi Q3 1.4 TFSI",
    new Item(0, 'Aplication2'),
    VEHICLE_1,
    ["Audi", "Turbo", "Forjado"],
    "Detalhes do Audi",
    [VCU_1],
    [EQUIPMENT_1, EQUIPMENT_2],
    IMAGE_AUDI_LIST,
    IMAGE_7,
    undefined,
    []
);

export const PROJECT_2 = new Project(
    '2e1d520d-7c21-11ef-8cc9-0eea79e1407b',
    false,
    "Volkswagen Voyage",
    new Item(0, 'Aplication1'),
    VEHICLE_2,
    ["Volkswagen", "Turbo", "Forjado", "3kg e 1/2"],
    "Detalhes do Volkswagen",
    [VCU_3],
    [EQUIPMENT_3, EQUIPMENT_4],
    IMAGE_VOYAGE_LIST,
    IMAGE_3,
    SHARE_1,
    []
);

export const PROJECT_3 = new Project(
    '3e1d520d-7c21-11ef-8cc9-0eea79e1407b',
    false,
    "Chevrolet Cruze",
    new Item(0, 'Aplication2'),
    VEHICLE_3,
    ["Chevrolet", "Cruze", "Aspirado", "1.8"],
    "Detalhes do Chevrolet",
    [VCU_2],
    [EQUIPMENT_3, EQUIPMENT_4],
    IMAGE_CRUZE_LIST,
    IMAGE_6,
    SHARE_1,
    []
);
export const PROJECT_4 = new Project(
    '4e1d520d-7c21-11ef-8cc9-0eea79e1407b',
    false,
    "Fiat Punto",
    new Item(0, 'Aplication1'),
    VEHICLE_4,
    ["Fiat", "Punto", "Turbo", "1.4"],
    "Detalhes do Fiat",
    [VCU_2],
    [EQUIPMENT_3, EQUIPMENT_4],
    IMAGE_PUNTO_LIST,
    IMAGE_8,
    SHARE_1,
    []
);

export const PROJECT_5 = new Project(
    '5e1d520d-7c21-11ef-8cc9-0eea79e1407b',
    false,
    "Fiat Punto",
    new Item(0, 'Aplication1'),
    VEHICLE_4,
    ["Fiat", "Punto", "Turbo", "1.4"],
    "Detalhes do Fiat",
    [VCU_2],
    [EQUIPMENT_3, EQUIPMENT_4],
    IMAGE_DEFAULT_LIST,
    IMAGE_12,
    SHARE_1,
    []
);

export const PROJECT_LIST = [ PROJECT_1, PROJECT_2, PROJECT_3, PROJECT_4 ];