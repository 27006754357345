import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { MenuItem, MessageService } from 'primeng/api';
import { Country } from 'src/app/model/country';
import { DocTypeEnum } from 'src/app/model/enum/doc-type';
import { Account } from 'src/app/model/form/account';
import { ACCOUNT_1 } from 'src/app/model/mockup/account-mockup';
import { COUNTRY_LIST } from 'src/app/model/mockup/country-mockup';
import { CustomValidator } from 'src/shared/custom-validator';
import { AccountService } from '../../../service/account.service';
import { enviroment } from 'src/environments/environment';
import { LANGUAGE_LIST } from 'src/app/model/mockup/language-mockup';
import { Language } from 'src/app/model/language';
import { CountryService } from 'src/app/service/country.service';
import { ReasonService } from 'src/app/service/reason.service';
import { Reason } from 'src/app/model/reason';
import { DocType } from 'src/app/model/doc-type';
import { Location } from '@angular/common';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrl: './account.component.css'
})
export class AccountComponent implements OnInit {

  
  public form!: FormGroup;
  
  account!: Account;
  accountDB!: Account;
  userId: string | null = null;
  items: MenuItem[] | undefined;
  countryOptions: Country[] = [];
  docType: DocType | null = null;
  urlDashboard: string = '';
  language: Language;
  lastLanguage: Language;
  languageOptions: Language[] = [];
  inactiveReasonOptions: Reason[] = [];
  isBrasil: boolean = false;
  showBirthDate: boolean = true;
  placeHolderName: string = '';
  minDate: Date | undefined;
  maxDate: Date | undefined;
  beforeInactive: boolean = true;
  submitBtnLoading: boolean = false;
  backBtnLoading: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private titleService: Title,
              private translocoService: TranslocoService,
              private messageService: MessageService,
              private accountService: AccountService,
              private countryService: CountryService,
              private reasonService: ReasonService,
              private confirmationService: ConfirmationService,
              private location: Location) {

    this.languageOptions = LANGUAGE_LIST as [];

    this.translocoService.selectTranslate('user.account.basicData').subscribe(value => this.items = [
      { label: value, icon: 'pi pi-user' }
    ]);
    this.language = this.languageOptions[0];
    this.lastLanguage = this.languageOptions[0];
  }

  async ngOnInit() {
    this.account = new Account();
    this.createForm(this.account);
    this.adaptForm();
    if(enviroment.MOCKUP) {
      this.countryOptions = COUNTRY_LIST;
      this.account = ACCOUNT_1;
      this.createForm(this.account);
      this.adaptForm();
    } else {
      this.countryService.getAllCountry().subscribe({
        next: countrys => {
          this.countryOptions = CountryService.toCountryList(countrys);
        },
        error: _ => this.countryOptions = []
      });
      this.reasonService.getAllReason().subscribe({
        next: reasons => {
          this.inactiveReasonOptions = ReasonService.toReasonList(reasons);
        },
        error: _ => this.inactiveReasonOptions = []
      });
      this.userId = localStorage.getItem('idUser');
      // this.userId = '4e1d520d-7c21-11ef-8cc9-0eea79e1407b';
      if(this.userId) {
        this.accountService.getAccount(this.userId).subscribe({
          next: accountResponse => {
            this.account = AccountService.toAccount(accountResponse);
            this.accountDB = { ...this.account }
            this.createForm(this.account);
            this.adaptForm();
          },
          error: _ => {}
        });
      }
    }
    this.changeLanguage();
    this.urlDashboard = '/dashboard';
  }
  
  createForm(account: Account) {
    this.form = this.formBuilder.group({
      id: [account.id],
      country: [account.country, CustomValidator.getRequired()],
      name: [account.name, CustomValidator.getRequired()],
      docType: [account.docType],
      docNumber: [account.docNumber, CustomValidator.getRequired()],
      birthDate: [account.birthDate, CustomValidator.getRequired()],
      phone: [account.phone, CustomValidator.getRequired()],
      email: [account.email, CustomValidator.getEmail()],
      language: [account.language, CustomValidator.getRequired()],
      checkNewsletter: [account.checkNewsletter],
      inactive: [account.inactive],
      inactiveReason: [account.inactiveReason]
    })
  }

  onSubmit() {
    this.messageService.clear();
    if(this.f.country.invalid) this.f.country.markAsDirty()
    if(this.f.name.invalid) this.f.name.markAsDirty()
    if(this.f.docNumber.invalid) this.f.docNumber.markAsDirty()
    if(DocTypeEnum.CPF === this.f.docType.value.id && this.f.birthDate.invalid) this.f.birthDate.markAsDirty()
    if(this.f.phone.invalid) this.f.phone.markAsDirty()
    if(this.f.email.invalid) this.f.email.markAsDirty()

    if(this.form.valid) {
      if(!this.f.inactive.value)
        this.f.inactiveReason.setValue(null);

      if(!this.beforeInactive && this.f.inactive.value) {
        //TODO BACKEND ??? endpoint separado para inativar o usuario??
      //   this.accountService.inactiveAccount(this.f.id.value).subscribe({
      //     next: _ => {
      //       this.submitBtnLoading = true;
      //       this.translocoService.selectTranslate('user.account.inactiveMsg').subscribe(value => 
      //         this.messageService.add({ severity: 'warn', summary: value})
      //       );
      //       this.submitBtnLoading = false;
      //     },
      //     error: _ => {
      //       this.translocoService.selectTranslate('errorConection').subscribe(value => 
      //         this.messageService.add({ severity: 'error', summary: value})
      //       );
      //       this.submitBtnLoading = false;
      //     }
      //   });
      }
      this.submitBtnLoading = true;
      this.accountService.updateAccountFix(this.form.value, this.account.docNumber, this.account.email, this.verifyInactive()).subscribe({
        next: () => {
          this.translocoService.selectTranslate('user.account.success').subscribe(value => {
            this.messageService.clear();
            this.messageService.add({ severity: 'success', summary: value});
          });
          this.submitBtnLoading = false;
        },
        error: () => {
          this.translocoService.selectTranslate('errorConection').subscribe(value => {
            this.messageService.clear();
            this.messageService.add({ severity: 'error', summary: value});
          });
          this.submitBtnLoading = false;
        }
      });
    } else {
      this.translocoService.selectTranslate('inputRequired').subscribe(value => {
        this.messageService.clear();
        this.messageService.add({ severity: 'error', summary: value});
      });
    }
  }

  adaptForm() {
    this.setTitle();
    // if(this.account.country.id !== 0) {
    //   this.account.country = this.countryOptions.filter(c => c.id === this.account.country.id)[0];
    //   this.f.country.setValue(this.account.country);
    // }
    this.setIsBrasil();
    this.setDateInterval();
    this.fixLanguage();
    this.beforeInactive = this.f.inactive.value;
    this.messageService.clear();
    this.f.docNumber.disable();
    this.f.email.disable();
    
    if(this.f.inactiveReason.value === null) {
      this.f.inactiveReason.setValue((this.inactiveReasonOptions.length > 0 ? this.inactiveReasonOptions[0] : null))
    }
    if(this.beforeInactive) {
      this.f.inactive.disable();
      this.f.inactiveReason.disable();
    }
  }
  
  public setIsBrasil() {
    if('Brasil' === this.f.country.value.name || 'Brazil' === this.f.country.value.name) {
      this.isBrasil = true;
      this.getMaskDocNumber();
      this.showBirthDateAndName();
    } else {
      this.isBrasil = false;
      this.docType = null;
      this.showBirthDate = true;
      let docNumber = this.removeMask(this.f.docNumber.value);
      this.f.docNumber.setValue(docNumber);
    }
  }

  setDateInterval() {
    let today = new Date();
    this.maxDate = new Date();
    this.maxDate.setDate(today.getDate() - 1);
  }

  fixLanguage() {
    this.messageService.clear();
    this.language = this.f.language.value;
    if(this.lastLanguage !== this.language) {
        this.languageOptions
          .filter(opt => opt.name === this.language.name)
          .map(cty => cty.disabled = true)
        this.languageOptions
          .filter(opt => opt.name !== this.language.name)
          .map(cty => cty.disabled = false)

        this.lastLanguage = this.language;
        this.changeLanguage(this.language.name);
    }
  }

  backPage() {
    if(!this.deepEquals(this.form.value, this.accountDB)) {
      this.backBtnLoading = true;
      this.translocoService.selectTranslate('noSaved').subscribe(value => {
        this.messageService.clear();
        this.messageService.add({ key: 'toast', severity: 'error', summary: value});
      }).unsubscribe();

      setTimeout(() => {
        this.location.back();
      }, 3000);  //3s
    } else {
      this.location.back();
    }
  }

  deepEquals(form: Account, db: Account) : boolean {
    if(form && db) {
      if(form.country.id !== db.country.id) return false;
      if(form.name !== db.name) return false;
      if(form.birthDate?.getTime() !== db.birthDate?.getTime()) return false;
      if(this.removeMask(form.phone) !== this.removeMask(db.phone)) return false;
      if(form.language !== db.language) return false;
      if(form.checkNewsletter !== db.checkNewsletter) return false;
    }
    return true;
  }

  setTitle() {
    this.translocoService.selectTranslate('title.account').subscribe(value => 
      this.titleService.setTitle(value)
    );
  }
  
  setPlaceHolderNameCpf() {
    this.translocoService.selectTranslate('user.createAccount.fullName').subscribe(value => 
      this.placeHolderName = value+'*'
    );
  }
  setPlaceHolderNameCnpj() {
    this.translocoService.selectTranslate('user.createAccount.companyName').subscribe(value => 
      this.placeHolderName = value+'*'
    );
  }
  
  showBirthDateAndName() {
    if(DocTypeEnum.CPF === this.f.docType.value.id) {
      this.setPlaceHolderNameCpf();
      this.showBirthDate = true;
    } else if(DocTypeEnum.CNPJ === this.f.docType.value.id) {
      this.setPlaceHolderNameCnpj();
      this.showBirthDate = false;
      this.f.birthDate.setValidators(null);
    }
  }

  verifyInactive() : Reason | null {
    // se esta sendo inativado, retorna o motivo definido no form, senao mantem o motivo do getUser
    if(!this.beforeInactive && this.f.inactive.value)
      return this.f.inactiveReason.value;
    return this.account.inactiveReason;
  }

  getMaskDocNumber() {
    this.messageService.clear();
    let docNumber = this.removeMask(this.f.docNumber.value);
    if(docNumber.length === 11) {
      docNumber = this.addMaskCPF(docNumber);
      this.f.docNumber.setValue(docNumber);
      this.docType = DocType.CPF;
      this.showBirthDate = true;
    } else if(docNumber.length === 14) {
      docNumber = this.addMaskCNPJ(docNumber);
      this.f.docNumber.setValue(docNumber);
      this.docType = DocType.CNPJ;
      this.showBirthDate = false;
    }
  }

  private addMaskCPF(str: string) : string { // '999.999.999-99'
    if(str === null) return "";
    str = str.replace(/\D/g,"");
    str = str.replace(/(\d{3})(\d)/,"$1.$2");
    str = str.replace(/(\d{3})(\d)/,"$1.$2");
    str = str.replace(/(\d{3})(\d{1,2})$/,"$1-$2");
    return str;
  }

  private addMaskCNPJ(str: string) : string { // '99.999.999/9999-99'
    if(str === null) return "";
    str = str.replace(/\D/g,"");
    str = str.replace(/^(\d{2})(\d)/,"$1.$2")
    str = str.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3")
    str = str.replace(/\.(\d{3})(\d)/,".$1/$2")
    str = str.replace(/(\d{4})(\d)/,"$1-$2")
    return str;
  }

  private removeMask(str: string) : string {
    if(str === null) return "";
    str = str.replaceAll(".","");
    str = str.replaceAll("-","");
    str = str.replaceAll("/","");
    return str;
  }

  changeInactive() : void {
    if(this.f.inactive.value) {
      if(this.f.inactiveReason.value === null) 
        this.f.inactiveReason.setValue((this.inactiveReasonOptions.length > 0 ? this.inactiveReasonOptions[0] : null))
    } else {
      this.f.inactiveReason.setValue(null);
    }
  }

  public changeLanguage(languageCode?: string | null): void {
    if(!languageCode || languageCode === null)
      languageCode = localStorage.getItem('language');
    if(languageCode && languageCode !== null)
      this.translocoService.setActiveLang(languageCode);
  }

  confirmInactive(event: any) {
    if (event.checked) {
        this.translocoService.selectTranslateObject('user.account').subscribe(value => 
          this.confirmationService.confirm({
            message: value.inactiveQuest,
            header: value.inactive,
            icon: 'pi pi-info-circle',
            acceptButtonStyleClass:"p-button-danger",
            rejectButtonStyleClass:"p-button-text",
            acceptLabel: value.yes,
            rejectLabel: value.no,
            acceptIcon:"none",
            rejectIcon:"none",
            accept: () => {
              this.form.get('inactive')?.setValue(true);
            },
            reject: () => {
              this.form.get('inactive')?.setValue(false);
            }
          })
        ).unsubscribe();        
    }
}

  get f() { return this.form.controls; }
}
