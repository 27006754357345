import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Message, MessageService } from 'primeng/api';
import { AuthService } from 'src/app/service/auth.service';
import { CustomValidator } from 'src/shared/custom-validator';
import { CognitoException } from 'src/app/model/enum/cognito-exception';
import { AccountService } from 'src/app/service/account.service';
import { Language } from 'src/app/model/language';
import { LANGUAGE_LIST } from 'src/app/model/mockup/language-mockup';
import { GeolocationService } from './../../../service/geolocationService';

@Component({
  selector: 'app-mfa-login',
  templateUrl: './mfa-login.component.html',
  styleUrl: './mfa-login.component.css'
})
export class MfaLoginComponent implements OnInit {
public form!: FormGroup;

  session: string | null = null;
  userName: string | null = null;
  code: string = '';
  email: string | null = null;
  messages: Message[];

  isDivCreate: string;
  isDivActivated: string;
  isDivExpired: string;
  loginUrl: string;
  dashboardUrl: string;
  messagesCode: Message[] = [];

  languageOptions: Language[];
  DEFAULT_LANGUAGE: Language;
  lastLanguage: Language;
  language: Language;

  constructor(private formBuilder: FormBuilder, 
    private router: Router,
    private titleService: Title,
    private translocoService: TranslocoService,
    private authService: AuthService,
    private messageService: MessageService,
    private accountService: AccountService,
    private geolocationService: GeolocationService) {

    this.isDivCreate='';
    this.isDivActivated='none';
    this.isDivExpired='none';

    this.loginUrl = '/login';
    this.dashboardUrl = '/dashboard';
    this.messages = [];

    this.languageOptions = LANGUAGE_LIST as [];
    this.DEFAULT_LANGUAGE = this.languageOptions[0];
    this.language = this.DEFAULT_LANGUAGE;
    this.lastLanguage = this.DEFAULT_LANGUAGE;
  }

  ngOnInit(): void {
    let lan = localStorage.getItem('language');
    this.createForm(this.code);
    this.changeLanguage(this.getLanguage(lan).name);
    this.refreshLanguageOptions();
    this.getLocation();
    this.setTitle();
    this.session= localStorage.getItem('session');
    this.email = localStorage.getItem('email');
  }

  createForm(code: string) {
    this.form = this.formBuilder.group({
      code: [code, CustomValidator.getRequired()]
    })
  }

  async onSubmit() {
    if (this.session !== null && this.email !== null) {
      try {
        await this.authService.respondToChallenge(this.session, this.email, this.f.code.value)
          .then(result => {
            if (result) {
              const token = localStorage.getItem('token');
              if (token) {
                this.accountService.findUserActive().subscribe(
                  data => {
                    if (data) {
                      localStorage.setItem('idUser', data.userID);
                      localStorage.setItem('nameUser', data.name);
                      localStorage.setItem('language', data.language);
                      if (data.accessLevel < 1) {
                        this.messageService.clear();
                        this.translocoService.selectTranslate('user.login.accessDenied').subscribe(value => {
                          this.messageService.add({
                            key: 'mfalogin',
                            severity: 'success',
                            summary: value,
                          });
                        });
                      } else {
                        this.router.navigate([this.dashboardUrl]);
                      }
                    } else {
                      this.handleLoginError('user.login.errorMfa');
                    }
                  },
                  error => {
                    this.handleLoginError('user.login.errorMfa');
                  }
                );
              } else {
                this.resetCodeAndShowError('user.login.errorMfa');
              }
            } else {
              this.resetCodeAndShowError('user.login.errorMfa');
            }
          })
          .catch(error => {
            console.error("error respond to challenge", error);
            this.handleErrorResponse(error);
          });
      } catch (error) {
        console.error("error resposta", error);
        this.handleErrorResponse(error); 
      }
    }
  }
  
  private resetCodeAndShowError(translationKey: string) {
    this.f.code.setValue('');
    this.messageService.clear();
    this.translocoService.selectTranslate(translationKey).subscribe(value => {
      this.messageService.add({
        key: 'mfalogin',
        severity: 'error',
        summary: value,
      });
    });
  }
  

  private handleErrorResponse(error: any) {
    let messageError = '';
    this.f.code.setValue('');
    this.messageService.clear();
    this.translocoService.selectTranslateObject('cognito.insuccess').subscribe(value => {
      const cognitoError = error as { code: string };
      if (CognitoException.NOT_AUTHORIZED === cognitoError.code)
        messageError = value.NotAuthorizedException;
      else if (CognitoException.USER_NOT_CONFIRMED === cognitoError.code)
        messageError = value.UserNotConfirmedException;
      else if (CognitoException.NETWORKING_ERROR === cognitoError.code)
        messageError = value.NetworkingError;
      else if (CognitoException.CODE_MISMATCH === cognitoError.code)
        messageError = value.CodeMismatchException;
      else if (CognitoException.EXPIRED_CODE === cognitoError.code)
        messageError = value.ExpiredCodeException;
      else 
        messageError = value.errorMfa;
  
      this.messageService.add({
        key: 'mfalogin',
        severity: 'error',
        summary: messageError,
      });
    }).unsubscribe();
  }
  

  private handleLoginError(translationKey: string) {
    this.messageService.clear();
    this.translocoService.selectTranslate(translationKey).subscribe(value => {
      this.messageService.add({
        key: 'mfalogin',
        severity: 'error',
        summary: value,
      });
    });
  }
  
  
  sendEmailAgain() {
    if(this.session !== null && this.email !== null)
    this.authService.resendConfirmationCode(this.email, this.userName, localStorage.getItem('language'), 'login').then(() => {
    }).catch(() => {
      this.messageService.clear();
      this.translocoService.selectTranslate('errorConection').subscribe(value => 
        this.messageService.add({
          key: 'mfalogin',
          severity: 'error',
          summary: value,
        })
      );
    });
  }

  redirect() {
    this.router.navigate([this.loginUrl]);
  }

  expired() {
    this.router.navigate([this.loginUrl]);
  }

  showCreate() {
    this.isDivCreate = "";
    this.isDivActivated = "none";
    this.isDivExpired="none";
  }

  showActivated() {
    this.isDivCreate = "none";
    this.isDivActivated = "";
    this.isDivExpired="none";
    setTimeout(() => {
      this.router.navigate([this.loginUrl]);
    }, 5000);  //5s
  }

  showExpired() {
    this.isDivCreate = "none";
    this.isDivActivated = "none";
    this.isDivExpired="";
  }
  
  setTitle() {
    this.translocoService.selectTranslate('title.activate-account').subscribe(value => 
      this.titleService.setTitle(value)
    );
  }


  fixLanguage(event:any) {
    this.language = event.option;    
    if(this.lastLanguage !== this.language) {
        this.languageOptions
          .filter(opt => opt.name === this.language.name)
          .map(cty => cty.disabled = true)
        this.languageOptions
          .filter(opt => opt.name !== this.language.name)
          .map(cty => cty.disabled = false)

        this.lastLanguage = this.language;
        this.changeLanguage(this.language.name);
        localStorage.setItem('language', this.language.name);
    }
  }

  getLanguage(lan: string | null) : Language {
    for (let i = 0; i < this.languageOptions.length; i++) {
      if(this.languageOptions[i].name === lan) {
        return this.languageOptions[i];
      }
    }
    return this.DEFAULT_LANGUAGE;
  }

  public changeLanguage(languageCode: string | null): void {
    if(languageCode !== null)
      this.translocoService.setActiveLang(languageCode);
  }

  getLocation(){
    this.geolocationService.getCountry().subscribe((data: any) => {
      if (data.country === "BR") {
        this.language = this.languageOptions[0];
      } else {
        this.language = this.languageOptions[1];
      }
    });
  }

  refreshLanguageOptions() {
    for (let i = 0; i < this.languageOptions.length; i++) {
      if(this.languageOptions[i].name === this.language.name)
        this.languageOptions[i].disabled = true;
      else
       this.languageOptions[i].disabled = false;
    }
  }


  get f() { return this.form.controls; }
}

