import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Message } from 'primeng/api';
import { LanguageEnum } from 'src/app/model/enum/language-enum';
import { AuthService } from 'src/app/service/auth.service';
import { CustomValidator } from 'src/shared/custom-validator';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrl: './activate-account.component.css'
})
export class ActivateAccountComponent implements OnInit {

  public form!: FormGroup;

  name: string | null = null;
  email: string = '';
  code: string = '';

  isDivCreate: string;
  isDivActivated: string;
  isDivExpired: string;
  loginUrl: string;
  createAccountUrl: string;
  messagesCode: Message[] = [];

  constructor(private formBuilder: FormBuilder, 
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translocoService: TranslocoService,
    private authService: AuthService) {

    this.isDivCreate='';
    this.isDivActivated='none'; //none
    this.isDivExpired='none'; //none

    this.loginUrl = '/login';
    this.createAccountUrl = '/create-account';
  }

  ngOnInit(): void {
    this.createForm(this.code);
    this.changeLanguage();
    this.setTitle();
    let email = localStorage.getItem('email-activate-account')
    localStorage.removeItem('email-activate-account');
    this.name = localStorage.getItem('name-activate-account')
    localStorage.removeItem('name-activate-account');
    // this.name = this.router.getCurrentNavigation()?.extras.state?.['name'];
    // let email = this.router.getCurrentNavigation()?.extras.state?.['email'];
    if(email !== null) {
      this.email = email;
    } else {
      this.router.navigate([this.loginUrl]);
    }
  }

  createForm(code: string) {
    this.form = this.formBuilder.group({
      code: [code, CustomValidator.getRequired()]
    })
  }

  onSubmit() {
      //TODO ativar o loading do botao
      this.authService.confirmSignUp(this.email, this.f.code.value).then(result => {
        if(result) {
          //TODO BACKEND avisar que o usuario foi ativo
          //TODO desativar o loading do botao
          this.showActivated();
        }
        else {
        //TODO desativar o loading do botao
        this.showExpired();
      }
    }).catch(error => {
      //TODO desativar o loading do botao
      this.showExpired();
    });
  }

  sendEmailAgain() {
    this.authService.resendConfirmationCode(this.email, this.name, localStorage.getItem('language'), 'signup').then(() => {
    }).catch(() => {
      this.translocoService.selectTranslate('errorConection').subscribe(value => this.messagesCode = [
        { severity: 'error', summary: value}
      ]);
    });
  }

  redirect() {
    this.router.navigate([this.loginUrl]);
  }

  expired() {
    this.router.navigate([this.createAccountUrl]);
  }

  showCreate() {
    this.isDivCreate = "";
    this.isDivActivated = "none";
    this.isDivExpired="none";
  }

  showActivated() {
    this.isDivCreate = "none";
    this.isDivActivated = "";
    this.isDivExpired="none";
    setTimeout(() => {
      this.router.navigate([this.loginUrl]);
    }, 5000);  //5s
  }

  showExpired() {
    this.isDivCreate = "none";
    this.isDivActivated = "none";
    this.isDivExpired="";
  }
  
  setTitle() {
    this.translocoService.selectTranslate('title.activate-account').subscribe(value => 
      this.titleService.setTitle(value)
    );
  }

  public changeLanguage(languageCode?: string | null): void {
    if(!languageCode || languageCode === null)
      languageCode = localStorage.getItem('language');
    if(languageCode && languageCode !== null)
      this.translocoService.setActiveLang(languageCode);
  }

  get f() { return this.form.controls; }
}
