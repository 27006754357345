export class FindUserVcusGetResp {
    id?: string | null;
    serial?: string | null;
    nickname?: string | null;
    status?: string | null;
    purchaseDate?: string | null;
    createdDate?: string | null;
    lastAccess?: string | null;
    projects?: ProjectsFindUserVcusGetResp[] | null;
    vcuAttributes?: { [key: string]: string } | null;
}

export class ProjectsFindUserVcusGetResp {
    projectID?: string | null;
    projectName?: string | null;

    constructor(
        projectID?: string | null,
        projectName?: string | null
    ) {
        this.projectID =  projectID;
        this.projectName = projectName;
    }
}