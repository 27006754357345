import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { TagModule } from 'primeng/tag';
import { DialogModule } from 'primeng/dialog';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ChipsModule } from 'primeng/chips';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from "primeng/message"; 
import { AccordionModule } from 'primeng/accordion';
import { TableModule } from 'primeng/table';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { CarouselModule } from 'primeng/carousel';
import { ImageModule } from 'primeng/image';

import { TranslocoRootModule } from 'src/app/transloco-root.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { ProjectComponent } from './project/project.component';
import { DashboardTemplateComponent } from '../dashboard/dashboard-template/dashboard-template.component';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { RegisterModule } from '../register/register.module';
import { VcuModule } from '../vcu/vcu.module';
import { RouterModule } from '@angular/router';
import { ToastModule } from 'primeng/toast';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CardModule } from '../card/card.module';

@NgModule({
  declarations: [
    ProjectComponent
  ],
  imports: [
    CommonModule,
    ButtonModule,
    DropdownModule,
    TranslocoRootModule,
    TagModule,
    DialogModule,
    ReactiveFormsModule,
    ChipsModule,
    MessagesModule,
    MessageModule,
    DashboardTemplateComponent,
    AccordionModule,
    TableModule,
    InputIconModule,
    IconFieldModule,
    CarouselModule,
    ImageModule,
    FormsModule,
    ConfirmDialogModule,
    RouterModule,
    VcuModule,
    RegisterModule,
    ToastModule,
    InputTextareaModule,
    CardModule
  ],
  exports: [
    ProjectComponent
  ],
  providers: [provideAnimations(), provideAnimationsAsync(), ConfirmationService]
})
export class ProjectModule { }
