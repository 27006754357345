import { Status } from "../enum/status";
import { Vcu } from "../form/vcu";
import { Load } from "./load-mockup";

export const VCU_1 = new Vcu(
    "1",
    Load.IMAGE_FT700,
    "FT150 SFI",
    Load.FAMILY_VISION,
    Load.MODEL_VISION[0],
    "111111111111111111111",
    Load.DEALER[0],
    "111111",
    new Date("2024-01-01"),
    ['Tag01'],
    new Date("2024-06-19"),
    Status.ONLINE,
    new Date("2024-06-30"),
    new Date("2024-06-29")
);

export const VCU_2 = new Vcu(
    "2",
    Load.IMAGE_FT450,
    "NANOPRO",
    Load.FAMILY_POWER,
    Load.MODEL_POWER[0],
    "22222222222222222222",
    Load.DEALER[1],
    "222222",
    new Date("2024-01-01"),
    ['Tag01', 'Tag02', 'Tag02asdasdasdasdasd', 'Tag04asdsadase', 'Tag05'],
    new Date("2024-06-12"),
    Status.PENDING,
    new Date("2024-06-13"),
    new Date("2024-06-15")
);

export const VCU_3 = new Vcu(
    "3",
    Load.IMAGE_FT300,
    "FT350 SFI",
    Load.FAMILY_300,
    Load.MODEL_300[0],
    "3333333333333333333",
    Load.DEALER[1],
    "333333",
    new Date("2024-01-01"),
    [],
    new Date("2024-06-19"),
    Status.ONLINE,
    new Date("2024-06-22"),
    new Date("2024-06-29")
);

export const VCU_4 = new Vcu(
    "4",
    Load.IMAGE_FT700,
    "FT450 SFI",
    Load.FAMILY_VISION,
    Load.MODEL_VISION[0],
    "4444444444444444444444",
    Load.DEALER[0],
    "444444",
    new Date("2024-01-01"),
    [],
    new Date("2024-06-19"),
    Status.PENDING,
    new Date("2024-06-21"),
    new Date("2024-06-29")
);

export const VCU_5 = new Vcu(
    "5",
    Load.IMAGE_FT550_LITE,
    "FT550 SFI",
    Load.FAMILY_POWER,
    Load.MODEL_POWER[2],
    "5555555555555555555555",
    Load.DEALER[0],
    "555555",
    new Date("2024-01-01"),
    ['Tag04', 'Tag05'],
    new Date("2024-06-19"),
    Status.ONLINE,
    new Date("2024-06-24"),
    new Date("2024-06-29")
);


export const VCU_6 = new Vcu(
    "6",
    Load.IMAGE_FT700,
    "FT650 SFI",
    Load.FAMILY_VISION,
    Load.MODEL_VISION[0],
    "66666666666666666666666",
    Load.DEALER[1],
    "666666",
    new Date("2024-01-01"),
    ['Tag02asdasdasdasdasd', 'Tag04asdsadase', 'Tag02asdasdasdasdasd', 'Tag04asdsadase'],
    new Date("2024-06-19"),
    Status.ONLINE,
    new Date("2024-06-25"),
    new Date("2024-06-29")
);


export const VCU_LIST_1 = [VCU_1, VCU_2, VCU_3, VCU_4, VCU_5, VCU_6]
export const VCU_LIST_2 = [VCU_1, VCU_2, VCU_3, VCU_4]
export const VCU_LIST_3 = [VCU_1, VCU_2]
export const VCU_LIST_4 = [VCU_4, VCU_5, VCU_6]