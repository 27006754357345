<div class="background" [style.display]="isDivEmail">
  <div class="header">
    <a routerLink="{{ loginUrl }}">
      <div class="logo-write"></div>
    </a>
    <strong class="header-title">{{ 'user.forgotPassword.header' | transloco }}</strong>
    <div class="others">
      <p-selectButton 
        [(ngModel)]="language"
        [options]="languageOptions" 
        optionLabel="name"
        optionDisabled="disabled"
        (onOptionClick)="fixLanguage($event)"
      >
        <ng-template let-item pTemplate>
          <div [class]="item.class"></div>
        </ng-template>
      </p-selectButton>
    </div>
  </div>
    <div class="container">
        <div class="title">
            <h2>{{ 'user.forgotPassword.title' | transloco }}</h2>
        </div>
        <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
            <!-- {{ form.value | json }} -->
            <input id="email" 
                class="email" 
                type="email" 
                formControlName="email"
                name="email"
                placeholder="{{ 'user.forgotPassword.email' | transloco }}*" 
                pInputText
                [style]="{'width':'500px'}" />
            <p-messages id="messages"
                [(value)]="messagesEmail" 
                [enableService]="false" 
                [style]="{'font-size':'15px', 'width': '500px'}"/>
            <div class="btns">
                <!-- <a id="btnBackLogin" [routerLink]="[]" (click)="backLogin()">
                    {{'user.forgotPassword.btnBack' | transloco}}
                </a> -->
                <p-button
                    id="btnBackLogin"
                    severity="secondary"
                    [raised]="true"
                    (click)="backLogin()"
                    label="{{'user.forgotPassword.btnBack' | transloco}}">
                </p-button>
                <p-button
                    id="btnSend"
                    type="submit"
                    [loading]="submitBtnLoading"
                    severity="danger"
                    [raised]="true"
                    label="{{'user.forgotPassword.btnSend' | transloco}}">
                </p-button>
            </div>
        </form>
    </div>
</div>

<div class="background" [style.display]="isDivCode">
  <div class="header">
    <a routerLink="{{ loginUrl }}">
      <div class="logo-write"></div>
    </a>
    <strong class="header-title">{{ 'user.forgotPassword.header' | transloco }}</strong>
    <div class="others">
      <p-selectButton 
        [(ngModel)]="language"
        [options]="languageOptions" 
        optionLabel="name"
        optionDisabled="disabled"
        (onOptionClick)="fixLanguage($event)"
      >
        <ng-template let-item pTemplate>
          <div [class]="item.class"></div>
        </ng-template>
      </p-selectButton>
    </div>
  </div>
    <div class="container">
        <p>{{ 'user.forgotPassword.title2' | transloco }}</p>
        <div class="title">
            <h2>{{email}}</h2>
        </div>
        <p-messages 
            [value]="messagesCodeFix" 
            [enableService]="false" 
            [closable]="false" />
        <form class="form" [formGroup]="formCode" (ngSubmit)="onSubmitCode()">
            <!-- {{ form.value | json }} -->
            <p-inputOtp 
                formControlName="code"
                [integerOnly]="true" 
                [length]="6" />
            <p-messages id="messages"
                [(value)]="messagesCode" 
                [enableService]="false" 
                [style]="{'font-size':'15px'}"/>
            <p-button
                type="submit"
                severity="danger"
                [raised]="true"
                label="{{ 'user.forgotPassword.btnSend' | transloco }}">
            </p-button>
            <div class="row">
              {{ 'user.forgotPassword.resend' | transloco }} 
              <a 
                  [routerLink]="[]" 
                  (click)="sendEmailAgain()" 
                  [class.disabled]="isButtonDisabled"
                  [attr.aria-disabled]="isButtonDisabled ? 'true' : null"
                  [style.pointer-events]="isButtonDisabled ? 'none' : 'auto'"
              >
                  {{ 'user.forgotPassword.resend2' | transloco }} 
              </a>
              <span *ngIf="isButtonDisabled">({{ countdown }}s)</span>
          </div>
          
          
        </form>
    </div>
</div>

<div class="background" [style.display]="isDivPass">
  <div class="header">
    <a routerLink="{{ loginUrl }}">
      <div class="logo-write"></div>
    </a>
    <strong class="header-title">{{ 'user.forgotPassword.header2' | transloco }}</strong>
    <div class="others">
      <p-selectButton 
        [(ngModel)]="language"
        [options]="languageOptions" 
        optionLabel="name"
        optionDisabled="disabled"
        (onOptionClick)="fixLanguage($event)"
      >
        <ng-template let-item pTemplate>
          <div [class]="item.class"></div>
        </ng-template>
      </p-selectButton>
    </div>
  </div>
    <div class="container">
        <form class="form" [formGroup]="formPass" (ngSubmit)="onSubmitPass()">
            <!-- {{ formPass.value | json }} -->
            <p>{{ 'user.forgotPassword.title3' | transloco }}</p>
            <div class="title">
                <h2>{{email}}</h2>
            </div>
            <p-password id="password"
                class="ig-pass"
                formControlName="password"
                name="password"
                placeholder="{{ 'user.forgotPassword.newPass' | transloco }}*"
                promptLabel="{{ 'user.createAccount.passwordInput' | transloco }}"
                weakLabel="{{ 'user.createAccount.passwordShort' | transloco }}" 
                mediumLabel="{{ 'user.createAccount.passwordMedium' | transloco }}" 
                strongLabel="{{ 'user.createAccount.passwordLong' | transloco }}" 
                [toggleMask]="true" 
                [style]="{'width':'500px'}"
                [inputStyle]="{'width':'500px'}"/>
                <small id="password-help" style="margin-top: 5px;">
                    {{ 'user.createAccount.passwordText' | transloco }}
                </small>
            <p-password id="passwordConfirm"
                class="ig-pass"
                formControlName="passwordConfirm"
                name="passwordConfirm"
                placeholder="{{ 'user.forgotPassword.confirmPass' | transloco }}*"
                [toggleMask]="true" 
                [style]="{'width':'500px'}"
                [inputStyle]="{'width':'500px'}"/>  
            <p-messages id="messages"
                [(value)]="messagesPass" 
                [enableService]="false" 
                [style]="{'font-size':'15px'}"/>
            <p-button
                id="btnConfirmPass"
                type="submit"
                [loading]="submitBtnLoading"
                severity="danger"
                [raised]="true"
                label="{{'user.forgotPassword.btnConfirmPass' | transloco}}">
            </p-button>
        </form>
    </div>
</div>